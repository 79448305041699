import React from "react";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import {Chip} from "@mui/material";

export const CancelButton = styled(Button)({
    backgroundColor: '#aaaaaa',
    borderColor: '#aaaaaa',
    color: 'white',
    '&:hover':{
        backgroundColor: '#999999',
        borderColor: '#999999',
    },
    '&:active':{
        backgroundColor: '#cccccc',
        borderColor: '#cccccc',
    }
})

export const ValidateButton = styled(Button)({
    backgroundColor: '#ff8d04',
    borderColor: '#ff8d04',
    color: 'white',
    '&:hover':{
        backgroundColor: '#e37f05',
        borderColor: '#e37f05',
    },
    '&:active':{
        backgroundColor: '#fda437',
        borderColor: '#fda437',
    }
})

export const StandardButton = styled(Button)({
    //#022B49;
    backgroundColor: 'transparent',
    borderColor: '#022B49',
    color: '#022B49',
    '&:hover':{
        backgroundColor: '#022B49',
        borderColor: '#022B49',
        color: 'white'
    },
    '&:active':{
        backgroundColor: '#fda437',
        borderColor: '#fda437',
        color: 'white'
    }
})

export const ValidateChip = styled(Chip)({
    backgroundColor: '#ff8d04',
    color: 'white',
    cursor: 'pointer'
})
