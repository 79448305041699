import React, {useEffect, useState} from "react"
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {connect} from "react-redux";
import { styled } from '@mui/material/styles';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Card,
    Chip,
    CardHeader,
    Grid,
    Paper,
    Skeleton,
    Step,
    StepButton,
    StepConnector,
    stepConnectorClasses,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    IconButton,
    Tooltip,
    ButtonGroup, Link
} from "@mui/material";
import {apiPost} from "../authentication/OAuth2Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid,duotone,light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import {setPartner} from "./PartnerForm";
import {assoc, clone} from "ramda";
import {Check, GroupAdd, Settings, VideoLabel} from "@mui/icons-material";
import {CancelButton, ValidateButton, ValidateChip} from "../Buttons";
import Container from "@mui/material/Container";
import {changePageLoadingAndCaptionState, changePageLoadingState, reducer as pageLoaderReducer} from "../PageLoader";
import Typography from "@mui/material/Typography";
import {printSapDoc} from "../TableUtils";
import Drawer from "@mui/material/Drawer";
import AddressForm from "./AddressForm";
import {useViewport} from "../hooks/ViewportContext";

const LeadCRM = ({
    user = null,
    userImpersonator = null,
    pageLoading = false,
    caption = '',
    dispatch
}) => {

    const location = useLocation()
    const uri = location.pathname.substring(1)
    const explodedUri = uri.split('/')
    const baseUri = explodedUri[0]
    const {size} = useViewport()
    const params = useParams()

    const navigate = useNavigate()

    const [prospect, setProspect] = useState(null)
    const [distriUsers, setDistriUsers] = useState(null)
    const [openAddress,setOpenAddress] = useState(false)
    const [address, setAddress] = useState(null)

    useEffect(() => {
        dispatch(setMessageInformation(null))
        let param = {
            CardCode : params.cardcode,
            CntctCode: params.internalcode
        }
        apiPost('/api/get-crm-info',param)
            .then(data => {
                if(data.valid){
                    setProspect(data.infoCRM)
                } else {
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                }
            })
        if (baseUri === 'update-lead-distri-manager') {
            dispatch(setMessageInformation(null))
            apiPost('/api/get-distri-users',param)
                .then(data => {
                    if(data.valid){
                        setDistriUsers(data.distriUsers)
                    } else {
                        dispatch(setMessageInformation({
                            severity: 'error',
                            title: 'Une erreur est survenue',
                            content: data.message
                        }))
                    }
                })
        } else {
            setDistriUsers([]);
        }
    },[true])

    let activeStep = prospect !== null && prospect.Resultat === 'PERDU' ?
        0:
        prospect !== null &&  prospect.Resultat === 'EN_COURS'?
            1:
            prospect !== null &&  prospect.Resultat === 'GAGNE' ?
                2:
                null

    const handleValidate = () => {
        dispatch(setMessageInformation(null))
        dispatch(changePageLoadingAndCaptionState({
            pageLoading: true,
            caption: 'Enregistrement...'
        }))
        apiPost('/api/update-lead', prospect)
            .then(data => {
                if(data.valid){
                    navigate('/' + baseUri.substring(7))
                } else {
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                }
            })
            .finally(() => {
                dispatch(changePageLoadingState(false))
            })
    }

    const addressAdded = () => {
        let newProspect = clone(prospect)
        if (address.type === 'shipping'){
            newProspect.SLabel = address.label
            newProspect.SStreet = address.street
            newProspect.SZipCode = address.zipCode
            newProspect.SCity = address.city
        } else {
            newProspect.BLabel = address.label
            newProspect.BStreet = address.street
            newProspect.BZipCode = address.zipCode
            newProspect.BCity = address.city
        }
        setProspect(newProspect)
    }

    let iconProspect = null
    let colorIconProspect = null
    let titleIconProspect = null

    if(prospect !== null){
        if(prospect.Properties62 === 'Y'){
            iconProspect = solid('user-group')
            colorIconProspect = '#f9b114'
            titleIconProspect = 'Créé par l\'agent'
        } else if(prospect.Properties63 === 'Y'){
            iconProspect = solid('headset')
            colorIconProspect = '#f9b114'
            titleIconProspect = 'Créé par un Webmanager'
        } else if(prospect.Properties64 === 'Y') {
            iconProspect = solid('globe')
            colorIconProspect = '#f9b114'
            titleIconProspect = 'Créé depuis le site web Innoclair.fr'
        }
    }

    return (
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={"Suivi opportunité"}
            page_active={baseUri.substring(7)}
            loading={pageLoading}
            caption={caption}
            dispatch={dispatch}
        >
            <Container maxWidth="md">
                <Grid
                    container
                    sx={{marginTop:2}}
                    direction='column'
                >
                    {
                        prospect === null || distriUsers === null ?
                            <>
                                <Skeleton variant="rectangular" height={60} />
                                <Skeleton variant="rectangular" height={120} style={{marginTop: 10}} />
                                {
                                    [1,2,3,4].map((index) => {
                                        return(
                                            <Grid container style={{marginTop: 30}} key={index}>
                                                <Grid item xs={4} style={{paddingRight: 10}}>
                                                <Skeleton variant="rectangular" height={20} style={{marginTop: 15}} />
                                                </Grid>
                                                    <Grid item xs={4}>
                                                        <Skeleton variant="rectangular" height={50} />
                                                    </Grid>
                                                </Grid>
                                        )
                                    })
                                }
                                <Grid container style={{marginTop: 30}}>
                                    <Grid item xs={6}>
                                        <Skeleton variant="rectangular" width={100} height={30} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton variant="rectangular" height={30} />
                                    </Grid>
                                </Grid>
                            </>
                        :
                            <>
                                <Paper
                                    sx={{padding:'1em'}}
                                >
                                    {
                                        prospect.Series !== 74 && prospect?.CardName ?
                                            <Box sx={{
                                                paddingBottom:'1em'
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    variant={"standard"}
                                                    inputProps={{
                                                        style: {
                                                            fontSize: 26,
                                                            fontWeight:'bold'
                                                        },
                                                        readOnly: !!prospect?.NombreDevis ? 'readonly' : ''
                                                    }}
                                                    onChange={(e) =>
                                                        setProspect(assoc('CardName',e.target.value,prospect))
                                                    }
                                                    value={prospect?.CardName ? prospect.CardName : ''}
                                                />
                                            </Box>
                                            : null
                                    }
                                    <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                            marginBottom:'1em'
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={'auto'}
                                        >
                                            <Box
                                                sx={{
                                                    marginTop:'.5em',
                                                }}
                                            >
                                                <Avatar
                                                    {...titleIconProspect !== null ? {title: titleIconProspect}:null}
                                                    sx={{
                                                        width: '56px',
                                                        height: '56px',
                                                        backgroundColor: colorIconProspect !== null ? colorIconProspect : '#042C48',
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        size="lg"
                                                        icon={iconProspect !== null ? iconProspect : solid('user')} />
                                                </Avatar>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item xs={'auto'}
                                            container direction={'column'} spacing={1}
                                        >
                                            <Grid item xs={'auto'}>
                                                <TextField
                                                    fullWidth
                                                    variant={"standard"}
                                                    inputProps={{
                                                        style: {
                                                            fontSize: 22,
                                                            fontWeight:'bold',
                                                            paddingTop:0,
                                                            paddingBottom:0,
                                                        },
                                                        readOnly: !!prospect?.NombreDevis ? 'readonly' : false
                                                    }}
                                                    value={prospect?.LastName ? prospect.LastName : ''}
                                                    onChange={(e) =>
                                                        setProspect(assoc('LastName',e.target.value,prospect))
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    variant={"standard"}
                                                    inputProps={{
                                                        style: {
                                                            fontSize: 22,
                                                            fontWeight:'bold',
                                                            paddingTop:0,
                                                            paddingBottom:0,
                                                        },
                                                        readOnly: !!prospect?.NombreDevis ? 'readonly' : false
                                                    }}
                                                    value={prospect?.FirstName ? prospect.FirstName : ''}
                                                    onChange={(e) =>
                                                        setProspect(assoc('FirstName',e.target.value,prospect))
                                                    }
                                                />
                                            </Grid>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <List
                                                dense={true}
                                            >
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete"
                                                            disabled={!!prospect?.NombreDevis}
                                                            color={'warning'}
                                                            onClick={(e) => {
                                                                setAddress({
                                                                    customerCardCode : prospect.CardCode,
                                                                    label : prospect.BLabel,
                                                                    street : prospect.BStreet,
                                                                    zipCode : prospect.BZipCode,
                                                                    city : prospect.BCity,
                                                                    type : 'billing',
                                                                    apiUrl : '/api/update-address'
                                                                })
                                                                setOpenAddress(true);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={solid('pen-to-square')}
                                                            />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemIcon
                                                        sx={{
                                                            fontSize:'1.5em',
                                                            minWidth: '1.7em',
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={light('file-invoice-dollar')}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary = {
                                                            <Typography sx={{fontSize:'1em'}}>
                                                                {prospect.BStreet + ' - ' + prospect.BZipCode + ' ' + prospect.BCity}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton
                                                            edge="end" aria-label="delete"
                                                            disabled={!!prospect?.NombreDevis}
                                                            color={'warning'}
                                                            onClick={(e) => {
                                                                setAddress({
                                                                    customerCardCode : prospect.CardCode,
                                                                    label : prospect.SLabel,
                                                                    street : prospect.SStreet,
                                                                    zipCode : prospect.SZipCode,
                                                                    city : prospect.SCity,
                                                                    type : 'shipping',
                                                                    apiUrl : '/api/update-address'
                                                                })
                                                                setOpenAddress(true);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={solid('pen-to-square')}
                                                            />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemIcon
                                                        sx={{fontSize:'1.5em', minWidth: '1.7em'}}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={light('truck')}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary = {
                                                            <Typography sx={{fontSize:'1em'}}>
                                                                {prospect.SStreet + ' - ' + prospect.SZipCode + ' ' + prospect.SCity}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                        sx={{
                                            paddingTop:'0'
                                        }}
                                    >
                                        {
                                            prospect.E_Mail !== '' && prospect.E_Mail !== null ?
                                                <Grid item
                                                      xs={'auto'}
                                                >
                                                    <a
                                                        href={"mailto:" + prospect.E_Mail}
                                                        style={{marginRight:'.3em'}}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                bgcolor:'lightGrey'
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={duotone('envelope')}/>
                                                        </IconButton>
                                                    </a>
                                                    <TextField
                                                        variant={"standard"}
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                width:'20em'
                                                            },
                                                            readOnly: !!prospect?.NombreDevis ? 'readonly' : false
                                                        }}
                                                        value={prospect?.E_Mail ? prospect.E_Mail : ''}
                                                        onChange={(e) =>
                                                            setProspect(assoc('E_Mail',e.target.value,prospect))
                                                        }
                                                    />
                                                </Grid>
                                            : null
                                        }
                                        {
                                            prospect.Phone !== '' && prospect.Phone !== null ?
                                                <Grid item
                                                      xs={'auto'}
                                                >
                                                    <a
                                                        href={"tel:" + prospect.Phone}
                                                        style={{marginRight:'.3em'}}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                bgcolor:'lightGrey',
                                                                paddingX:'.5em'
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={duotone('mobile')} />
                                                        </IconButton>
                                                    </a>
                                                    <TextField
                                                        variant={"standard"}
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                width:'9em'
                                                            },
                                                            readOnly: !!prospect?.NombreDevis ? 'readonly' : false
                                                        }}
                                                        value={prospect?.Phone ? prospect.Phone : ''}
                                                        onChange={(e) =>
                                                            setProspect(assoc('Phone',e.target.value,prospect))
                                                        }
                                                    />
                                                </Grid>
                                            : null
                                        }
                                    </Grid>
                                </Paper>
                                <Paper
                                    sx={{
                                        marginTop: '1em',
                                        padding:'1em'
                                    }}
                                >
                                    <Grid
                                        container
                                        justifyContent={'space-between'}
                                        sx={{marginBottom : '1em'}}
                                    >
                                        <Grid item xs={'auto'}>
                                            <Typography
                                                variant={'h6'}
                                                sx={{
                                                    fontWeight : 'bold',
                                                }}
                                            >
                                                Statut commercial
                                            </Typography>
                                        </Grid>
                                        {
                                            baseUri === 'update-lead-agent' ?
                                                <Grid item container xs={'auto'} alignItems={'center'} spacing={2}>
                                                    <Grid item xs={'auto'}>
                                                        {
                                                            !prospect.NombreDevis || prospect.NombreDevis < 1 ?
                                                                <Link
                                                                    href={'/create-devis/'+params.cardcode+'-'+params.internalcode}
                                                                >
                                                                    <FontAwesomeIcon icon={light('memo')} style={{marginRight:'.5em'}}/>
                                                                    Devis à faire
                                                                </Link>
                                                                :
                                                                <Typography>
                                                                    {prospect.NombreDevis + ' devis'}
                                                                </Typography>
                                                        }
                                                    </Grid>
                                                    {
                                                        prospect.NombreDevis > 0 ?
                                                            <Grid item xs={'auto'}>
                                                                <ButtonGroup >
                                                                    <Tooltip title={'Modifier le ' + (prospect.NombreDevis > 1 ? 'dernier ':'') + 'devis'}>
                                                                        <IconButton
                                                                            color={'warning'}
                                                                            onClick={(e) => {
                                                                                navigate('/update-devis/'+prospect.DocNum+'-'+prospect.DocEntry)
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon icon={solid('file-pen')}/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title={'Impression PDF'+(prospect.NombreDevis > 1 ? ' du dernier devis':'')}>
                                                                        <IconButton
                                                                            color={'secondary'}
                                                                            onClick={(e) => {
                                                                                printSapDoc(prospect.DocEntry, 23, prospect.NomDevis, null, dispatch)
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon icon={solid('eye')}/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    {
                                                                        prospect.NombreDevis > 1 ?
                                                                            <Tooltip title={'Voir les '+prospect.NombreDevis +' devis'}>
                                                                                <IconButton
                                                                                    color={'primary'}
                                                                                    onClick={(e) => {
                                                                                        navigate('/liste-devis/'+prospect.CardCode+'-'+prospect.InternalCode)
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={light('memo')}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        : null
                                                                    }
                                                                </ButtonGroup>
                                                            </Grid>
                                                        : null
                                                    }
                                                </Grid>
                                            : null
                                        }
                                    </Grid>
                                    <Grid container justifyContent={'space-around'}>
                                        <Grid item xs={size === 'xs' ? 'auto' : 12}>
                                            <Stepper activeStep={activeStep} orientation={size === 'xs' ? "vertical" : "horizontal"}>
                                                <Step onClick={(e) => {
                                                    activeStep === 0 ?
                                                        setProspect(assoc('Resultat',null,prospect))
                                                        :
                                                        setProspect(assoc('Resultat','PERDU',prospect))
                                                }}>
                                                    <StepLabel StepIconComponent={ColorlibStepIcon} icon={1}>Perdu</StepLabel>
                                                </Step>
                                                <Step onClick={(e) => {
                                                    activeStep === 1 ?
                                                        setProspect(assoc('Resultat',null,prospect))
                                                        :
                                                        setProspect(assoc('Resultat','EN_COURS',prospect))
                                                }}>
                                                    <StepLabel StepIconComponent={ColorlibStepIcon} icon={2}>En cours</StepLabel>
                                                </Step>
                                                <Step onClick={(e) => {
                                                    activeStep === 2 ?
                                                        setProspect(assoc('Resultat',null,prospect))
                                                        :
                                                        setProspect(assoc('Resultat','GAGNE',prospect))
                                                }}>
                                                    <StepLabel StepIconComponent={ColorlibStepIcon} icon={3}>Gagné</StepLabel>
                                                </Step>
                                            </Stepper>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Grid container sx={{marginTop: 1}} spacing={2}>
                                    {
                                        baseUri === 'update-lead-distri-manager' ?
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant="standard">
                                                    <InputLabel variant="standard">Géré par</InputLabel>
                                                    <Select
                                                        fullWidth={true}
                                                        variant="standard"
                                                        value={prospect?.ChannelContactCode ? prospect?.ChannelContactCode : ''}
                                                        onChange={(e) => {
                                                            let prospectClone = clone(prospect)
                                                            prospectClone.ChannelContactName = distriUsers.find(user => user.InternalCode === e.target.value).Name;
                                                            prospectClone.ChannelContactCode = e.target.value;
                                                            setProspect(prospectClone)
                                                        }}
                                                    >
                                                        <MenuItem value=""></MenuItem>
                                                        {
                                                            distriUsers.map((user, index) =>
                                                                <MenuItem value={user.InternalCode} key={index}>
                                                                    {user.LastName +' '+ user.FirstName}
                                                                </MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            :
                                            null
                                    }
                                    <Grid item xs={4} style={{display: 'flex',alignItems: 'center'}}>
                                        Date prévisionnelle de chantier
                                    </Grid>
                                    <Grid item xs={8}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                            <DatePicker
                                                onChange={e => {
                                                    setProspect(assoc('DateProjet',e?.format('YYYY-MM-DD'),prospect))
                                                }}
                                                value={prospect.DateProjet !== '' ? prospect.DateProjet: null}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={4} style={{display: 'flex',alignItems: 'center'}}>
                                        Date de premier contact
                                    </Grid>
                                    <Grid item xs={8}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                            <DatePicker
                                                onChange={e => {
                                                    setProspect(assoc('DatePremierContact',e?.format('YYYY-MM-DD'),prospect))
                                                }}
                                                value={prospect.DatePremierContact !== '' ? prospect.DatePremierContact: null}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={4} style={{display: 'flex',alignItems: 'center'}}>
                                        Date de dernier contact
                                    </Grid>
                                    <Grid item xs={8}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                            <DatePicker
                                                onChange={e => {
                                                    setProspect(assoc('DateDernierContact',e?.format('YYYY-MM-DD'),prospect))
                                                }}
                                                value={prospect.DateDernierContact !== '' ? prospect.DateDernierContact: null}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={4} style={{display: 'flex',alignItems: 'center'}}>
                                        Date de prochaine étape
                                    </Grid>
                                    <Grid item xs={8}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                            <DatePicker
                                                onChange={e => {
                                                    setProspect(assoc('DateNextStep',e?.format('YYYY-MM-DD'),prospect))
                                                }}
                                                value={prospect.DateNextStep !== '' ? prospect.DateNextStep: null}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} style={{display: 'flex',alignItems: 'center'}}>
                                        <TextField
                                            multiline={true}
                                            fullWidth={true}
                                            label="Commentaire"
                                            value={prospect.FreeText ?? ''}
                                            onChange={e => {
                                                setProspect(assoc('FreeText',e.target.value,prospect))
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} sx={{marginTop:'0', marginBottom:'1em'}}>
                                    <Grid item xs={6}>
                                        <CancelButton
                                            fullWidth={true}
                                            onClick={(e) => {
                                                navigate('/' + baseUri.substring(7))
                                            }}
                                        >
                                            Annuler
                                        </CancelButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ValidateButton
                                            variant="contained"
                                            fullWidth={true}
                                            onClick={handleValidate}
                                        >
                                            Valider
                                        </ValidateButton>
                                    </Grid>
                                </Grid>
                        </>
                    }
                </Grid>
            </Container>
            <Drawer
                sx={{
                    maxWidth:500,
                    '& .MuiDrawer-paper': {
                        maxWidth:500
                    }
                }}
                anchor={'right'}
                open={openAddress}
                onClose={() => setOpenAddress(false)}
            >
                <AddressForm
                    setOpenAddress={setOpenAddress}
                    address={address}
                    setAddress={setAddress}
                    addressAdded={addressAdded}
                />
            </Drawer>
        </PageSkeleton>
    )
}

const ColorlibStepIcon = ({
    active=false,
    completed = false,
    icon = 1,
    className = null
}) => {
    const icons = {
        1: <FontAwesomeIcon icon={solid('trash-can')} />,
        2: <FontAwesomeIcon icon={solid('bars-progress')} />,
        3: <FontAwesomeIcon icon={solid('check')} />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active, icon }} className={className}>
            {icons[String(icon)]}
        </ColorlibStepIconRoot>
    );
}

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    ...(ownerState.active && ownerState.icon === 1 && {
        backgroundColor: 'red',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.active && ownerState.icon === 2 && {
        backgroundColor: '#042C48',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.active && ownerState.icon === 3 && {
        backgroundColor: 'green',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
}));

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        pageLoading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption
    })
)(LeadCRM)
