import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {apiGet} from "../authentication/OAuth2Utils";
import {Alert, AlertTitle} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";

const CommandeAgent = ({
    user = null,
    userImpersonator = null,
    loading = false,
    caption = '',
    dispatch
}) => {
    const [commandeLoading,setCommandeLoading] = useState(false)
    const [listCommande, setListCommande] = useState(null)

    useEffect(() => {
        dispatch(setMessageInformation(null))
        setCommandeLoading(true)
        apiGet('/api/get-commandes-agent')
            .then(data => {
                if(data.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                } else {
                    setListCommande(data.data)
                }
            })
            .finally(() => {
                setCommandeLoading(false)
            })
    }, []);

    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title="Commandes"
            page_active="commande-agent"
            loading={loading}
            caption={caption}
            dispatch={dispatch}
        >
            {
                commandeLoading ?
                    <TableSkeleton nbCol={6} nbLig={6} />:
                    listCommande !== null && listCommande.commande.length > 0?
                        <TableData
                            columns={listCommande.entete}
                            data={listCommande.commande}
                            dispatch={dispatch}
                            defaultOrderColumnId={'DocNum'}
                        />:
                        null
            }
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption
    }),
)(CommandeAgent)
