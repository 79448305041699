import React, {useEffect, useState} from "react"
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions, Alert, AlertTitle, Popper, FormControl, Select, MenuItem, Card
} from "@mui/material";
import {createSlice} from "@reduxjs/toolkit";
import {connect} from "react-redux";
import {TableData} from "./TableUtils";
import {apiGet, apiPost} from "./authentication/OAuth2Utils";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {CancelButton, StandardButton} from "./Buttons";
import {PersonAdd} from "@mui/icons-material";
import {flattenListPrestataires} from "./Utils";
import {assoc, clone, set} from "ramda";
import InputLabel from "@mui/material/InputLabel";
import {setPartner} from "./pages/PartnerForm";
import {useViewport} from "./hooks/ViewportContext";

export const DelegateDialogSlice = createSlice({
    name: 'delegateDialogManager',
    initialState: {
        delegateDialogData: false,
    },
    reducers: {
        setDelegateDialogData: (state, action) => {
            return {
                ...state,
                delegateDialogData: action.payload
            }
        }
    }
});

export const {actions, reducer} = DelegateDialogSlice;

export const {
    setDelegateDialogData
} = actions;

export const DelegateDialog = ({
    delegateDialogData = null,
    init = null,
    dispatch,
}) => {
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState('')
    const [document,setDocument] = useState(null)
    const [prestataires,setPrestataires] = useState(null)
    const [prestataireOptions,setPrestataireOptions] = useState(null)
    const [civilites,setCivilites] = useState(null)
    const [creating,setCreating] = useState(false)
    const [libelleAction,setLibelleAction] = useState('')

    const {size} = useViewport()

    useEffect(() => {
        if (!!delegateDialogData){
            switch (delegateDialogData.type){
                case 'mes' :
                    setLibelleAction('mise en service')
                    break
                case '100j' :
                    setLibelleAction('visite des 100 jours')
                    break
                case 'sav' :
                    setLibelleAction('maintenance')
                    break
            }
            setPrestataireOptions(null)
            setPrestataires(null)
            setDocument({'equipmentCardNum' : delegateDialogData.equipmentCardNum})
            setError('')
            setCreating(false)
            apiPost('/api/get-mes-delegation-data', {'equipmentCardNum' : delegateDialogData.equipmentCardNum})
                .then(response => {
                    if(response.status === 'error'){
                        setError(response.message)
                    } else {
                        setPrestataires(response.data.prestataires)
                        setCivilites(response.data.civilites)
                        setPrestataireOptions(flattenListPrestataires(response.data.prestataires))
                    }
                })
        }
    }, [delegateDialogData]);

    const setPrestataire = (prestataireCode) =>
    {
        if (prestataireCode) {
            let clonedDoc = clone(document)
            clonedDoc['Prestataire'] = prestataireCode
            clonedDoc['Title'] = prestataires[prestataireCode].Title
            clonedDoc['FirstName'] = prestataires[prestataireCode].FirstName
            clonedDoc['LastName'] = prestataires[prestataireCode].LastName
            clonedDoc['Email'] = prestataires[prestataireCode].E_Mail
            clonedDoc['Company'] = prestataires[prestataireCode].Remarks1
            setDocument(clonedDoc)
        } else {
            setDocument({'equipmentCardNum' : delegateDialogData.equipmentCardNum})
        }
    }

    const handleDelegate = () => {
        let error = validate()
        if (error === ''){
            setLoading(true)
            let uri =''
            switch (delegateDialogData.type){
                case 'mes' :
                    uri = '/api/create-mes-delegation'
                    break
                case '100j' :
                    uri = '/api/create-100j-delegation'
                    break
                case 'sav' :
                    uri = '/api/create-sav-delegation'
                    break
            }
            apiPost(uri, document)
                .then(response => {
                    if(response.status === 'error'){
                        setError(response.message)
                    } else {
                        dispatch(setDelegateDialogData(null))
                        init()
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            setError(error)
        }
    }

    const validate = () => {
        if (!creating){
            if (!document?.Prestataire){
                return 'Le prestataire doit être renseigné.'
            }
        } else {
            if (!document?.FirstName){
                return 'Le prénom doit être renseigné.'
            }
            if (!document?.LastName){
                return 'Le nom doit être renseigné.'
            }
            if (!document?.Email){
                return 'L\'email doit être renseigné.'
            }
        }
        return ''
    }

    const handleSetCreating = () => {
        setDocument({'equipmentCardNum' : delegateDialogData.equipmentCardNum})
        setCreating(true)
    }

    const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

    return(
        <Dialog
            open={!!delegateDialogData}
            onClose={(e) => {
                dispatch(setDelegateDialogData(null))
            }
        }>
            <DialogTitle sx={{fontSize:'1.7em'}}>
                Déléguer la {libelleAction}
            </DialogTitle>
            <DialogContent>
                {
                    error !== '' ?
                        <Alert severity="error" sx={{lineBreak:'anywhere'}}>
                            <AlertTitle>Une erreur est survenue</AlertTitle>
                            {error}
                        </Alert>
                    :
                        null
                }
                {
                    !creating ?
                        <Grid
                            container spacing={1}
                            alignItems={'center'}
                        >
                            <Grid item xs>
                                <Autocomplete
                                    fullWidth={true}
                                    options={prestataireOptions ? prestataireOptions : ['Chargement en cours...']}
                                    PopperComponent={CustomPopper}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            disabled={loading}
                                            required
                                            label="Prestataire délégué"
                                            variant={'standard'}
                                        />
                                    }
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={
                                        document?.Prestataire && prestataireOptions && prestataireOptions.find(o => o.id === document.Prestataire) ?
                                            prestataireOptions.find(o => o.id === document.Prestataire)
                                            : null
                                    }
                                    onChange={(event, newValue) => {
                                        setPrestataire(newValue.id)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={'auto'}>
                                <StandardButton
                                    disabled={loading}
                                    fullWidth={true}
                                    startIcon={<PersonAdd />}
                                    variant="outlined"
                                    onClick={() => {
                                        handleSetCreating()
                                    }}
                                >
                                {
                                    ['xs', 'sm'].includes(size) ?
                                        null
                                    :
                                        'Nouveau'
                                }
                                </StandardButton>
                            </Grid>
                        </Grid>
                    :
                        <Card sx={{
                            padding : '1em',
                        }}>
                            <Grid
                                container
                                spacing={2}
                                alignItems={'center'}
                            >
                                <Grid item xs={12} md={2}>
                                    <FormControl fullWidth>
                                        <InputLabel variant="standard">Civilité</InputLabel>
                                        <Select
                                            disabled={loading}
                                            fullWidth={true}
                                            label="Civilité"
                                            variant="standard"
                                            id="Title"
                                            value={document?.Title ? document.Title : ''}
                                            onChange={(e) => {
                                                setDocument(assoc('Title', e.target.value, document))
                                            }}
                                        >
                                            <MenuItem value=""></MenuItem>
                                            {
                                                civilites?.length > 0 ?
                                                    civilites.map((civilite,index) => {
                                                        return (
                                                            <MenuItem value={civilite} key={index}>
                                                                {civilite}
                                                            </MenuItem>
                                                        )
                                                    })
                                                :
                                                    <MenuItem value=''>
                                                        Chargement en cours...
                                                    </MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <TextField
                                        disabled={loading}
                                        fullWidth={true}
                                        label="Nom"
                                        variant="standard"
                                        id="LastName"
                                        required={true}
                                        value={document?.LastName ? document.LastName : ''}
                                        onChange={(e) => {
                                            setDocument(assoc('LastName', e.target.value.toUpperCase(), document))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={loading}
                                        fullWidth={true}
                                        label="Prénom"
                                        variant="standard"
                                        id="FirstName"
                                        required={true}
                                        value={document?.FirstName ? document.FirstName : ''}
                                        onChange={(e) => {
                                            setDocument(assoc('FirstName', capitalize(e.target.value), document))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={loading}
                                        fullWidth={true}
                                        label="Email"
                                        variant="standard"
                                        id="Email"
                                        required={true}
                                        value={document?.Email ? document.Email : ''}
                                        onChange={(e) => {
                                            setDocument(assoc('Email', e.target.value, document))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={loading}
                                        fullWidth={true}
                                        label="Société"
                                        variant="standard"
                                        id="Company"
                                        value={document?.Company ? document.Company : ''}
                                        onChange={(e) => {
                                            setDocument(assoc('Company', capitalize(e.target.value), document))
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                }
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CancelButton
                            disabled={loading}
                            fullWidth={true}
                            style={{color: 'white'}}
                            onClick={(e) => {
                                dispatch(setDelegateDialogData(null))
                            }}
                        >
                            Annuler
                        </CancelButton>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            loading ?
                                <Button
                                    disabled
                                    color={'info'}
                                    variant="contained"
                                    fullWidth={true}
                                >
                                    Enregistrement en cours...
                                </Button>
                            :
                                <Button
                                    color={'info'}
                                    variant="contained"
                                    fullWidth={true}
                                    onClick={(event) => {
                                        handleDelegate()
                                    }}
                                >
                                    Déléguer
                                </Button>
                        }
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

const CustomPopper = function (props) {
    return <Popper {...props} style={{width: "fit-content"}} placement="bottom-start" />;
};

export default connect(
    state => ({
        delegateDialogData: state.delegateDialogReducer.delegateDialogData
    }),
)(DelegateDialog)
