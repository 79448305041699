import React, {useEffect, useState} from "react";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import Container from "@mui/material/Container";
import {assoc, clone, empty, isEmpty, set} from "ramda";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {
    Alert,
    AlertTitle, Box,
    Button,
    Collapse, Divider,
    FormControl,
    IconButton, InputAdornment, Link, List, ListItem, ListItemIcon, ListItemText,
    MenuItem,
    Paper, Popper,
    Select, Stack,
    Switch
} from "@mui/material";
import {CancelButton, StandardButton, ValidateButton} from "../Buttons";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/fr';
import {brands, duotone, light, regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import {PersonAdd} from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import PartnerForm from "./PartnerForm";
import {flattenListCustomerMes, flattenListItem} from "../Utils";
import {changePageLoadingState, PageLoader} from "../PageLoader";
import {FileInput} from "./MiseEnServiceForm";
import {setPdfReaderUrl} from "../dialogs/PdfReaderDialog";
import {useViewport} from "../hooks/ViewportContext";

const Visite100JoursForm = ({
    pageLoading = false,
    user = null,
    dispatch
}) => {
    const [creating,setCreating] = useState(false)
    const [document,setDocument] = useState(null)
    const [files,setFiles] = useState({})
    const [imageLoaded, setImageLoaded] = useState([])
    const [failed,setFailed] = useState(false)
    const [customers,setCustomers] = useState(null)
    const [localization, setLocalization] = useState({})

    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const uri = location.pathname.substring(1)
    const imageTypes = ['png','jpg','jpeg','bmp','gif','svg']
    const {size} = useViewport()

    const handleImageLoaded = (id, value) => {
        let imageLoadedClone = clone(imageLoaded);
        if (imageLoadedClone.includes(id) && !value){
            imageLoadedClone = imageLoadedClone.filter(item => item !== id)
        } else {
            imageLoadedClone.push(id)
        }
        setImageLoaded(imageLoadedClone)
    }

    const handleSetDocument = (property,value) => {
        setDocument(assoc(property, value, document))
    }

    const createForm = () => {
        let form = new FormData
        Object.keys(document).map((key) => {
            form.append(key, document[key])
        })
        Object.keys(localization).map((key) => {
            form.append(key, localization[key])
        })
        Object.keys(files).map((key) => {
            form.append(key+'_name', files[key].file.name)
            form.append(key, files[key].file)
        })
        return form
    }

    const handleSetFile = (id,file) => {
        let clonedFiles = clone(files)
        if (file) {
            clonedFiles[id] = {
                'id' : id,
                'file' : file,
            }
            if (imageTypes.some(fileType => file.type.includes(fileType))) {
                clonedFiles[id].url = URL.createObjectURL(file)
            }
        } else {
            delete clonedFiles[id]
        }
        setFiles(clonedFiles)
    }

    const initFile = (attachmentInfos) => {
        let clonedFiles = clone(files)
        attachmentInfos.map((infos) => {
            if (infos?.id && infos?.url) {
                let fileName = decodeURI(infos.url.split('\\').pop().split('/').pop().split('?').shift())
                let extension = fileName.split('.').pop()
                clonedFiles[infos.id] = {
                    'id': infos.id,
                    'file': {
                        'name': fileName
                    },
                    'extension' : extension,
                    'url': infos.url,
                }
            }
        })
        setFiles(clonedFiles)
    }

    const create = (statut) => {
        dispatch(setMessageInformation(null))
        const errorForm = validate()
        if(errorForm !== ''){
            dispatch(setMessageInformation({
                severity: 'error',
                title: 'Erreur',
                content: errorForm
            }))

        } else {
            setCreating(true);
            let form = createForm();
            if (statut) {
                form.set('statut', statut)
            }
            let apiUri ='/api/create-visite-100-jours'
            if (uri.includes('delegate')) {
                apiUri ='/anonymous-api/create-visite-100-jours'
            }
            apiPost(apiUri, form)
                .then(data => {
                    if (data.status === 'error') {
                        dispatch(setMessageInformation({
                            severity: 'error',
                            title: 'Erreur',
                            content: data.message
                        }))
                    } else {
                        if (uri.includes('delegate')) {
                            navigate('/merci')
                        } else {
                            navigate('/parc-installe' + (uri.includes('agent') ? '-agent' : ''))
                        }
                    }
                })
                .finally(() => {
                    setCreating(false);
                })
        }
    }
    const validate = () => {
        if (!document?.dateVisite){
            return 'Le champ date de visite doit être renseigné.'
        }
        if (!files?.piece_jointe){
            return 'Le formulaire signé doit être joint.'
        }
        return ''
    }


    const init = () => {
        dispatch(changePageLoadingState(true))
        let apiUri = '/api/get-visite-100-jours-details'
        if (uri.includes('delegate')) {
            apiUri = '/anonymous-api/get-100j-details-delegation'
        } else {
            if (
                (user?.roles.includes('ROLE_ACCOUNTMGR') && !uri.includes('create-100-jours-distri'))
                ||
                (user?.roles.includes('ROLE_SALESAGENT') && !uri.includes('create-100-jours-agent'))
            ){
                window.location.pathname = '/login';
            }
        }

        apiPost(apiUri, params)
            .then(response => {
                if(response.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Erreur',
                        content: response.message
                    }))
                } else if(response.status === 'failed'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Erreur',
                        content: response.message
                    }))
                    setFailed(true)
                } else {
                    let newLocalization = {
                        'lat':response.data.equipmentCard.lat,
                        'lon':response.data.equipmentCard.lon,
                        'elevation':response.data.equipmentCard.elevation,
                    }
                    newLocalization = updateLinks(newLocalization)
                    setLocalization(newLocalization)
                    let newDoc = {
                        'equipmentCardNum':params.equipmentCardNum,
                        'statut':'DONE_NXT',
                        'dateVisite':response.data.equipmentCard.dateVisite,
                        'commentaire':response.data.equipmentCard.commentaire,
                        'serial':response.data.equipmentCard.serial,
                        'customer':response.data.equipmentCard.customer,
                        'street':response.data.equipmentCard.street,
                        'zipCode':response.data.equipmentCard.zipCode,
                        'city':response.data.equipmentCard.city,
                        'lat':response.data.equipmentCard.lat,
                        'lon':response.data.equipmentCard.lon,
                        'posteLocataire':response.data.equipmentCard.posteLocataire,
                    }
                    if (response?.data?.equipmentCard?.posteLocataire === 'Locataire'){
                        newDoc.nomLocataire = response.data.equipmentCard.nomLocataire
                        newDoc.prenomLocataire = response.data.equipmentCard.prenomLocataire
                        newDoc.telLocataire = response.data.equipmentCard.telLocataire
                        newDoc.emailLocataire = response.data.equipmentCard.emailLocataire
                    }
                    if (uri.includes('delegate')) {
                        newDoc.equipmentCardNum = response.data.equipmentCard.equipmentCardNum
                    }
                    if (!newLocalization?.lat){
                        getCoordinates(newDoc)
                    }
                    setDocument(newDoc)
                    initFile([
                        {id:'visite', url:response.data.equipmentCard.urlVisite},
                        {id:'piece_jointe', url:response.data.equipmentCard.urlPieceJointe},
                    ])
                    setCustomers(response.data.customers)

                }
            })
            .finally(() => {
                dispatch(changePageLoadingState(false))
            })
    }

    const getCoordinates = (paramDocument) => {
        setLocalization({})
        apiPost('/anonymous-api/get-coordinates', {
            address : paramDocument['street']+' '+paramDocument['city'],
            zipCode : paramDocument['zipCode']
        })
            .then(data => {
                if (data.status === 'error') {
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Erreur',
                        content: data.message
                    }))
                } else {
                    let newLocalization = updateLinks(data.data)
                    setLocalization(newLocalization)
                }
            })

    }

    const updateLinks = (paramloc) => {
        if (paramloc?.lat && paramloc?.lon) {
            paramloc.gmap = 'https://www.google.com/maps/search/?api=1&query='+paramloc.lat+'%2C'+paramloc.lon
            paramloc.waze = 'https://www.waze.com/ul?ll='+paramloc.lat+'%2C'+paramloc.lon+'&navigate=yes'
        } else {
            paramloc.gmap = ''
            paramloc.waze = ''
        }
        return paramloc
    }

    useEffect(() => {
        init()
    },[])

    return(
        <PageSkeleton
            user={user}
            title={ ['xs','sm'].includes(size) ? "Effectuer visite des jours" : "Effectuer la visite des 100 jours"}
            page_active="parc-installe"
            dispatch={dispatch}
            anonymous={uri.includes('delegate')}
        >
            {
                !failed ?
                    <Container
                        sx={{
                            marginTop : '1em'
                        }}
                    >
                        {
                            document?.serial ?
                                <Grid container justifyContent={"space-between"}>
                                    <Grid item>
                                        <Typography variant={'h5'} sx={{marginBottom : '.7em'}}>
                                            N° de série {document.serial}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {
                                            ['xs','sm'].includes(size) ?
                                                null
                                            :
                                                <IconButton
                                                    color={'secondary'}
                                                    onClick={() => {
                                                        dispatch(setPdfReaderUrl(
                                                            process.env.REACT_APP_API_URL+
                                                            '/open-sap-doc/30000176-'+
                                                            (user?.email ? user?.email.split("@")[0] : 'delegate')+'-'+
                                                            document.equipmentCardNum+'-'+
                                                            document.serial+'-'+
                                                            document.customerCardCode+'/'+
                                                            document.serial.replace(/\./g, "_")+'.pdf'
                                                        ))
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={solid('eye')}/>
                                                </IconButton>
                                        }
                                        <Link
                                            href={
                                                process.env.REACT_APP_API_URL+
                                                '/download-sap-doc/30000176-'+
                                                (user?.email ? user?.email.split("@")[0] : 'delegate')+'-'+
                                                document.equipmentCardNum+'-'+
                                                document.serial+'-'+
                                                document.customerCardCode+'/'+
                                                document.serial.replace(/\./g, "_")+'.pdf'
                                            }
                                            target="_blank"
                                        >
                                            <IconButton
                                                color={'primary'}
                                            >
                                                <FontAwesomeIcon icon={solid('arrow-down-to-line')}/>
                                            </IconButton>
                                        </Link>
                                    </Grid>
                                </Grid>
                                : null
                        }
                        <Paper
                            sx={{
                                marginBottom : '1em',
                                padding : '1em'
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant={'h5'}>
                                        Client
                                    </Typography>
                                </Grid>
                                <Grid item spacing={2} container xs={12}>
                                    <Grid item xs={12} md={6}>
                                    {
                                        !!customers ?
                                            <List sx={{paddingBottom:0}}>
                                                <ListItem disablePadding>
                                                    <ListItemIcon sx={{minWidth:'2em'}}>
                                                        <FontAwesomeIcon icon={light('user')}/>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        {
                                                            customers.find(o => o.InternalCode === document.customer).Identite
                                                        }
                                                    </ListItemText>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemIcon sx={{minWidth:'2em'}}>
                                                        <FontAwesomeIcon icon={light('envelope')}/>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        {
                                                            customers.find(o => o.InternalCode === document.customer).Email
                                                        }
                                                    </ListItemText>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemIcon sx={{minWidth:'2em'}}>
                                                        <FontAwesomeIcon icon={light('mobile')}/>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        {
                                                            customers.find(o => o.InternalCode === document.customer).Phone
                                                        }
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        : null
                                    }
                                        {
                                            document?.posteLocataire === 'Locataire' ?
                                                <Paper
                                                    sx={{
                                                        padding:'1em',
                                                        marginTop:'1em'
                                                    }}
                                                >
                                                    <Typography variant={'h6'}>
                                                        Locataire
                                                    </Typography>
                                                    <List sx={{paddingBottom:0}}>
                                                        <ListItem disablePadding>
                                                            <ListItemIcon sx={{minWidth:'2em'}}>
                                                                <FontAwesomeIcon icon={light('user')}/>
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                                {
                                                                    document.nomLocataire + ' ' +document.prenomLocataire
                                                                }
                                                            </ListItemText>
                                                        </ListItem>
                                                        <ListItem disablePadding>
                                                            <ListItemIcon sx={{minWidth:'2em'}}>
                                                                <FontAwesomeIcon icon={light('envelope')}/>
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                                {
                                                                    document.emailLocataire
                                                                }
                                                            </ListItemText>
                                                        </ListItem>
                                                        <ListItem disablePadding>
                                                            <ListItemIcon sx={{minWidth:'2em'}}>
                                                                <FontAwesomeIcon icon={light('mobile')}/>
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                                {
                                                                    document.telLocataire
                                                                }
                                                            </ListItemText>
                                                        </ListItem>
                                                    </List>
                                                </Paper>
                                                : null
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Paper
                                            sx={{
                                                paddingX:'1em',
                                                paddingBottom:'1em'
                                            }}
                                        >
                                            <>
                                                <Typography variant={'h6'}>
                                                    Adresse
                                                </Typography>
                                                {
                                                    document?.street || document?.zipCode || document?.city  ?
                                                        <>
                                                            <List sx={{paddingBottom:0}}>
                                                                <ListItem disablePadding>
                                                                    <ListItemIcon sx={{minWidth:'2em'}}>
                                                                        <FontAwesomeIcon icon={light('truck')}/>
                                                                    </ListItemIcon>
                                                                    <ListItemText>
                                                                        {
                                                                            document?.street ?
                                                                                document.street+' '
                                                                                : null
                                                                        }
                                                                        {
                                                                            document?.zipCode ?
                                                                                document.zipCode+' '
                                                                                : null
                                                                        }
                                                                        {
                                                                            document?.city ?
                                                                                document.city
                                                                                : null
                                                                        }
                                                                    </ListItemText>
                                                                </ListItem>
                                                            </List>
                                                            {
                                                                localization?.gmap || localization?.waze ?
                                                                    <>
                                                                        <Divider sx={{marginY:'1em'}}/>
                                                                        <Grid container spacing={2} justifyContent={'space-between'}>
                                                                            {
                                                                                localization?.gmap ?
                                                                                    <Grid item xs={'auto'}>
                                                                                        <Button
                                                                                            href={localization.gmap}
                                                                                            target="_blank"
                                                                                            startIcon={
                                                                                                <FontAwesomeIcon icon={light('location-dot')}></FontAwesomeIcon>
                                                                                            }
                                                                                        >
                                                                                            Google map
                                                                                        </Button>
                                                                                    </Grid>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                localization?.waze ?
                                                                                    <Grid item xs={'auto'}>
                                                                                        <Button
                                                                                            href={localization.waze}
                                                                                            target="_blank"
                                                                                            startIcon={
                                                                                                <FontAwesomeIcon icon={brands('waze')}></FontAwesomeIcon>
                                                                                            }
                                                                                        >
                                                                                            Waze
                                                                                        </Button>
                                                                                    </Grid>
                                                                                    : null
                                                                            }
                                                                        </Grid>
                                                                    </>
                                                                : null
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Grid container spacing={2} sx={{marginBottom : '1em'}}>
                            <Grid item xs={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                    <DatePicker
                                        label="Date de la visite"
                                        onChange={e => {
                                            handleSetDocument('dateVisite',e?.format('YYYY-MM-DD'))
                                        }}
                                        value={document?.dateVisite ? document?.dateVisite : null}
                                        renderInput={
                                            (params) =>
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    required={true}
                                                />
                                        }
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FileInput
                                    id={'visite'}
                                    label={'Photo visite'}
                                    accepted={'image/*'}
                                    handleSetFile={handleSetFile}
                                    imageLoaded={imageLoaded}
                                    handleImageLoaded={handleImageLoaded}
                                    files={files}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FileInput
                                    id={'piece_jointe'}
                                    label={'Formulaire signé *'}
                                    accepted={
                                        'application/msword,'+
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'+
                                        'application/vnd.ms-powerpoint,'+
                                        'application/vnd.openxmlformats-officedocument.presentationml.slideshow,'+
                                        'application/vnd.openxmlformats-officedocument.presentationml.presentation'+
                                        'application/msword,'+
                                        'application/vnd.ms-excel,'+
                                        'application/vnd.ms-powerpoint,' +
                                        'application/pdf,' +
                                        'image/*'
                                    }
                                    handleSetFile={handleSetFile}
                                    imageLoaded={imageLoaded}
                                    handleImageLoaded={handleImageLoaded}
                                    files={files}
                                    dispatch={dispatch}
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                              sx={{
                                  marginBottom:'1em'
                              }}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    label="Commentaire"
                                    multiline
                                    fullWidth
                                    value={document?.commentaire ? document.commentaire : ''}
                                    onChange={(e) =>
                                        handleSetDocument('commentaire', e.target.value)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={3}>
                            <Grid item xs={12} md={4}>
                                <CancelButton
                                    onClick={(e) => {
                                        navigate('/parc-installe' + (uri.includes('agent') ? '-agent' : ''))
                                    }}
                                    fullWidth={true}
                                    style={{color: 'white'}}
                                >
                                    Annuler
                                </CancelButton>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {
                                    creating
                                        ?
                                        <CancelButton
                                            variant="contained"
                                            fullWidth={true}
                                            disabled
                                        >
                                            Enregistrement...
                                        </CancelButton>
                                        :
                                        <Button
                                            color={'info'}
                                            variant="contained"
                                            fullWidth={true}
                                            onClick={(event) => {
                                                create()
                                            }}
                                        >
                                            Enregistrer provisoirement
                                        </Button>

                                }
                            </Grid>
                            <Grid item xs={12} md={4} sx={{marginBottom : '1em'}}>
                                {
                                    creating
                                        ?
                                        <CancelButton
                                            variant="contained"
                                            fullWidth={true}
                                            disabled
                                        >
                                            Enregistrement...
                                        </CancelButton>
                                        :
                                        <ValidateButton
                                            variant="contained"
                                            fullWidth={true}
                                            onClick={(event) => {
                                                create('DONE')
                                            }}
                                        >
                                            Déclarer la visite des 100 jours
                                        </ValidateButton>
                                }
                            </Grid>
                        </Grid>
                    </Container>
                :null
            }
            <PageLoader pageLoading={pageLoading} dispatch={dispatch} caption="Chargement en cours..." />
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        pageLoading: state.pageLoaderReducer.pageLoading,
    }),
)(Visite100JoursForm)
