import React, {useEffect, useState} from "react";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import Container from "@mui/material/Container";
import {assoc, clone, empty, isEmpty, set} from "ramda";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {
    Alert,
    AlertTitle, Box,
    Button, Checkbox,
    Collapse, Divider,
    FormControl, FormControlLabel,
    IconButton, InputAdornment, Link, List, ListItem, ListItemIcon, ListItemText,
    MenuItem,
    Paper, Popper,
    Select, Stack,
    Switch, Tooltip
} from "@mui/material";
import {CancelButton, StandardButton, ValidateButton} from "../Buttons";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/fr';
import {brands, duotone, light, regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import {PersonAdd} from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import PartnerForm from "./PartnerForm";
import {flattenListAdressesMes, flattenListCustomerMes, flattenListItem} from "../Utils";
import {changePageLoadingState, PageLoader} from "../PageLoader";
import AddressForm from "./AddressForm";
import {setPdfReaderUrl} from "../dialogs/PdfReaderDialog";
import {useViewport} from "../hooks/ViewportContext";

const MiseEnServiceForm = ({
    pageLoading = false,
    user = null,
    dispatch
}) => {
    const [miseEnServiceCreating,setMiseEnServiceCreating] = useState(false)
    const [document,setDocument] = useState(null)
    const [failed,setFailed] = useState(false)
    const [files,setFiles] = useState({})
    const [customers,setCustomers] = useState(null)
    const [customerChoices,setCustomerChoices] = useState(null)
    const [poseurs,setPoseurs] = useState(null)
    const [spanc,setSpanc] = useState(null)
    const [be,setBe] = useState(null)
    const [openPartner,setOpenPartner] = useState(false)
    const [openAddress,setOpenAddress] = useState(false)
    const [imageLoaded, setImageLoaded] = useState([])
    const [addresses, setAddresses] = useState(null)
    const [addressChoices, setAddressChoices] = useState(null)
    const [address, setAddress] = useState({})
    const [localization, setLocalization] = useState({})
    const [locating, setLocating] = useState(false)
    const [customerIsCompany, setCustomerIsCompany] = useState(false)

    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const {size} = useViewport()
    const uri = location.pathname.substring(1)
    // const imageTypes = ['png','jpg','jpeg','bmp','gif','svg']

    const handleImageLoaded = (id, value) => {
        let imageLoadedClone = clone(imageLoaded);
        if (imageLoadedClone.includes(id) && !value){
            imageLoadedClone = imageLoadedClone.filter(item => item !== id)
        } else {
            imageLoadedClone.push(id)
        }
        setImageLoaded(imageLoadedClone)
    }

    const handleSetDocument = (property,value,customerList = null) => {
        let customersToUse = customers
        if (!!customerList){
            customersToUse = customerList
        }
        setDocument(assoc(property, value, document))
        if (property === 'customer'){
            let newDoc = clone(document)
            newDoc['customer'] = value
            newDoc['customerCardCode'] = customersToUse.find(o => o.InternalCode === value).CardCode
            newDoc['addressName'] = ''
            newDoc['street'] = ''
            newDoc['zipCode'] = ''
            newDoc['city'] = ''
            newDoc['posteLocataire'] = ''
            newDoc['nomLocataire'] = ''
            newDoc['prenomLocataire'] = ''
            newDoc['emailLocataire'] = ''
            newDoc['telLocataire'] = ''
            setDocument(newDoc)
            setLocalization({})
            setAddresses(customersToUse.find(o => o.InternalCode === value).Addresses)
            setAddressChoices(flattenListAdressesMes(customersToUse.find(o => o.InternalCode === value).Addresses))
        } else if (property === 'addressName') {
            let newDoc = clone(document)
            newDoc['addressName'] = value
            if (!!addresses.find(o => o.AddressName === value)) {
                newDoc['street'] = addresses.find(o => o.AddressName === value).Street
                newDoc['zipCode'] = addresses.find(o => o.AddressName === value).ZipCode
                newDoc['city'] = addresses.find(o => o.AddressName === value).City
            }
            setDocument(newDoc)
            getCoordinates(newDoc)
        } else {
            setDocument(assoc(property, value, document))
        }
    }



    const getCoordinates = (paramDocument) => {
        setLocalization({})
        apiPost('/anonymous-api/get-coordinates', {
            address : paramDocument['street']+' '+paramDocument['city'],
            zipCode : paramDocument['zipCode']
        })
        .then(data => {
            if (data.status === 'success') {
                let newLocalization = updateLinks(data.data)
                setLocalization(newLocalization)
            }
        })
    }

    const handleSetLocalization = (property,value) => {
        let clonedLocalization = clone(localization)
        clonedLocalization[property] = value
        clonedLocalization = updateLinks(clonedLocalization)
        setLocalization(clonedLocalization)

    }

    const updateLinks = (paramloc) => {
        if (paramloc?.lat && paramloc?.lon) {
            paramloc.gmap = 'https://www.google.com/maps/search/?api=1&query='+paramloc.lat+'%2C'+paramloc.lon
            paramloc.waze = 'https://www.waze.com/ul?ll='+paramloc.lat+'%2C'+paramloc.lon+'&navigate=yes'
        } else {
            paramloc.gmap = ''
            paramloc.waze = ''
        }
        return paramloc
    }

    const updateElevation = (paramLocalization) => {
        if (paramLocalization?.lon && paramLocalization?.lat) {
            apiPost('/anonymous-api/get-elevation', {
                lon : paramLocalization.lon,
                lat : paramLocalization.lat
            })
            .then(data => {
                if (data.status === 'error') {
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Erreur',
                        content: data.message
                    }))
                } else {
                    paramLocalization['elevation'] = data.data.elevation
                    setLocalization(paramLocalization)
                }
            })
        }
    }

    const customerAdded = (newId) => {
        setCustomers(null)
        setOpenPartner(false)
        init(true,newId)
    }

    const addressAdded = () => {
        init(true)
        let newDoc = clone(document)
        newDoc['addressName'] = address.label
        newDoc['street'] = address.street
        newDoc['zipCode'] = address.zipCode
        newDoc['city'] = address.city
        setDocument(newDoc)
        getCoordinates(newDoc)
        setOpenAddress(false)
    }

    const createForm = () => {
        let form = new FormData
        Object.keys(document).map((key) => {
            form.append(key, document[key])
        })
        Object.keys(localization).map((key) => {
            form.append(key, localization[key])
        })
        Object.keys(files).map((key) => {
            form.append(key+'_name', files[key].file.name)
            form.append(key, files[key].file)
        })
        return form
    }

    const handleSetFile = (id,file) => {
        let clonedFiles = clone(files)
        if (file) {
            clonedFiles[id] = {
                'id' : id,
                'file' : file,
            }
            clonedFiles[id].url = URL.createObjectURL(file)
        } else {
            delete clonedFiles[id]
        }
        setFiles(clonedFiles)
    }

    const initFile = (attachmentInfos) => {
        let clonedFiles = clone(files)
        attachmentInfos.map((infos) => {
            if (infos?.id && infos?.url) {
                let fileName = decodeURI(infos.url.split('\\').pop().split('/').pop().split('?').shift())
                let extension = fileName.split('.').pop()
                clonedFiles[infos.id] = {
                    'id': infos.id,
                    'file': {
                        'name': fileName
                    },
                    'extension' : extension,
                    'url': infos.url,
                }
            }
        })
        setFiles(clonedFiles)
    }

    const createMiseEnService = (statut) => {
        dispatch(setMessageInformation(null))
        const errorForm = validateMiseEnService(statut)
        if(errorForm !== ''){
            dispatch(setMessageInformation({
                severity: 'error',
                title: 'Erreur',
                content: errorForm
            }))
        } else {
            setMiseEnServiceCreating(true);
            let form = createForm();
            if (statut) {
                form.set('statutMiseEnService', statut)
            }
            let apiUri ='/api/create-mise-en-service'
            if (uri.includes('delegate')) {
                apiUri ='/anonymous-api/create-mise-en-service'
            }
            apiPost(apiUri, form)
                .then(data => {
                    if (data.status === 'error') {
                        dispatch(setMessageInformation({
                            severity: 'error',
                            title: 'Erreur',
                            content: data.message
                        }))
                    } else {
                        if (uri.includes('delegate')) {
                            navigate('/merci')
                        } else {
                            navigate('/mise-en-service' + (uri.includes('agent') ? '-agent' : '-distri'))
                        }
                    }
                })
                .finally(() => {
                    setMiseEnServiceCreating(false);
                })
        }
    }

    const validateMiseEnService = (statut) => {
        if (uri.includes('distri') || customerIsCompany) {
            if (!document?.customer){
                return 'Le client doit être renseigné.'
            }
        }
        if (statut === 'DONE' && !document?.dateMiseEnService){
            return 'Le champ date de mise en service doit être renseigné.'
        }
        if (statut === 'DONE' && !files?.piece_jointe){
            return 'Le formulaire signé doit être joint.'
        }
        return ''
    }

    const pasteCoordinates = (e) => {
        let coordinates = e.clipboardData.getData("text")
        let index = coordinates.indexOf(',')
        if (index >= 0) {
            e.preventDefault()
            let clonedLocalization = clone(localization)
            clonedLocalization.lat = coordinates.substring(0,index)
            clonedLocalization.lon = coordinates.substring(index+1,coordinates.length).trim()
            clonedLocalization = updateLinks(clonedLocalization)
            setLocalization(clonedLocalization)
        }
    }

    const init = (onlyLists = false, customerId = null) => {
        dispatch(changePageLoadingState(true))
        let apiUri = '/api/get-mise-en-service-details'
        if (uri.includes('delegate')) {
            apiUri = '/anonymous-api/get-mes-details-delegation'
        } else {
            if (
                (user?.roles.includes('ROLE_ACCOUNTMGR') && !uri.includes('create-mise-en-service-distri'))
                ||
                (user?.roles.includes('ROLE_SALESAGENT') && !uri.includes('create-mise-en-service-agent'))
            ){
                window.location.pathname = '/login';
            }
        }
        apiPost(apiUri, {...params, forDistri : uri.includes('distri') })
            .then(response => {
                if(response.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Erreur',
                        content: response.message
                    }))
                } else if(response.status === 'failed'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Erreur',
                        content: response.message
                    }))
                    setFailed(true)
                } else {
                    if (!onlyLists){
                        let newLocalization = {
                            'lat':response.data.equipmentCard.lat,
                            'lon':response.data.equipmentCard.lon,
                            'elevation':response.data.equipmentCard.elevation,
                        }
                        newLocalization = updateLinks(newLocalization)
                        setLocalization(newLocalization)
                        let newDoc = {
                            'equipmentCardNum':params.equipmentCardNum,
                            'statutMiseEnService':'DONE_NXT',
                            'poseur':response.data.equipmentCard.poseur,
                            'spanc':response.data.equipmentCard.spanc,
                            'be':response.data.equipmentCard.be,
                            'dateMiseEnService':response.data.equipmentCard.dateMiseEnService,
                            'commentaire':response.data.equipmentCard.commentaire,
                            'serial':response.data.equipmentCard.serial,
                            'customer':response.data.equipmentCard.customer,
                            'customerCardCode':response.data.equipmentCard.customerCardCode,
                            'street':response.data.equipmentCard.street,
                            'zipCode':response.data.equipmentCard.zipCode,
                            'city':response.data.equipmentCard.city,
                            'posteLocataire':response.data.equipmentCard.posteLocataire,
                        }
                        if (response?.data?.equipmentCard?.posteLocataire === 'Locataire'){
                            newDoc.nomLocataire = response.data.equipmentCard.nomLocataire
                            newDoc.prenomLocataire = response.data.equipmentCard.prenomLocataire
                            newDoc.telLocataire = response.data.equipmentCard.telLocataire
                            newDoc.emailLocataire = response.data.equipmentCard.emailLocataire
                        }
                        if (uri.includes('delegate')) {
                            newDoc.equipmentCardNum = response.data.equipmentCard.equipmentCardNum
                        }
                        initFile([
                            {id:'site', url:response.data.equipmentCard.urlSite},
                            {id:'mise_en_service', url:response.data.equipmentCard.urlMiseEnService},
                            {id:'facade', url:response.data.equipmentCard.urlFacade},
                            {id:'equipements', url:response.data.equipmentCard.urlEquipements},
                            {id:'plaque', url:response.data.equipmentCard.urlPlaque},
                            {id:'piece_jointe', url:response.data.equipmentCard.urlPieceJointe},
                        ])
                        if (!!response.data.customers.find(o => o.InternalCode === response.data.equipmentCard.customer)){
                            setAddresses(response.data.customers.find(o => o.InternalCode === response.data.equipmentCard.customer).Addresses)
                            setAddressChoices(flattenListAdressesMes(response.data.customers.find(o => o.InternalCode === response.data.equipmentCard.customer).Addresses))
                        } else {
                            newDoc.customer = ''
                        }
                        if (!newLocalization?.lat){
                            getCoordinates(newDoc)
                        }
                        setDocument(newDoc)
                    }
                    setPoseurs(response.data.poseurs)
                    setSpanc(response.data.spanc)
                    setBe(response.data.be)
                    setCustomers(response.data.customers)
                    setCustomerChoices(flattenListCustomerMes(response.data.customers))
                    setCustomerIsCompany(response.data.customers.find(o => o.CardCode === response.data.equipmentCard.customerCardCode)?.GroupName.toUpperCase().startsWith('CLI ENTREPRISE') )

                    if (onlyLists) {
                        setAddresses(response.data.customers.find(o => o.InternalCode === document.customer).Addresses)
                        setAddressChoices(flattenListAdressesMes(response.data.customers.find(o => o.InternalCode === document.customer).Addresses))
                    }

                    if (!!customerId) {
                        handleSetDocument('customer', customerId, response.data.customers)
                    }
                }
            })
            .finally(() => {
                dispatch(changePageLoadingState(false))
            })
    }

    const localize = () => {
        setLocating(true)
        if (!navigator.geolocation) {
            dispatch(setMessageInformation({
                severity: 'error',
                title: 'Erreur',
                content: 'La géolocatisation n\'est pas disponible sur votre appareil'
            }))
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (position?.coords?.latitude && position?.coords?.longitude) {
                        let clonedLocalization = clone(localization)
                        clonedLocalization.lat = position.coords.latitude
                        clonedLocalization.lon = position.coords.longitude
                        clonedLocalization = updateLinks(clonedLocalization)
                        if (position?.coords?.altitude) {
                            clonedLocalization.altitude = position.coords.altitude
                            setLocalization(clonedLocalization)
                        } else {
                            updateElevation(clonedLocalization)
                        }
                        setLocating(false)
                    }
                },
                (e) => {
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Erreur',
                        content: 'Impossible de définir votre position'
                    }))
                    setLocating(false)
                }
            );
        }
    }

    const handleCheckLocataire = () => {
        if (document?.posteLocataire === 'Locataire') {
            handleSetDocument('posteLocataire', '')
        } else {
            handleSetDocument('posteLocataire', 'Locataire')
        }
    }

    useEffect(() => {
        init()
    },[])

    return(
        <PageSkeleton
            user={user}
            title={['xs', 'sm'].includes(size) ? "Effectuer mise en service" : "Effectuer une mise en service"}
            page_active="mise-en-service-agent"
            anonymous={uri.includes('delegate')}
            dispatch={dispatch}
        >
            {
                !failed ?
                    <Container
                        sx={{
                            marginTop : '1em'
                        }}
                    >
                        {
                            document?.serial ?
                                <Grid container justifyContent={"space-between"}>
                                    <Grid item>
                                        <Typography variant={'h5'} sx={{marginBottom : '.7em'}}>
                                            N° de série {document.serial}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {
                                            ['xs', 'sm'].includes(size) ?
                                                null
                                            :
                                                <IconButton
                                                    color={'secondary'}
                                                    onClick={() => {
                                                        dispatch(setPdfReaderUrl(
                                                            process.env.REACT_APP_API_URL+
                                                            '/open-sap-doc/10000176-'+
                                                            (user?.email ? user?.email.split("@")[0] : 'delegate')+'-'+
                                                            document.equipmentCardNum+'-'+
                                                            document.serial+'-'+
                                                            document.customerCardCode+'/'+
                                                            document.serial.replace(/\./g, "_")+'.pdf'
                                                        ))
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={solid('eye')}/>
                                                </IconButton>
                                        }
                                        <Link
                                            href={
                                                process.env.REACT_APP_API_URL+
                                                '/download-sap-doc/10000176-'+
                                                (user?.email ? user?.email.split("@")[0] : 'delegate')+'-'+
                                                document.equipmentCardNum+'-'+
                                                document.serial+'-'+
                                                document.customerCardCode+'/'+
                                                document.serial.replace(/\./g, "_")+'.pdf'
                                            }
                                            target="_blank"
                                        >
                                            <IconButton
                                                color={'primary'}
                                            >
                                                <FontAwesomeIcon icon={solid('arrow-down-to-line')}/>
                                            </IconButton>
                                        </Link>
                                    </Grid>
                                </Grid>
                                : null
                        }
                        <Grid container spacing={2} sx={{marginBottom : '1em'}}>
                            <Grid item xs={12}>
                                <Paper sx={{
                                    padding : '1em'
                                }}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={document?.customer && customers && customers.find(o => o.InternalCode === document.customer) ? 6 :12}
                                        >
                                            <Typography variant={'h5'}>
                                                Client
                                            </Typography>
                                            {
                                                uri.includes('distri') || customerIsCompany ?
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        alignItems={'center'}
                                                        sx={{paddingTop:'.2em'}}
                                                    >
                                                        <Grid item xs={9} md={8} lg={9}>
                                                            <Autocomplete
                                                                fullWidth
                                                                options={customerChoices ? customerChoices : ['Chargement en cours...']}
                                                                PopperComponent={CustomPopper}
                                                                renderInput={(params) =>
                                                                    <TextField {...params} required variant={'standard'} />
                                                                }
                                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                value={
                                                                    document?.customer && customerChoices && customerChoices.find(o => o.id === document.customer) ?
                                                                        customerChoices.find(o => o.id === document.customer)
                                                                        : null
                                                                }
                                                                onChange={(event, newValue) => {
                                                                    handleSetDocument('customer',newValue.id)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} md={4} lg={3}>
                                                            <StandardButton
                                                                fullWidth
                                                                startIcon={<PersonAdd />}
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    setOpenPartner(true);
                                                                }}
                                                            >
                                                                {
                                                                    ['xs','sm'].includes(size) ?
                                                                        null
                                                                    :
                                                                        'Ajout'
                                                                }
                                                            </StandardButton>
                                                        </Grid>
                                                    </Grid>
                                                : null
                                            }
                                            {
                                                document?.customer && customers && customers.find(o => o.InternalCode === document.customer) ?
                                                    <List sx={{paddingBottom:0}}>
                                                        {
                                                            !uri.includes('distri') || customerIsCompany?
                                                                <ListItem disablePadding>
                                                                    <ListItemIcon sx={{minWidth:'2em'}}>
                                                                        <FontAwesomeIcon icon={light('user')}/>
                                                                    </ListItemIcon>
                                                                    <ListItemText>
                                                                        {
                                                                            customers.find(o => o.InternalCode === document.customer).Identite
                                                                        }
                                                                    </ListItemText>
                                                                </ListItem>
                                                                : null
                                                        }
                                                        <ListItem disablePadding>
                                                            <ListItemIcon sx={{minWidth:'2em'}}>
                                                                <FontAwesomeIcon icon={light('envelope')}/>
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                                {
                                                                    customers.find(o => o.InternalCode === document.customer).Email
                                                                }
                                                            </ListItemText>
                                                        </ListItem>
                                                        <ListItem disablePadding>
                                                            <ListItemIcon sx={{minWidth:'2em'}}>
                                                                <FontAwesomeIcon icon={light('mobile')}/>
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                                {
                                                                    customers.find(o => o.InternalCode === document.customer).Phone
                                                                }
                                                            </ListItemText>
                                                        </ListItem>
                                                    </List>
                                                : null
                                            }
                                            {
                                                document?.customer && customers && customers.find(o => o.InternalCode === document.customer) ?
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            label={'Locataire'}
                                                            control={
                                                                <Checkbox
                                                                    checked={document?.posteLocataire === 'Locataire'}
                                                                    onChange={e => {handleCheckLocataire()}}
                                                                />
                                                            }
                                                        />
                                                        {
                                                            document?.posteLocataire === 'Locataire' ?
                                                                <Paper
                                                                    sx={{
                                                                        paddingX:'1em',
                                                                        paddingBottom:'1em'
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        variant="standard"
                                                                        label="Nom"
                                                                        fullWidth
                                                                        value={document?.nomLocataire}
                                                                        onChange={e => {
                                                                            handleSetDocument('nomLocataire',e.currentTarget.value)
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        variant="standard"
                                                                        label="Prénom"
                                                                        fullWidth
                                                                        value={document?.prenomLocataire}
                                                                        onChange={e => {
                                                                            handleSetDocument('prenomLocataire',e.currentTarget.value)
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        variant="standard"
                                                                        label="Téléphone"
                                                                        fullWidth
                                                                        value={document?.telLocataire}
                                                                        onChange={e => {
                                                                            handleSetDocument('telLocataire',e.currentTarget.value)
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        variant="standard"
                                                                        label="E-mail"
                                                                        fullWidth
                                                                        value={document?.emailLocataire}
                                                                        onChange={e => {
                                                                            handleSetDocument('emailLocataire',e.currentTarget.value)
                                                                        }}
                                                                    />
                                                                </Paper>
                                                            : null
                                                        }
                                                    </Grid>
                                                : null
                                            }

                                        </Grid>
                                        {
                                            document?.customer && customers && customers.find(o => o.InternalCode === document.customer) ?
                                                <Grid item xs={12} md={6}>
                                                    <Paper
                                                        sx={{
                                                            paddingX:'1em',
                                                            paddingBottom:'1em'
                                                        }}
                                                    >
                                                        <>
                                                            <Typography variant={'h6'}>
                                                                Adresse
                                                            </Typography>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                alignItems={'center'}
                                                                sx={{paddingTop:'.2em'}}
                                                            >
                                                                <Grid item xs={8} lg={9}>
                                                                    <Autocomplete
                                                                        fullWidth
                                                                        options={addressChoices ? addressChoices : ['Chargement en cours...']}
                                                                        PopperComponent={CustomPopper}
                                                                        renderInput={(params) =>
                                                                            <TextField {...params} required variant={'standard'} />
                                                                        }
                                                                        value={
                                                                            document?.addressName && addressChoices ?
                                                                                document.addressName
                                                                                : null
                                                                        }
                                                                        onChange={(event, newValue) => {
                                                                            handleSetDocument('addressName',newValue)
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4} lg={3}>
                                                                    <StandardButton
                                                                        fullWidth
                                                                        startIcon={<FontAwesomeIcon icon={solid('plus')}/>}
                                                                        variant="outlined"
                                                                        onClick={() => {
                                                                            setAddress({
                                                                                customerCardCode : document.customerCardCode,
                                                                                type : 'shipping',
                                                                                apiUrl : '/anonymous-api/create-address'
                                                                            })
                                                                            setOpenAddress(true);
                                                                        }}
                                                                    >
                                                                        Ajout
                                                                    </StandardButton>
                                                                </Grid>
                                                            </Grid>
                                                            {
                                                                document?.street || document?.zipCode || document?.city  ?
                                                                    <>
                                                                        <List sx={{paddingBottom:0}}>
                                                                            <ListItem disablePadding>
                                                                                <ListItemIcon sx={{minWidth:'2em'}}>
                                                                                    <FontAwesomeIcon icon={light('truck')}/>
                                                                                </ListItemIcon>
                                                                                <ListItemText>
                                                                                    {
                                                                                        document?.street ?
                                                                                            document.street+' '
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        document?.zipCode ?
                                                                                            document.zipCode+' '
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        document?.city ?
                                                                                            document.city
                                                                                            : null
                                                                                    }
                                                                                </ListItemText>
                                                                            </ListItem>
                                                                        </List>
                                                                        <Divider sx={{marginY:'1em'}}/>
                                                                        <Grid container spacing={2} justifyContent={'space-between'}>
                                                                            <Grid item xs={4}>
                                                                                <TextField
                                                                                    inputProps={{style: {textAlign: 'right'}}}
                                                                                    size="small"
                                                                                    variant="standard"
                                                                                    label="Latitude"
                                                                                    fullWidth
                                                                                    value={localization?.lat ? localization.lat : ''}
                                                                                    onChange={(e) => {
                                                                                        handleSetLocalization('lat',e.target.value)
                                                                                    }}
                                                                                    onBlur={(e) => {
                                                                                        updateElevation(localization)
                                                                                    }}
                                                                                    onPaste={(e) => {
                                                                                        pasteCoordinates(e)
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <TextField
                                                                                    inputProps={{style: {textAlign: 'right'}}}
                                                                                    size="small"
                                                                                    variant="standard"
                                                                                    label="Longitude"
                                                                                    fullWidth
                                                                                    value={localization?.lon ? localization.lon : ''}
                                                                                    onChange={(e) => {
                                                                                        handleSetLocalization('lon',e.target.value)
                                                                                    }}
                                                                                    onBlur={(e) => {
                                                                                        updateElevation(localization)
                                                                                    }}
                                                                                    onPaste={(e) => {
                                                                                        pasteCoordinates(e)
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                {
                                                                                    (localization?.elevation || localization?.elevation === 0) && localization?.elevation > -99999 ?
                                                                                        <TextField
                                                                                            inputProps={{style: {textAlign: 'right'}}}
                                                                                            size="small"
                                                                                            variant="standard"
                                                                                            label="Altitude"
                                                                                            fullWidth
                                                                                            InputProps={{
                                                                                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                                                                                readOnly: true,
                                                                                                disableUnderline: true
                                                                                            }}
                                                                                            value={localization.elevation}
                                                                                        />
                                                                                        : null
                                                                                }
                                                                            </Grid>
                                                                            {
                                                                                localization?.gmap ?
                                                                                    <Grid item xs={'auto'}>
                                                                                        <Button
                                                                                            href={localization.gmap}
                                                                                            target="_blank"
                                                                                            startIcon={
                                                                                                <FontAwesomeIcon icon={light('location-dot')}></FontAwesomeIcon>
                                                                                            }
                                                                                        >
                                                                                            Google map
                                                                                        </Button>
                                                                                    </Grid>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                localization?.waze ?
                                                                                    <Grid item xs={'auto'}>
                                                                                        <Button
                                                                                            href={localization.waze}
                                                                                            target="_blank"
                                                                                            startIcon={
                                                                                                <FontAwesomeIcon icon={brands('waze')}></FontAwesomeIcon>
                                                                                            }
                                                                                        >
                                                                                            Waze
                                                                                        </Button>
                                                                                    </Grid>
                                                                                    : null
                                                                            }
                                                                            <Grid item xs={'auto'}>
                                                                                <Button
                                                                                    variant={'contained'}
                                                                                    size={'small'}
                                                                                    disabled={locating}
                                                                                    sx={{
                                                                                        width:'240px'
                                                                                    }}
                                                                                    startIcon={
                                                                                        <FontAwesomeIcon icon={light('map-location-dot')}></FontAwesomeIcon>
                                                                                    }
                                                                                    onClick={(e) => {
                                                                                        localize()
                                                                                    }}
                                                                                >
                                                                                    { locating ? 'Localisation en cours...' : 'Utiliser ma position' }
                                                                                </Button>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </>
                                                                : null
                                                            }
                                                        </>
                                                    </Paper>
                                                </Grid>
                                            : null
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                container spacing={2}
                                direction={'column'}
                            >
                                <Grid item>
                                    <Autocomplete
                                        fullWidth
                                        options={poseurs ? poseurs : ['Chargement en cours...']}
                                        PopperComponent={CustomPopper}
                                        renderInput={(params) =>
                                            <TextField {...params} label="Poseur" variant={'standard'}/>
                                        }
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        value={
                                            document?.poseur && poseurs ?
                                                poseurs.find(o => o.id === document.poseur)
                                                : null
                                        }
                                        onChange={(event, newValue) => {
                                            handleSetDocument('poseur',newValue?.id ? newValue.id : '')
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        fullWidth
                                        options={be ? be : ['Chargement en cours...']}
                                        PopperComponent={CustomPopper}
                                        renderInput={(params) =>
                                            <TextField {...params} label="Bureau d'étude" variant={'standard'} />
                                        }
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        value={
                                            document?.be && be ?
                                                be.find(o => o.id === document.be)
                                                : null
                                        }
                                        onChange={(event, newValue) => {
                                            handleSetDocument('be',newValue?.id ? newValue.id : '')
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        fullWidth
                                        options={spanc ? spanc : ['Chargement en cours...']}
                                        PopperComponent={CustomPopper}
                                        renderInput={(params) =>
                                            <TextField {...params} label="SPANC" variant={'standard'} />
                                        }
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        value={
                                            document?.spanc && spanc ?
                                                spanc.find(o => o.id === document.spanc)
                                                : null
                                        }
                                        onChange={(event, newValue) => {
                                            handleSetDocument('spanc',newValue?.id ? newValue.id : '')
                                        }}
                                    />
                                </Grid>
                                <Grid item >
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                        <DatePicker
                                            label="Date de mise en service"
                                            onChange={e => {
                                                handleSetDocument('dateMiseEnService',e?.format('YYYY-MM-DD'))
                                            }}
                                            value={document?.dateMiseEnService ? document?.dateMiseEnService : null}
                                            renderInput={
                                                (params) =>
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        required={true}
                                                    />
                                            }
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item>
                                    <FileInput
                                        id={'piece_jointe'}
                                        label={'Formulaire signé *'}
                                        accepted={
                                            'application/msword,'+
                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'+
                                            'application/vnd.ms-powerpoint,'+
                                            'application/vnd.openxmlformats-officedocument.presentationml.slideshow,'+
                                            'application/vnd.openxmlformats-officedocument.presentationml.presentation'+
                                            'application/msword,'+
                                            'application/vnd.ms-excel,'+
                                            'application/vnd.ms-powerpoint,' +
                                            'application/pdf,' +
                                            'image/*'
                                        }
                                        handleSetFile={handleSetFile}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} container spacing={2} direction={'column'}>
                                <Grid item>
                                    <FileInput
                                        id={'facade'}
                                        label={'Photo facade'}
                                        accepted={'image/*'}
                                        handleSetFile={handleSetFile}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                                <Grid item>
                                    <FileInput
                                        id={'site'}
                                        label={'Photo vue large (implantation jardin)'}
                                        accepted={'image/*'}
                                        handleSetFile={handleSetFile}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                                <Grid item>
                                    <FileInput
                                        id={'mise_en_service'}
                                        label={'Photo rapprochée (1m de la station)'}
                                        accepted={'image/*'}
                                        handleSetFile={handleSetFile}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                                <Grid item>
                                    <FileInput
                                        id={'plaque'}
                                        label={'Photo plaque'}
                                        accepted={'image/*'}
                                        handleSetFile={handleSetFile}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                                <Grid item>
                                    <FileInput
                                        id={'equipements'}
                                        label={'Photo équipements connectés'}
                                        accepted={'image/*'}
                                        handleSetFile={handleSetFile}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        dispatch={dispatch}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid container
                              sx={{
                                  marginBottom:'1em'
                              }}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    label="Commentaire"
                                    multiline
                                    fullWidth
                                    value={document?.commentaire ? document.commentaire : ''}
                                    onChange={(e) =>
                                        handleSetDocument('commentaire', e.target.value)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'center'} spacing={3}>
                            {
                                !uri.includes('delegate') ?
                                    <Grid item xs={12} md={4}>
                                        <CancelButton
                                            onClick={(e) => {
                                                navigate('/mise-en-service' + (uri.includes('agent') ? '-agent' : '-distri'))
                                            }}
                                            fullWidth={true}
                                            style={{color: 'white'}}
                                        >
                                            Annuler
                                        </CancelButton>
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12} md={4}>
                                {
                                    miseEnServiceCreating
                                        ?
                                        <CancelButton
                                            variant="contained"
                                            fullWidth={true}
                                            disabled
                                        >
                                            Enregistrement...
                                        </CancelButton>
                                        :
                                        <Button
                                            color={'info'}
                                            variant="contained"
                                            fullWidth={true}
                                            onClick={(event) => {
                                                createMiseEnService()
                                            }}
                                        >
                                            Enregistrer provisoirement
                                        </Button>

                                }
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {
                                    miseEnServiceCreating
                                        ?
                                        <CancelButton
                                            variant="contained"
                                            fullWidth={true}
                                            disabled
                                        >
                                            Enregistrement...
                                        </CancelButton>
                                        :
                                        <ValidateButton
                                            variant="contained"
                                            fullWidth={true}
                                            onClick={(event) => {
                                                createMiseEnService('DONE')
                                            }}
                                        >
                                            Déclarer la mise en service
                                        </ValidateButton>
                                }
                            </Grid>
                        </Grid>
                    </Container>
                    : null
            }

            <Drawer
                sx={{
                    maxWidth:500,
                    '& .MuiDrawer-paper': {
                        maxWidth:500
                    }
                }}
                anchor={'right'}
                open={openPartner}
                onClose={() => setOpenPartner(false)}
            >
                <PartnerForm
                    customerAdded={customerAdded}
                    setOpenPartner={setOpenPartner}
                />
            </Drawer>
            <Drawer
                sx={{
                    maxWidth:500,
                    '& .MuiDrawer-paper': {
                        maxWidth:500
                    }
                }}
                anchor={'right'}
                open={openAddress}
                onClose={() => setOpenAddress(false)}
            >
                <AddressForm
                    setOpenAddress={setOpenAddress}
                    address={address}
                    setAddress={setAddress}
                    addressAdded={addressAdded}
                />
            </Drawer>
            <PageLoader pageLoading={pageLoading} dispatch={dispatch} caption="Chargement en cours..." />
        </PageSkeleton>
    )
}

export const FileInput = ({
    id = null,
    label = null,
    accepted = null,
    handleSetFile = null,
    imageLoaded = null,
    handleImageLoaded = null,
    files= null,
    readOnly = null,
    dispatch
}) => {
    const [tooLarge,setTooLarge] = useState(false)
    const imageTypes = ['png','jpg','jpeg','bmp','gif','svg']

    const {size} = useViewport()

    const validateUpload = (file) => {
        setTooLarge(false)
        if (!file?.type?.includes('image') && ((file.size/1024)/1024).toFixed(4) > 4){
            setTooLarge(true)
        } else {
            handleSetFile(id, file)
        }
    }

    return (
        <Box>
            <Grid container alignItems={'center'} spacing={1}>
                <Grid item xs={'auto'}>
                    <Typography sx={{color : 'rgba(0, 0, 0, 0.6)'}}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={'auto'}>
                    {
                        !readOnly ?
                            <Tooltip title={'Sélectionner un fichier'}>
                                <IconButton component="label" color={'warning'}>
                                    <input
                                        hidden accept={accepted ? accepted : '*'}
                                        type="file"
                                        onChange={(e) => {
                                            validateUpload(e.target.files[0])
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        style={{fontSize:'.8em'}}
                                        icon={solid('upload')}
                                    />
                                </IconButton>
                            </Tooltip>
                        :
                            null
                    }
                    {
                        !['xs','sm'].includes(size) && (
                        imageTypes.includes(files[id]?.extension?.toLowerCase()) ||
                        files[id]?.extension === 'pdf' ||
                        files[id]?.file?.type?.includes('image') ||
                        files[id]?.file?.type?.includes('pdf')) ?
                            <Tooltip title={'Visualiser la pièce jointe'}>
                                <IconButton
                                    color={'secondary'}
                                    onClick={(e) => {
                                        imageTypes.includes(files[id]?.extension?.toLowerCase()) || files[id]?.file?.type?.includes('image') ?
                                            window.open(files[id].url, '_blank')
                                        :
                                            dispatch(setPdfReaderUrl(files[id].url))
                                    }}
                                >
                                    <FontAwesomeIcon
                                        style={{fontSize:'.8em'}}
                                        icon={solid('eye')}
                                    />
                                </IconButton>
                            </Tooltip>
                        :
                            null
                    }
                    {
                        !!files[id] && !files[id]?.file?.type ?
                            <Tooltip title={'Télécharger la pièce jointe'}>
                                <IconButton
                                    color={'primary'}
                                    onClick={(e) => {
                                        window.location.href = files[id].url+'/attachment'
                                    }}
                                >
                                    <FontAwesomeIcon
                                        style={{fontSize:'.8em'}}
                                        icon={solid('arrow-down-to-line')}
                                    />
                                </IconButton>
                            </Tooltip>
                        : null
                    }
                    {
                        !!files[id] && !readOnly ?
                            <Tooltip title={'Supprimer la pièce jointe'}>
                                <IconButton
                                    color={'error'}
                                    onClick={(e) => {
                                        handleSetFile(id)
                                    }}
                                >
                                    <FontAwesomeIcon
                                        style={{fontSize:'.8em'}}
                                        icon={solid('trash-xmark')}
                                    />
                                </IconButton>
                            </Tooltip>
                        : null
                    }
                </Grid>
            </Grid>
            {
                tooLarge ?
                    <Typography variant={"caption"} sx={{'color':'#ed6c02'}}>
                        La taille du fichier ne peut excéder 4 Mo
                    </Typography>
                : null
            }
            {
                files.hasOwnProperty(id) ?
                    <Grid container>
                        <Grid item xs={12}>
                            {
                                imageTypes.includes(files[id]?.extension) || files[id]?.file?.type?.includes('image') ?
                                    <>
                                        <img
                                            src={files[id].url}
                                            style={{
                                                objectFit: 'contain',
                                                maxHeight: '7em',
                                                maxWidth: '100%',
                                                border: '1px solid LightGrey',
                                                borderRadius : '4px',
                                                display: imageLoaded.includes(id) ? '' : 'none'
                                            }}
                                            onLoad={(e) => {
                                                handleImageLoaded(id, true)
                                            }}
                                        />
                                        <FontAwesomeIcon
                                            className={"fa-spin"}
                                            style={{
                                                color: 'LightGrey',
                                                height: '3em',
                                                display : imageLoaded.includes(id) ? 'none' : ''
                                            }}
                                            icon={regular('cog')}
                                        />
                                    </>
                                    :
                                    <TextField
                                        value = {files[id].file.name}
                                        disabled
                                        fullWidth
                                        size={'small'}
                                    />
                            }
                        </Grid>
                    </Grid>
                :
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{marginTop:'.3em', color:'LightGrey'}}>
                            Aucun fichier
                        </Grid>

                    </Grid>
            }
        </Box>
    )
}

const CustomPopper = function (props) {
    return <Popper {...props} style={{width: "fit-content"}} placement="bottom-start" />;
};


export default connect(
    state => ({
        user: state.loginReducer.user,
        pageLoading: state.pageLoaderReducer.pageLoading,
    }),
)(MiseEnServiceForm)
