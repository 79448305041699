import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import PageSkeleton from "../PageSkeleton";
import {Alert, AlertTitle} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";
import {apiGet} from "../authentication/OAuth2Utils";
import {useLocation} from "react-router-dom";

const SuiviMaintenance = ({
                           user = null,
                           loading = false,
                           caption = '',
                           dispatch
                       }) => {
    const [suiviMaintenanceLoading,setSuiviMaintenanceLoading] = useState(false)
    const [listSuiviMaintenance, setListSuiviMaintenance] = useState(null)
    const [error,setError] = useState('')
    const location = useLocation()

    useEffect(() => {
        setSuiviMaintenanceLoading(true)
        let url = location.pathname === '/suivi-maintenance-distri' ?
            "/api/get-liste-suivi-maintenance-distri":
            "/api/get-liste-suivi-maintenance-agent"
        apiGet(url)
            .then(data => {
                if(data.status === 'error'){
                    setError(data.message)
                } else {
                    setListSuiviMaintenance(data.data)
                }
            })
            .finally(() => {
                setSuiviMaintenanceLoading(false)
            })
    }, []);

    return(
        <PageSkeleton
            user={user}
            title="Suivi maintenance"
            page_active={location.pathname.substring(1)}
            dispatch={dispatch}
        >
            {
                error !== '' ?
                    <Alert severity="error">
                        <AlertTitle>Une erreur est survenue</AlertTitle>
                        {error}
                    </Alert>:
                    null
            }
            {
                suiviMaintenanceLoading ?
                    <TableSkeleton nbCol={6} nbLig={6} />:
                    listSuiviMaintenance !== null && listSuiviMaintenance.data.length > 0?
                        <TableData
                            columns={listSuiviMaintenance.entete}
                            data={listSuiviMaintenance.data}
                            defaultOrderColumnId={'DeliveryDate'}
                            dispatch={dispatch} />:
                        <Alert severity="info">
                            Aucune station trouvée
                        </Alert>
            }
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption
    }),
)(SuiviMaintenance)