import React, {useState, useEffect} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {apiGet} from "../authentication/OAuth2Utils";
import {
    Alert,
    AlertTitle
} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";
import {useLocation} from "react-router-dom";

const ListeLead = ({
    user = null,
    userImpersonator = null,
    loading = false,
    caption = '',
    leadFilter= false,
    dispatch
}) => {
    const [clientLoading,setClientLoading] = useState(false)
    const [listClient, setListClient] = useState(null)

    const location = useLocation()
    const uri = location.pathname.substring(1)

    const userType = uri.substring(5);

    useEffect(() => {
        dispatch(setMessageInformation(null))
        setClientLoading(true)
        let url = '/api/get-' + uri;
        apiGet(url)
            .catch(e => {
                console.log(e)
            })
            .then(data => {
                if(data.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                } else {
                    setListClient(data.data)
                }
            })
            .finally(() => {
                setClientLoading(false)
            })
    }, []);

    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={'Opportunités'}
            page_active={uri}
            dispatch={dispatch}
            loading={loading}
            caption={caption}
        >
            {
                clientLoading
                ?
                    <TableSkeleton nbCol={4} nbLig={6} />
                :
                    listClient !== null && listClient.lead.length > 0
                    ?
                        <TableData
                            columns={listClient.entete}
                            data={
                                leadFilter ?
                                    listClient.lead.filter(client => client.ChannelContactCode === user.internalCode)
                                :
                                    listClient.lead
                            }
                            defaultOrderColumnId='CreateDate'
                            csvExportInfo={{
                                url : uri !== 'lead-webmanager' ? 'download-lead/'+userType : '',
                                fileName :'ExportContacts.csv'
                            }}
                            page_active={uri}
                            dispatch={dispatch}
                        />
                    :
                        <Alert severity="info">
                            Vous n'avez aucune opportunité dans notre base
                        </Alert>
            }
        </PageSkeleton>
    )
}


export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption,
        leadFilter: state.pageSkeletonReducer.leadFilter
    }),
)(ListeLead)
