import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PrivateRoute} from './PrivateRoute'
import OauthTokenReader from "./authentication/OauthTokenReader";
import Home from "./pages/Home";
import EspaceDocument from "./pages/EspaceDocument";
import {PageLoader} from "./PageLoader";
import Login from "./authentication/Login"
import {connect} from "react-redux";
import Stock from "./pages/Stock";
import MonCompte from "./pages/MonCompte";
import DemandeReapproStock from "./pages/DemandeReapproStock";
import VenteStock from "./pages/VenteStock";
import Commande from "./pages/Commande";
import CommandeSav from "./pages/CommandeSav";
import CommandeAgent from "./pages/CommandeAgent";
import MiseEnService from "./pages/MiseEnService";
import MiseEnServiceForm from "./pages/MiseEnServiceForm";
import ParcInstalle from "./pages/ParcInstalle";
import ParcInstalleDistriUser from "./pages/ParcInstalleDistriUser";
import SuiviMaintenance from "./pages/SuiviMaintenance";
import GestionDelegues from "./pages/GestionDelegues";
import Devis from "./pages/Devis";
import DevisForm from "./pages/DevisForm";
import CreationLead from "./pages/CreationLead";
import CommandeForm from "./pages/CommandeForm";
import ReapproForm from "./pages/ReapproForm";
import ListeLead from "./pages/ListeLead";
import LeadCRM from "./pages/LeadCRM";
import Repertoire from "./pages/Repertoire";
import DestockForm from "./pages/DestockForm";
import {createTheme, ThemeProvider} from "@mui/material";
import Usurpation from "./pages/Usurpation";
import Visite100JoursForm from "./pages/Visite100JoursForm";
import DerniereMaintenanceForm from "./pages/DerniereMaintenanceForm";
import DelegueForm from "./pages/DelegueForm";
import {Merci} from "./pages/Merci";
import Station from "./pages/Station";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Verdana',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

export const App = ({
    loading= false,
    checkingAppCredentials= true,
    user= null
}) => {

    const publicRoutes = [
        'login',
        'init-password',
        'create-mise-en-service-delegate',
        'create-mise-en-service-delegate-distri',
        'create-100-jours-delegate',
        'create-100-jours-delegate-distri',
        'create-derniere-maintenance-delegate',
        'create-derniere-maintenance-delegate-distri',
        'merci'
    ]

    return (
        <ThemeProvider theme={theme}>
            <PageLoader pageLoading={loading} caption="Traitement en cours..."/>
            <OauthTokenReader />
            <BrowserRouter>
                {
                    !checkingAppCredentials
                        ?
                        <Routes>
                            <Route element={<PrivateRoute user={user} publicRoutes={publicRoutes}/>}>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/home" element={<Home/>}/>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/mon-compte" element={<MonCompte/>}/>
                                <Route path="/init-password/:token" element={<Login/>}/>
                                <Route path="/communication" element={<EspaceDocument />} />
                                <Route path="/documents-sav" element={<EspaceDocument />} />
                                <Route path="/lead-webmanager" element={<ListeLead />} />
                                <Route path="/lead-agent" element={<ListeLead />} />
                                <Route path="/lead-distri-user" element={<ListeLead />} />
                                <Route path="/lead-distri-manager" element={<ListeLead />} />
                                <Route path="/directory-agent" element={<Repertoire />} />
                                <Route path="/directory-distri-manager" element={<Repertoire />} />
                                <Route path="/create-lead-agent" element={<CreationLead />} />
                                <Route path="/create-lead-webmanager" element={<CreationLead />} />
                                <Route path="/create-lead-distri-user" element={<CreationLead />} />
                                <Route path="/create-lead-distri-manager" element={<CreationLead />} />
                                <Route path="/update-lead-agent/:cardcode-:internalcode" element={<LeadCRM />} />
                                <Route path="/update-lead-distri-manager/:cardcode-:internalcode" element={<LeadCRM />} />
                                <Route path="/update-lead-distri-user/:cardcode-:internalcode" element={<LeadCRM />} />
                                <Route path="/update-lead-webmanager/:cardcode-:internalcode" element={<LeadCRM />} />
                                <Route path="/devis" element={<Devis />} />
                                <Route path="/liste-devis/:cardcode-:internalcode" element={<Devis />} />
                                <Route path="/create-devis" element={<DevisForm />} />
                                <Route path="/create-devis/:cardcode-:internalcode" element={<DevisForm />} />
                                <Route path="/update-devis/:docnum-:docentry" element={<DevisForm />} />
                                <Route path="/stock" element={<Stock />} />
                                <Route path="/demande-reappro-stock" element={<DemandeReapproStock />} />
                                <Route path="/vente-stock" element={<VenteStock />} />
                                <Route path="/commande-agent" element={<CommandeAgent />} />
                                <Route path="/commande" element={<Commande />} />
                                <Route path="/create-commande" element={<CommandeForm />} />
                                <Route path="/create-commande-sav" element={<CommandeForm />} />
                                <Route path="/create-reappro" element={<ReapproForm />} />
                                <Route path="/commande-sav" element={<CommandeSav />} />
                                <Route path="/mise-en-service-agent" element={<MiseEnService />} />
                                <Route path="/create-mise-en-service-agent/:equipmentCardNum" element={<MiseEnServiceForm />} />
                                <Route path="/mise-en-service-distri" element={<MiseEnService />} />
                                <Route path="/create-mise-en-service-distri/:equipmentCardNum" element={<MiseEnServiceForm />} />
                                <Route path="/create-mise-en-service-delegate/:token" element={<MiseEnServiceForm />} />
                                <Route path="/create-mise-en-service-delegate-distri/:token" element={<MiseEnServiceForm />} />
                                <Route path="/parc-installe" element={<ParcInstalle />} />
                                <Route path="/parc-installe-agent" element={<ParcInstalle />} />
                                <Route path="/parc-installe-distri-user" element={<ParcInstalleDistriUser />} />
                                <Route path="/suivi-maintenance-distri" element={<SuiviMaintenance />} />
                                <Route path="/suivi-maintenance-agent" element={<SuiviMaintenance />} />
                                <Route path="/gerer-delegues" element={<GestionDelegues />} />
                                <Route path="/create-delegue" element={<DelegueForm />} />
                                <Route path="/usurpation-identite" element={<Usurpation />} />
                                <Route path="/create-destock/:docentry" element={<DestockForm />} />
                                <Route path="/create-100-jours-distri/:equipmentCardNum" element={<Visite100JoursForm />} />
                                <Route path="/create-100-jours-agent/:equipmentCardNum" element={<Visite100JoursForm />} />
                                <Route path="/create-100-jours-delegate/:token" element={<Visite100JoursForm />} />
                                <Route path="/create-100-jours-delegate-distri/:token" element={<Visite100JoursForm />} />
                                <Route path="/create-derniere-maintenance-distri/:equipmentCardNum" element={<DerniereMaintenanceForm />} />
                                <Route path="/create-derniere-maintenance-agent/:equipmentCardNum" element={<DerniereMaintenanceForm />} />
                                <Route path="/create-derniere-maintenance-delegate/:token" element={<DerniereMaintenanceForm />} />
                                <Route path="/create-derniere-maintenance-delegate-distri/:token" element={<DerniereMaintenanceForm />} />
                                <Route path="/station/:equipmentCardNum" element={<Station />} />
                                <Route path="/merci" element={<Merci />} />
                            </Route>
                        </Routes>
                        :
                        <PageLoader pageLoading={checkingAppCredentials} caption="Authentification en cours..."/>
                }
            </BrowserRouter>
        </ThemeProvider>
    )
};

export default connect(state => ({
    loading: state.loginReducer.loading,
    checkingAppCredentials: state.loginReducer.checkingAppCredentials,
    user: state.loginReducer.user
}))(App)
