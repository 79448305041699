import React, {useEffect, useState} from "react"
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {connect} from "react-redux";
import {
    Button,
    Container,
    Divider,
    Grid, IconButton, InputAdornment, Link, List, ListItem, ListItemIcon, ListItemText,
    Paper,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {apiPost} from "../authentication/OAuth2Utils";
import {clone} from "ramda";
import {useNavigate, useParams} from "react-router-dom";
import {changePageLoadingState, PageLoader} from "../PageLoader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid, light, brands} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FileInput} from "./MiseEnServiceForm";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {StandardButton} from "../Buttons";
import {setPdfReaderUrl} from "../dialogs/PdfReaderDialog";
import {useViewport} from "../hooks/ViewportContext";


const Station = ({
    user = null,
    userImpersonator = null,
    pageLoading = null,
    dispatch
}) => {
    const [document, setDocument] = useState(null)
    const [customer, setCustomer] = useState(null)
    const navigate = useNavigate()
    const params = useParams()
    const [imageLoaded, setImageLoaded] = useState([])
    const [files,setFiles] = useState({})
    const [localization, setLocalization] = useState({})
    const [error,setError] = useState('')
    const [locating, setLocating] = useState(false)

    const {size} = useViewport()

    const initFile = (attachmentInfos) => {
        let clonedFiles = clone(files)
        attachmentInfos.map((infos) => {
            if (infos?.id && infos?.url) {
                let fileName = decodeURI(infos.url.split('\\').pop().split('/').pop().split('?').shift())
                let extension = fileName.split('.').pop()
                clonedFiles[infos.id] = {
                    'id': infos.id,
                    'file': {
                        'name': fileName
                    },
                    'extension' : extension,
                    'url': infos.url,
                }
            }
        })
        setFiles(clonedFiles)
    }

    const handleImageLoaded = (id, value) => {
        let imageLoadedClone = clone(imageLoaded);
        if (imageLoadedClone.includes(id) && !value){
            imageLoadedClone = imageLoadedClone.filter(item => item !== id)
        } else {
            imageLoadedClone.push(id)
        }
        setImageLoaded(imageLoadedClone)
    }

    const init = () => {
        dispatch(setMessageInformation(null))
        dispatch(changePageLoadingState(true))
        apiPost('/api/get-fiche-equipement',params)
            .then(response => {
                if(response.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: response.message
                    }))
                } else {
                    setDocument(response.data.equipmentCard)
                    setCustomer(response.data.customer)
                    initFile([
                        {id:'site', url:response.data.equipmentCard.urlSite},
                        {id:'mise_en_service', url:response.data.equipmentCard.urlMiseEnService},
                        {id:'facade', url:response.data.equipmentCard.urlFacade},
                        {id:'equipements', url:response.data.equipmentCard.urlEquipements},
                        {id:'plaque', url:response.data.equipmentCard.urlPlaque},
                        {id:'piece_jointe_mes', url:response.data.equipmentCard.urlPieceJointeMes},
                        {id:'photo_100j', url:response.data.equipmentCard.urlPhoto100J},
                        {id:'piece_jointe_100j', url:response.data.equipmentCard.urlPieceJointe100J},
                        {id:'piece_jointe_maintenance', url:response.data.equipmentCard.urlPieceJointeMaintenance},
                ])
                    let newLocalization = {
                        'lat':response.data.equipmentCard.U_W3C_LATI,
                        'lon':response.data.equipmentCard.U_W3C_LONG,
                        'elevation':response.data.equipmentCard.U_W3C_ALTI,
                    }
                    newLocalization = updateLinks(newLocalization)
                    setLocalization(newLocalization)

                }
            })
            .finally(() => {
                dispatch(changePageLoadingState(false))
            })
    }

    useEffect(() => {
        init()

    },[])

    const handleDateFormat = (dateString) =>
    {
        let dateToFormat = new Date(Date.parse(dateString))
        return dateToFormat.toLocaleString('fr-FR', {
            day: '2-digit', // Pour afficher le jour avec deux chiffres (DD)
            month: '2-digit', // Pour afficher le mois avec deux chiffres (MM)
            year: 'numeric' // Pour afficher l'année sous forme de chiffres (YYYY)
        })
    }

    const updateLinks = (paramloc) => {
        if (paramloc?.lat && paramloc?.lon) {
            paramloc.gmap = 'https://www.google.com/maps/search/?api=1&query='+paramloc.lat+'%2C'+paramloc.lon
            paramloc.waze = 'https://www.waze.com/ul?ll='+paramloc.lat+'%2C'+paramloc.lon+'&navigate=yes'
        } else {
            paramloc.gmap = ''
            paramloc.waze = ''
        }
        return paramloc
    }

    const localize = () => {
        setLocating(true)
        if (!navigator.geolocation) {
            setError('La géolocatisation n\'est pas disponible sur votre appareil');
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (position?.coords?.latitude && position?.coords?.longitude) {
                        apiPost('/api/set-coordinates', {
                            equipmentCardNum : document.EquipmentCardNum,
                            lat : position.coords.latitude,
                            lon : position.coords.longitude,
                        })
                        .then(data => {
                            if(data.status === 'success'){
                                init()
                            } else {
                                dispatch(setMessageInformation({
                                    severity: 'error',
                                    title: 'Une erreur est survenue',
                                    content: data.message
                                }))
                            }
                        })
                        .finally(() => {
                            setLocating(false)
                        })
                    }
                },
                (e) => {
                    setError('Impossible de définir votre position');
                    setLocating(false)
                }
            );
        }
    }
    return (
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title="Station"
            page_active="parc-installe-agent"
            loading = {pageLoading}
            dispatch={dispatch}
        >
            {
                document ?
                    <Container
                        sx={{
                            marginTop : '10px'
                        }}
                    >
                        <Paper sx={{padding : '1em',marginBottom : '1em'}}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <Typography variant={'h4'}>
                                        <FontAwesomeIcon icon={light('box-taped')} style={{paddingRight:'.5em'}}/>
                                        {document.InternalSerialNum}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {
                                        ['xs', 'sm'].includes(size) ?
                                            null
                                            :
                                            <IconButton
                                                color={'secondary'}
                                                onClick={() => {
                                                    dispatch(setPdfReaderUrl(
                                                        process.env.REACT_APP_API_URL+
                                                        '/open-sap-doc/20000176-'+
                                                        user.email.split("@")[0]+'-'+
                                                        document.EquipmentCardNum+'-'+
                                                        document.InternalSerialNum+'-'+
                                                        document.CustomerCode+'/'+
                                                        document.InternalSerialNum.replace(/\./g, "_")+'.pdf'
                                                    ))
                                                }}
                                            >
                                                <FontAwesomeIcon icon={solid('eye')}/>
                                            </IconButton>
                                    }

                                    <Link
                                        href={
                                            process.env.REACT_APP_API_URL+
                                            '/download-sap-doc/20000176-'+
                                            user.email.split("@")[0]+'-'+
                                            document.EquipmentCardNum+'-'+
                                            document.InternalSerialNum+'-'+
                                            document.CustomerCode+'/'+
                                            document.InternalSerialNum.replace(/\./g, "_")+'.pdf'
                                        }
                                        target="_blank"
                                    >
                                        <IconButton
                                            color={'primary'}
                                            onClick={() => {
                                                dispatch(setPdfReaderUrl(

                                                ))
                                            }}
                                        >
                                            <FontAwesomeIcon icon={solid('arrow-down-to-line')}/>
                                        </IconButton>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Typography sx={{marginBottom:'1em'}}>
                                {document.ItemDescription} ({document.ItemCode})
                            </Typography>

                            <Grid container spacing={2} sx={{marginBottom:'1em'}}>
                                <Grid item xs={12} md={6}>
                                    <FontAwesomeIcon icon={light('user')} style={{paddingRight:'.5em'}}/>
                                    Usager : {document.DirectCustomerName}
                                </Grid>
                                {/*<Grid item xs={12} md={6}>*/}
                                {/*    <FontAwesomeIcon icon={light('user')} style={{paddingRight:'.5em'}}/>*/}
                                {/*    Mainteneur : {*/}
                                {/*        document?.U_W3C_MAIN ?*/}
                                {/*            document.U_W3C_MAIN*/}
                                {/*        :*/}
                                {/*            'non renseigné'*/}
                                {/*    }*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} md={6}>
                                    <FontAwesomeIcon icon={light('calendar')} style={{paddingRight : '.5em'}}/>
                                    Livraison le : {
                                        document?.DeliveryDate ?
                                            handleDateFormat(document.DeliveryDate)
                                        :
                                            'non renseigné'
                                    }
                                </Grid>

                            </Grid>
                            <Divider sx={{marginBottom :'1em'}}/>
                            <Typography variant={'h6'} sx={{marginBottom:'.5em'}}>
                                Adresse
                            </Typography>
                            {
                                document?.Street || document?.SipCode || document?.City  ?
                                    <>
                                        <Grid container spacing={2} sx={{marginBottom:'1em'}}>
                                            <Grid item xs={12} md={6}>
                                                {
                                                    document?.Street ?
                                                        document.Street+' '
                                                        : null
                                                }
                                                {
                                                    document?.ZipCode ?
                                                        document.ZipCode+' '
                                                        : null
                                                }
                                                {
                                                    document?.City ?
                                                        document.City
                                                        : null
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} justifyContent={'space-between'} sx={{marginBottom:'.5em'}}>
                                            <Grid item xs={12} md={4}>
                                                Latitude : {
                                                localization?.lat ?
                                                    localization.lat
                                                    :
                                                    'Non renseigné'
                                            }
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                Longitude : {
                                                localization?.lon ?
                                                    localization.lon
                                                    :
                                                    'Non renseigné'
                                            }
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                Altitude : {
                                                localization?.elevation ?
                                                    localization.elevation+'m'
                                                    :
                                                    'Non renseigné'
                                            }
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            {
                                                localization?.gmap ?
                                                    <Grid item xs={'auto'}>
                                                        <Button
                                                            href={localization.gmap}
                                                            target="_blank"
                                                            startIcon={
                                                                <FontAwesomeIcon icon={light('location-dot')}></FontAwesomeIcon>
                                                            }
                                                        >
                                                            Google map
                                                        </Button>
                                                    </Grid>
                                                    : null
                                            }
                                            {
                                                localization?.waze ?
                                                    <Grid item xs={'auto'}>
                                                        <Button
                                                            href={localization.waze}
                                                            target="_blank"
                                                            startIcon={
                                                                <FontAwesomeIcon icon={brands('waze')}></FontAwesomeIcon>
                                                            }
                                                        >
                                                            Waze
                                                        </Button>
                                                    </Grid>
                                                    : null
                                            }
                                            <Grid item xs={'auto'}>
                                                <Button
                                                    variant={'contained'}
                                                    size={'small'}
                                                    disabled={locating}
                                                    sx={{
                                                        width:'240px'
                                                    }}
                                                    startIcon={
                                                        <FontAwesomeIcon icon={light('map-location-dot')}></FontAwesomeIcon>
                                                    }
                                                    onClick={(e) => {
                                                        localize()
                                                    }}
                                                >
                                                    { locating ? 'Localisation en cours...' : 'Utiliser ma position' }
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </>
                                    : null
                            }
                        </Paper>

                        <Paper sx={{padding : '1em', marginBottom : '1em'}}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <Typography variant={'h5'} sx={{marginBottom:'.8em'}}>
                                        <FontAwesomeIcon icon={light('toggle-large-on')} style={{paddingRight:'.5em'}}/>
                                        Mise en service
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Divider sx={{marginBottom :'1em'}}/>
                            <Grid container spacing={2} sx={{marginBottom:'1em'}}>
                                <Grid item xs={12} md={6}>
                                    <FontAwesomeIcon icon={light('calendar')} style={{paddingRight : '.5em'}}/>
                                    Effectuée le : {
                                        document?.U_W3C_MESD ?
                                            handleDateFormat(document.U_W3C_MESD)
                                        :
                                            'non effectuée'
                                    }
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FontAwesomeIcon icon={light('user')} style={{paddingRight : '.5em'}}/>
                                    Par : {document?.U_W3C_MESN ? document?.U_W3C_MESN : 'non renseigné'}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FontAwesomeIcon icon={light('user')} style={{paddingRight : '.5em'}}/>
                                    Poseur : {document?.U_W3C_POSN ? document?.U_W3C_POSN : 'non renseigné'}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FontAwesomeIcon icon={light('comment')} style={{paddingRight : '.5em'}}/>
                                    Commentaire : {document?.U_W3C_MEST ? document?.U_W3C_MEST : 'non renseigné'}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FontAwesomeIcon icon={light('user')} style={{paddingRight : '.5em'}}/>
                                    Spanc : {document?.U_W3C_SPAN ? document?.U_W3C_SPAN : 'non renseigné'}
                                </Grid>
                                <Grid item xs={12}>
                                    <FontAwesomeIcon icon={light('user')} style={{paddingRight : '.5em'}}/>
                                    Bureau d'étude : {document?.U_W3C_BETN ? document?.U_W3C_BETN : 'non renseigné'}
                                </Grid>
                            </Grid>
                            <Divider sx={{marginBottom :'1em'}}/>
                            <Typography variant={'h6'} sx={{marginBottom:'.5em'}}>
                                Pièces jointes
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FileInput
                                        id={'facade'}
                                        label={'Photo facade'}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        readOnly={true}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FileInput
                                        id={'site'}
                                        label={'Photo vue large (implantation jardin)'}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        readOnly={true}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FileInput
                                        id={'mise_en_service'}
                                        label={'Photo rapprochée (1m de la station)'}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        readOnly={true}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FileInput
                                        id={'plaque'}
                                        label={'Photo plaque'}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        readOnly={true}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FileInput
                                        id={'equipements'}
                                        label={'Photo équipements connectés'}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        readOnly={true}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FileInput
                                        id={'piece_jointe_mes'}
                                        label={'Formulaire signé'}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        readOnly={true}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper sx={{padding : '1em', marginBottom : '1em'}}>
                            <Grid container justifyContent={'space-between'}>
                                {
                                    document.Visite100ToDo === 'oui' && (user?.roles?.includes('ROLE_ACCOUNTMGR') || user?.roles?.includes('ROLE_SALESAGENT')) ?
                                        <Grid item>
                                            <Button
                                                sx={{marginBottom:'.8em'}}
                                                color={'primary'}
                                                onClick={() => {
                                                    navigate(
                                                        '/create-100-jours'+
                                                        (
                                                            user?.roles?.includes('ROLE_ACCOUNTMGR') ?
                                                                '-distri'
                                                            : user?.roles?.includes('ROLE_SALESAGENT') ?
                                                                '-agent'
                                                            : ''
                                                        ) +
                                                        '/' + document.EquipmentCardNum
                                                    )
                                                }}
                                            >
                                                <Typography variant={'h5'}>
                                                    <FontAwesomeIcon icon={light('hundred-points')} style={{paddingRight:'.5em'}}/>
                                                    Visite des 100 jours
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    :
                                        <Grid item>
                                            <Typography variant={'h5'} sx={{marginBottom:'.8em'}}>
                                                <FontAwesomeIcon icon={light('hundred-points')} style={{paddingRight:'.5em'}}/>
                                                Visite des 100 jours
                                            </Typography>
                                        </Grid>

                                }
                            </Grid>
                            <Divider sx={{marginBottom :'1em'}}/>
                            <Grid container spacing={2} sx={{marginBottom:'1em'}}>
                                <Grid item xs={12} md={6}>
                                    <FontAwesomeIcon icon={light('calendar')} style={{paddingRight : '.5em'}}/>
                                    Effectuée le : {
                                        document?.U_W3C_VS1D ?
                                            handleDateFormat(document.U_W3C_VS1D)
                                        :
                                            'non effectuée'
                                }
                                </Grid>
                                <Grid item xs={12}>
                                    <FontAwesomeIcon icon={light('comment')} style={{paddingRight : '.5em'}}/>
                                    Commentaire : {document?.U_W3C_VS1T ? document?.U_W3C_VS1T : 'non renseigné'}
                                </Grid>
                            </Grid>
                            <Divider sx={{marginBottom :'1em'}}/>
                            <Typography variant={'h6'} sx={{marginBottom:'.5em'}}>
                                Pièces jointes
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FileInput
                                        id={'photo_100j'}
                                        label={'Photo visite'}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        readOnly={true}
                                        dispatch={dispatch}
                                        files={files}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FileInput
                                        id={'piece_jointe_100j'}
                                        label={'Formulaire signé'}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        readOnly={true}
                                        dispatch={dispatch}
                                        files={files}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>

                        <Paper sx={{padding : '1em', marginBottom : '1em'}}>
                            <Grid container justifyContent={'space-between'}>
                                {
                                    user?.roles?.includes('ROLE_ACCOUNTMGR') || user?.roles?.includes('ROLE_SALESAGENT') ?
                                        <Grid item>
                                            <Button
                                                sx={{marginBottom:'.8em'}}
                                                color={'primary'}
                                                onClick={() => {
                                                    navigate(
                                                        '/create-derniere-maintenance'+
                                                        (
                                                            user?.roles?.includes('ROLE_ACCOUNTMGR') ?
                                                                '-distri'
                                                            : user?.roles?.includes('ROLE_SALESAGENT') ?
                                                                '-agent'
                                                            : ''
                                                        ) +
                                                        '/' + document.EquipmentCardNum
                                                    )
                                                }}
                                            >
                                                <Typography variant={'h5'}>
                                                    <FontAwesomeIcon icon={light('triangle-person-digging')} style={{paddingRight:'.5em'}}/>
                                                    Maintenance
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    :
                                        <Grid item>
                                            <Typography variant={'h5'} sx={{marginBottom:'.8em'}}>
                                                <FontAwesomeIcon icon={light('triangle-person-digging')} style={{paddingRight:'.5em'}}/>
                                                Maintenance
                                            </Typography>
                                        </Grid>
                                }
                            </Grid>
                            <Divider sx={{marginBottom :'1em'}}/>
                            <Grid container spacing={2} sx={{marginBottom:'1em'}}>
                                <Grid item xs={12} md={6}>
                                    <FontAwesomeIcon icon={light('calendar')} style={{paddingRight : '.5em'}}/>
                                    Effectuée le : {
                                    document?.U_W3C_DMTD ?
                                        handleDateFormat(document.U_W3C_DMTD)
                                        :
                                        'non effectuée'
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <FontAwesomeIcon icon={light('comment')} style={{paddingRight : '.5em'}}/>
                                    Commentaire : {document?.U_W3C_DMTT ? document?.U_W3C_DMTT : 'non renseigné'}
                                </Grid>
                            </Grid>
                            <Divider sx={{marginBottom :'1em'}}/>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FileInput
                                        id={'piece_jointe_maintenance'}
                                        label={'Pièce jointe'}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        readOnly={true}
                                        dispatch={dispatch}
                                        files={files}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                : null
            }
        </PageSkeleton>
    )
}



export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        pageLoading: state.pageLoaderReducer.pageLoading
    }),
)(Station)
