import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
    Button,
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField, Divider
}
    from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid}  from "@fortawesome/fontawesome-svg-core/import.macro";
import {setLine} from "../pages/DevisForm";
import {clone} from "ramda"

export default({
    open= false,
    selectedLine = null,
    setSelectedLine = null,
    dispatch
}) => {

    const [remise, setRemise] = useState(0)
    const [error,setError] = useState('')

    const handleClose = () => {
        setRemise(0)
        setSelectedLine(null)
    }

    useEffect(() => {
        if(remise === 0){

        }
    },[])

    const handleChangeRemise = (e) => {
        const newRemise = e.target.value

        if((selectedLine.data.price - newRemise) < selectedLine.listItem[selectedLine.data.itemCode].prices[18]){
            setError('Le seuil est atteint')
            setRemise(selectedLine.data.price -  selectedLine.listItem[selectedLine.data.itemCode].prices[18])
        } else {
            setRemise(newRemise)
        }
    }

    const handleValidate = () => {
        const param = {
            index: selectedLine.index,
            property: 'remise',
            value: clone(remise)

        }
        dispatch(setLine(param))
        handleClose()
    }

    useEffect(() => {
        if(selectedLine !== null){
            if(selectedLine.data.remise !== remise){
                setRemise(selectedLine.data.remise)
            }
        }
    },[open])

    return(
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Gestionnaire de remise</DialogTitle>
            <DialogContent>
                <Alert severity="info" sx={{marginBottom : '1em'}}>
                    Ce module permet d'appliquer une remise à la ligne en indiquant le montant de la remise
                </Alert>
                <Grid container alignItems={'center'} spacing={1} justifyContent={'space-between'} sx={{marginY : '1em'}}>
                    <Grid item xs={'auto'}>
                        Montant avant remise
                    </Grid>
                    <Grid item xs={'auto'} sx={{fontSize : 24}}>
                        {
                            selectedLine !== null ? selectedLine.data.price : ''
                        }
                    </Grid>
                </Grid>
                <Divider></Divider>
                <Grid container alignItems={'center'} spacing={1} justifyContent={'space-between'} sx={{marginY : '1em'}}>
                    <Grid item xs={'12'} md={'auto'}>
                        Remise
                    </Grid>
                    <Grid item xs={'auto'}>
                        <TextField
                            variant="outlined"
                            inputProps={{
                                style: {textAlign: 'right', padding: 4, fontSize: 24, width: 80},
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                            value={selectedLine !== null && remise !== null ?
                                Math.round((remise)/selectedLine.data.price *10000)/100:
                                null}
                            onChange={(e) => {
                                setRemise(selectedLine.data.price*e.target.value/100)
                            }}
                            InputProps={{
                                endAdornment: <FontAwesomeIcon icon={solid('percent')} />
                            }}
                        />
                    </Grid>
                    <Grid item xs={'auto'}>
                        <TextField
                            variant="outlined"
                            inputProps={{
                                style: {textAlign: 'right',padding: 4, fontSize: 24, width: 120},
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                            value={Math.round(remise*100)/100}
                            onChange={handleChangeRemise}
                        />
                    </Grid>
                </Grid>
                <Divider></Divider>
                <Grid container alignItems={'center'} spacing={1} justifyContent={'space-between'} sx={{marginY : '1em'}}>
                    <Grid item xs={'auto'}>
                        Montant après remise
                    </Grid>
                    <Grid item xs={'auto'} sx={{fontSize : 24}}>
                        {
                            selectedLine !== null && remise !== null ?
                                selectedLine.data.price - remise:
                                null

                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container style={{padding: 20}}>
                    <Grid item xs={6} style={{textAlign: 'left'}}>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleClose}
                        >Annuler</Button>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'right'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleValidate}
                        >Valider la remise</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
