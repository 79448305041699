import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import { styled } from '@mui/material/styles';
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {
    Alert,
    AlertTitle, Avatar, Box, Breadcrumbs,
    Button,
    Grid, Link,
    Skeleton, Stack,IconButton
} from "@mui/material";
import {getFileInfo} from "../Utils";
import {CloudDownload, FolderOpen} from "@mui/icons-material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {duotone, light, regular, solid, thin} from "@fortawesome/fontawesome-svg-core/import.macro";
import {clone, forEach} from "ramda";
import {useLocation} from "react-router-dom";
import {useViewport} from "../hooks/ViewportContext";
import {setPdfReaderUrl} from "../dialogs/PdfReaderDialog";

const getThumb = (title) => {
    return title.toLowerCase().replaceAll(' ','-')
}

const EspaceDocument = ({
    user = null,
    userImpersonator = null,
    pageColor= null,
    dispatch
}) => {

    const [documentLoading,setDocumentLoading] = useState(false)
    const [listDocument, setListDocument] = useState([])
    const [path,setPath] = useState('')

    const location = useLocation()
    const uri = location.pathname.substring(1)
    const title = uri === 'communication' ? "Communication" : 'Documents SAV'
    const pageActive = uri === 'communication' ? "communication" : 'documents-sav'
    const {width, height, size} = useViewport()

    const getFiles = (path = '/') => {
        if (uri === 'documents-sav'){
            path = '/SAV';
        }
        dispatch(setMessageInformation(null))
        setDocumentLoading(true)
        apiPost('/api/get-documents', {path})
            .then(data => {
                if(data.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                } else {
                    setListDocument(data.data)
                }
            })
            .catch((e) => {
                dispatch(setMessageInformation({
                    severity: 'error',
                    title: 'Une erreur est survenue',
                    content: e.message
                }))
            })
            .finally(() => {
                setPath(path);
                setDocumentLoading(false)
            })
    }

    useEffect(() => {
        getFiles();
    }, [uri]);



    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title= {title}
            page_active={pageActive}
            dispatch={dispatch}
        >
            {
                documentLoading ?
                    <Box sx={{ margin:'1rem'}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <DocumentTelechargeableSkeleton />
                            </Grid>
                            <Grid item xs={6}>
                                <DocumentTelechargeableSkeleton />
                            </Grid>
                        </Grid>
                    </Box>
                :
                    <Box
                        sx={{
                            overflow: 'auto',
                            height: height - 105,
                        }}
                    >
                        {
                            listDocument.length === 0 ?
                                <Alert severity="info">
                                    <AlertTitle>Information base documentaire</AlertTitle>
                                    Actuellement la base documentaire ne contient aucun document.
                                </Alert>
                            :
                                <Box>
                                    <Grid
                                        container spacing={0}
                                        alignItems='center'
                                        sx={{
                                        }}
                                    >
                                        {
                                            listDocument.map((document,index) => {
                                                let fileInfo = getFileInfo(document.file);
                                                return (
                                                    <Grid
                                                        item xs={12} lg={6} key={index}
                                                    >
                                                        <DocumentTelechargeable
                                                            document={document}
                                                            fileInfo={fileInfo}
                                                            getFiles={getFiles}
                                                            pageColor={pageColor}
                                                            dispatch={dispatch}
                                                        />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box>
                        }
                    </Box>


            }
        </PageSkeleton>
    )
}

const DocumentTelechargeableSkeleton = () => {
    return(
        <Grid container>
            <Grid item xs={12}>
                <Skeleton width="60%" height={40} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton width={80} height={9} />
            </Grid>
            <Grid item xs={12} style={{marginTop: 4}}>
                <Skeleton width="60%" height={60} />
                <Skeleton variant="circular" width={64} height={64} sx={{
                    position: 'relative',
                    top: -60,
                    left: 20
                }} />
            </Grid>
        </Grid>
    )
}

const DocumentTelechargeable = ({
    document = null,
    fileInfo = null,
    getFiles= null,
    pageColor = null,
    dispatch
}) => {
    const {width, height, size} = useViewport()
    const documentIsIframable = ['png','jpg','pdf'].includes(document.extension) && !(['xs','sm'].includes(size))
    return (
        <Stack
            direction='row'
            spacing={5}
            sx={{
                padding:2.5,
                border:'3px solid',
                borderColor:pageColor,
                marginTop:'-3px',
                marginLeft:'-3px',
            }}
        >
            {
                document !== null && fileInfo !== null ?
                    <>
                        {

                            document.thumb ?
                                <a href="javascript:"
                                    onClick={() => {
                                        dispatch(setPdfReaderUrl(document.encodedLink))
                                    }}
                                >
                                    <img
                                        alt={'file-icon'}
                                        src={'data:image/png;base64,'+ document.thumb}
                                        style={{
                                            objectFit: 'contain',
                                            width: 100,
                                            height: 142,
                                            border: '1px solid LightGrey'
                                        }}
                                    />
                                </a>
                            :
                            document.extension === 'zip' ?
                                <a href={document.link}>
                                    <FontAwesomeIcon
                                        icon={duotone('file-zipper')}
                                        size={'7x'}
                                        style={{
                                            color: 'Grey',
                                            height: 147.5,
                                            paddingY: 50
                                        }}
                                    />
                                </a>
                            :
                            document.extension.includes('directory') ?
                                <a href="#"
                                   onClick={(e) =>
                                       getFiles(document.link)
                                   }
                                >
                                    {
                                        document.extension === 'parent directory' ?
                                            <FontAwesomeIcon
                                                icon={duotone('angles-left')}
                                                size={'7x'}
                                                style={{
                                                    color: 'Grey',
                                                    height: 147.5,
                                                }}
                                            />
                                        :
                                            <img
                                                src={'/img/' + getThumb(fileInfo.filename) + '.png'}
                                                machin="truc"
                                                style={{
                                                    width: 140,
                                                    height: 142,
                                                    objectFit: 'contain',
                                                }}
                                                alt={fileInfo.filename} />
                                    }
                                </a>
                            :
                                <a href={document.link}>
                                    <FontAwesomeIcon
                                        icon={duotone('file')}
                                        size={'9x'}
                                        style={{color: 'LightGrey'}}
                                    />
                                </a>
                        }
                        <Grid
                            container
                            direction="column"
                            justifyContent='center'
                            spacing={1}
                            sx={{
                                overflow:'hidden',
                            }}
                        >
                            <Grid item
                                sx={{
                                    textTransform: 'uppercase',
                                    fontSize: '1em',
                                    fontWeight: 'bold',
                                    overflow: 'hidden',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    document.extension.includes('directory') ?
                                        getFiles(document.link)
                                    :
                                    documentIsIframable ?
                                        dispatch(setPdfReaderUrl(document.encodedLink)):
                                        window.location.href = document.link
                                }}
                            >
                                {fileInfo.filename}
                            </Grid>
                            <Grid item sx={{
                                fontSize:'.8em'
                            }}>
                                {fileInfo.mois} {fileInfo.annee}
                            </Grid>
                            <Grid item style={{textAlign: 'right'}}>
                            {
                                documentIsIframable ?
                                    <IconButton
                                        color={'secondary'}
                                        onClick={() => {
                                            dispatch(setPdfReaderUrl(document.encodedLink))
                                        }}
                                    >
                                        <FontAwesomeIcon icon={solid('eye')}/>
                                    </IconButton>:
                                    null
                            }
                            {
                                !document.extension.includes('directory') ?
                                    <IconButton
                                        color={'primary'}
                                        onClick={() => {
                                            window.location = document.link;
                                        }}
                                    >
                                        <FontAwesomeIcon icon={solid('arrow-down-to-line')}/>
                                    </IconButton>
                                    :null
                            }
                            </Grid>
                        </Grid>
                    </>
                    :
                    null
            }
        </Stack>
    )
}


export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        pageColor: state.pageSkeletonReducer.pageColor,
    }),
)(EspaceDocument)
