import React, {useState} from "react";
import {Alert, Box, Button, Collapse, Grid, Paper, TextField} from "@mui/material";
import {Header} from "../Header";
import {useViewport} from "../hooks/ViewportContext";
import Typography from "@mui/material/Typography";

export const Merci = ({
}) => {
    const {width, height, size} = useViewport()

    return(
        <Grid container style={{width: '100%'}}>
            <Header />
            <Box
                style={{
                    backgroundImage: 'url(' + process.env.REACT_APP_API_URL + '/HomeAppli-Back.png)',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    position: 'fixed',
                    top:50,
                    zIndex:-1,
                    width : width,
                    minHeight : height
                }}
            >
            </Box>
            <Box
                component={Paper}
                style={{
                    animation: 'merciAppearence 1.5s forwards',
                    margin: 'auto',
                    padding: 20,
                    marginTop: '22%'
                }}
            >
                <Typography>
                    Merci pour votre participation
                </Typography>
            </Box>
        </Grid>
    );
}