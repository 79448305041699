import React, {useState, useEffect} from "react"
import {Header} from "./Header";
import {Grid, Switch, FormControlLabel, Box, Alert, AlertTitle, Badge, Button, ToggleButton} from "@mui/material";
import MenuVertical from "./MenuVertical";
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {StandardButton} from "./Buttons";
import {PageLoader} from "./PageLoader";
import {useViewport} from "./hooks/ViewportContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {createSlice} from "@reduxjs/toolkit";
import {connect} from "react-redux";
import {PdfReaderDialog} from "./dialogs/PdfReaderDialog";

let pages = [
    {
        groupe: "home",
        listePage:[
            {
                code: 'PAGE_HOME',
                label: 'Accueil',
                link: 'home',
                icon: <FontAwesomeIcon icon={light('house')} size="lg" style={{marginLeft: 4}}/>,
                color: '#009ee3',
            }
        ]
    },
    {
        groupe: "document",
        listePage: [
            {
                code: 'PAGE_DOCUMENT',
                label: 'Communication',
                link: 'communication',
                icon: <FontAwesomeIcon icon={light('rocket-launch')} size="lg" style={{marginLeft: 4}}/>,
                color: '#e6007e',
            },
            {
                code: 'PAGE_DOCUMENT_SAV',
                label: 'Documents SAV',
                link: 'documents-sav',
                icon: <FontAwesomeIcon icon={light('file-import')} size="lg" style={{marginLeft: 4}}/>,
                color: '#00a09a',
            }
        ]
    },
    {
        groupe: "crm",
        listePage: [
            {
                code: 'PAGE_REPERTOIRE_AGENT',
                label: 'Répertoire',
                link: 'directory-agent',
                icon: <FontAwesomeIcon icon={light('address-book')} size="lg" style={{marginLeft: 4}}/>,
                color: '#3fa435',
            },
            {
                code: 'PAGE_REPERTOIRE_DISTRI_MANAGER',
                label: 'Répertoire',
                link: 'directory-distri-manager',
                icon: <FontAwesomeIcon icon={light('address-book')} size="lg" style={{marginLeft: 4}}/>,
                color: '#3fa435',
            },
            {
                code: 'PAGE_LEAD_WEBMANAGER',
                label: 'Opportunités',
                link: 'lead-webmanager',
                icon: <FontAwesomeIcon icon={light('lightbulb-on')} size="lg" style={{marginLeft: 4}}/>,
                color: '#f9b114',
            },
            {
                code: 'PAGE_LEAD_AGENT',
                label: 'Opportunités',
                link: 'lead-agent',
                icon: <FontAwesomeIcon icon={light('lightbulb-on')} size="lg" style={{marginLeft: 4}}/>,
                color: '#f9b114',
            },
            {
                code: 'PAGE_LEAD_DISTRI_USER',
                label: 'Opportunités',
                link: 'lead-distri-user',
                icon: <FontAwesomeIcon icon={light('lightbulb-on')} size="lg" style={{marginLeft: 4}}/>,
                color: '#f9b114',
            },
            {
                code: 'PAGE_LEAD_DISTRI_MANAGER',
                label: 'Opportunités',
                link: 'lead-distri-manager',
                icon: <FontAwesomeIcon icon={light('lightbulb-on')} size="lg" style={{marginLeft: 4}}/>,
                color: '#f9b114',
            },
        ]
    },
    {
        groupe: "devis",
        listePage: [
            {
                code: 'PAGE_DEVIS',
                label: 'Devis',
                link: 'devis',
                icon: <FontAwesomeIcon icon={light('memo')} size="lg" style={{marginLeft: 4}}/>,
                color: '#009ee3',
            },
            {
                code: 'PAGE_COMMANDE_AGENT',
                label: 'Commandes',
                link: 'commande-agent',
                icon: <FontAwesomeIcon icon={light('memo-circle-check')} size="lg" style={{marginLeft: 4}} />,
                color: '#e6007e',
            },
        ]
    },
    {
        groupe: "vente",
        listePage: [
            {
                code: 'PAGE_COMMANDE',
                label: 'Commandes Innoclair',
                link: 'commande',
                icon: <FontAwesomeIcon icon={light('square-terminal')} size="lg" style={{marginLeft: 4}} />,
                color: '#ea5427',
            },
            {
                code: 'PAGE_COMMANDE_SAV',
                label: 'Commandes sous garantie',
                link: 'commande-sav',
                icon: <FontAwesomeIcon icon={light('delete-left')} size="lg" style={{marginLeft: 4}} />,
                color: '#009ee3',
            },
        ]
    },
    {
        groupe: "stock",
        listePage: [
            {
                code: 'PAGE_STOCK',
                label: 'Stock',
                link: 'stock',
                icon: <FontAwesomeIcon icon={light('cubes')} size="lg" style={{marginLeft: 4}} />,
                color: '#00a09a',
            },
            {
                code: 'PAGE_DEMANDE_REAPPRO_STOCK',
                label: 'Demandes réappro.',
                link: 'demande-reappro-stock',
                icon: <FontAwesomeIcon icon={light('layer-plus')} size="lg" style={{marginLeft: 4}} />,
                color: '#f9b114',
            },
        ]
    },

    {
        groupe: "mco",
        listePage: [
            {
                code: 'PAGE_MISE_SERVICE_AGENT',
                label: 'Mises en service',
                link: 'mise-en-service-agent',
                icon: <FontAwesomeIcon icon={light('toggle-large-on')} size="lg" style={{marginLeft: 4}} />,
                color: '#283583',
            },
            {
                code: 'PAGE_MISE_SERVICE_DISTRI',
                label: 'Mises en service',
                link: 'mise-en-service-distri',
                icon: <FontAwesomeIcon icon={light('toggle-large-on')} size="lg" style={{marginLeft: 4}} />,
                color: '#283583',
            },
            {
                code: 'PAGE_PARC_INSTALLE',
                label: 'Installations',
                link: 'parc-installe',
                icon: <FontAwesomeIcon icon={light('pallet-box')} size="lg" style={{marginLeft: 4}} />,
                color: '#3fa435',
            },
            {
                code: 'PAGE_PARC_INSTALLE_AGENT',
                label: 'Installations',
                link: 'parc-installe-agent',
                icon: <FontAwesomeIcon icon={light('pallet-box')} size="lg" style={{marginLeft: 4}} />,
                color: '#3fa435',
            },
            {
                code: 'PAGE_PARC_INSTALLE_DISTRI_USER',
                label: 'Installations',
                link: 'parc-installe-distri-user',
                icon: <FontAwesomeIcon icon={light('pallet-box')} size="lg" style={{marginLeft: 4}} />,
                color: '#3fa435',
            },
            {
                code: 'PAGE_SUIVI_MAINTENANCE_AGENT',
                label: 'Suivi maintenance',
                link: 'suivi-maintenance-agent',
                icon: <FontAwesomeIcon icon={light('list-check')} size="lg" style={{marginLeft: 4}} />,
                color: '#b70e0c',
            },
            {
                code: 'PAGE_SUIVI_MAINTENANCE_DISTRI',
                label: 'Suivi maintenance',
                link: 'suivi-maintenance-distri',
                icon: <FontAwesomeIcon icon={light('list-check')} size="lg" style={{marginLeft: 4}} />,
                color: '#b70e0c',
            },
        ]
    },
    {
        groupe: "intendance",
        listePage: [
            {
                code: 'PAGE_USURPATION_IDENTITE',
                label: 'Usurpation',
                link: 'usurpation-identite',
                icon: <FontAwesomeIcon icon={light('user-secret')} size="lg" style={{marginLeft: 4}} />,
                color: '#666'
            },
            {
                code: 'PAGE_GESTION_DELEGUES',
                label: 'Utilisateurs',
                link: 'gerer-delegues',
                icon: <FontAwesomeIcon icon={light('users')} size="lg" style={{marginLeft: 4}} />,
                color: '#009ee3'
            }
        ]
    }
]

export const PageSkeletonSlice = createSlice({
    name: 'pageSkeletonManager',
    initialState: {
        pageColor: '#eaeaea',
        leadFilter: false,
        messageInformation: null
    },
    reducers: {
        setPageColor: (state, action) => {
            return {
                ...state,
                pageColor: action.payload
            }
        },
        setLeadFilter: (state, action) => {
            return {
                ...state,
                leadFilter: action.payload
            }
        },
        setMessageInformation: (state, action) => {
            return {
                ...state,
                messageInformation: action.payload
            }
        }
    }
});

export const {actions, reducer} = PageSkeletonSlice;

export const {setPageColor, setLeadFilter, setMessageInformation} = actions;

const PageSkeleton = ({
    user = null,
    userImpersonator = null,
    page_active = '',
    title = '',
    loading = false,
    caption = 'Traitement en cours...',
    pageColor = null,
    messageInformation = null,
    pdfReaderUrl = null,
    children,
    setCartOpen,
    cartOpen = null,
    cartCount=null,
    anonymous = false,
    dispatch
}) => {
    const getActiveColor = () => {
        pages.map((group) => {
            group.listePage.map((page) => {
                if (page.link === page_active) {
                    dispatch(setPageColor(page.color));
                }
            })
        })
    }

    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    const [alertHeight, setAlertHeight] = useState(0)

    const {size} = useViewport()

    useEffect(() => {
        getActiveColor();
        dispatch(setMessageInformation(null))
    }, [page_active]);

    let pageHeaderHeight = document.getElementById('pageHeader') !== null ?
        document.getElementById('pageHeader').clientHeight:
        0
    let pageTitleHeight = document.getElementById('pageTitle') !== null ?
        document.getElementById('pageTitle').clientHeight:
        0

    useEffect(() => {
        let currentAlertHeight = document.getElementById('messageInformation') !== null ?
            document.getElementById('messageInformation').clientHeight:
            0
        setAlertHeight(currentAlertHeight)
    },[messageInformation])

    return(
        <Grid container sx={{height:'100%'}}>
            <Header
                user={user}
                userImpersonator={userImpersonator}
                dispatch={dispatch}
                pages={pages}
            />
            <PageLoader pageLoading={loading} caption={caption}/>
            <PdfReaderDialog pdfReaderUrl={pdfReaderUrl} dispatch={dispatch} />
            <Grid container
                style={
                    anonymous ?
                        {
                            display: 'grid',
                            width: '100%',
                            height: '100%',
                        }
                    :
                        {
                            display: 'grid',
                            width: '100%',
                            height: '100%',
                            gridTemplateColumns: ['xl','lg'].includes(size) ? '275px 1fr' : size === 'md' ? '60px 1fr': '1fr'
                        }
                }
            >
                {
                    ['xl','lg','md'].includes(size) && !anonymous ?
                        <Grid item sx={{height:'100%'}}>
                            <MenuVertical
                                user={user}
                                dispatch={dispatch}
                                position="menu"
                                pages={pages}
                                page_active={page_active}
                                pageColor={pageColor}
                            />
                        </Grid>
                    :
                        null
                }
                <Grid item id="ici">
                    {
                        title !== '' ?
                            <Grid
                                container
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                id="pageTitle"
                                sx={{
                                    height:'55px',
                                    backgroundColor: pageColor
                                }}
                            >
                                <Grid item xs={'auto'}>
                                    <h1
                                        style={{
                                            color: 'rgba(255,255,255,.8)',
                                            margin: '0 0 0 .2em',
                                            fontSize: ['xl','lg','md'].includes(size) ? '2em': '1.5em',
                                            textWrap: 'wrap'
                                    }}
                                    >
                                        {title}
                                    </h1>
                                </Grid>
                                {
                                    location.pathname === '/lead-distri-manager' && !['xs','sm'].includes(size) ?
                                        <FormControlLabel
                                            sx={{color: 'rgba(255,255,255,.8)'}}
                                            control={
                                                <Switch
                                                    onChange={(e) => {
                                                        dispatch(setLeadFilter(e.currentTarget.checked));
                                                    }}
                                                />
                                            }
                                            label="Mes opportunités" />
                                    :
                                        null
                                }
                                <Grid item xs={'auto'} sx={{paddingRight:'.5em'}}>
                                    {
                                        location.pathname === '/devis' ?
                                            <StandardButton
                                                variant="outlined"
                                                onClick={(e) => {
                                                    navigate('/create-devis')
                                                }}
                                            >
                                                {
                                                    ['xs','sm'].includes(size) ?
                                                        '+'
                                                        :
                                                        'Créer un devis'
                                                }
                                            </StandardButton>
                                        :
                                        location.pathname.substring(0,12) === '/liste-devis' ?
                                            <StandardButton
                                                variant="outlined"
                                                onClick={(e) => {
                                                    navigate('/create-devis/' + params.cardcode + '-' + params.internalcode)
                                                }}
                                            >Créer un devis</StandardButton>
                                        :
                                        location.pathname.substring(0,5) === '/lead' ?
                                            <StandardButton
                                                variant="outlined"
                                                onClick={(e) =>

                                                {
                                                    navigate('/create-' + location.pathname.substring(1))
                                                }}
                                            >
                                                {
                                                    ['xs','sm'].includes(size) ?
                                                        '+'
                                                    :
                                                        'Créer une opportunité'
                                                }
                                            </StandardButton>
                                        :
                                        location.pathname === '/commande' ?
                                            <StandardButton
                                                variant="outlined"
                                                onClick={(e) => {
                                                    navigate('/create-commande')
                                                }}
                                            >
                                                {
                                                    ['xs','sm'].includes(size) ?
                                                        '+'
                                                    :
                                                        'Créer une commande'
                                                }

                                            </StandardButton>
                                        :
                                        location.pathname === '/commande-sav' ?
                                            <StandardButton
                                                variant="outlined"
                                                onClick={(e) => {
                                                    navigate('/create-commande-sav')
                                                }}
                                            >
                                                {
                                                    ['xs','sm'].includes(size) ?
                                                        '+'
                                                        :
                                                        'Créer une commande'
                                                }
                                            </StandardButton>
                                        :
                                        location.pathname === '/demande-reappro-stock' ?
                                            <StandardButton
                                                variant="outlined"
                                                onClick={(e) => {
                                                    navigate('/create-reappro')
                                                }}
                                            >
                                                {
                                                    ['xs','sm'].includes(size) ?
                                                        '+'
                                                    :
                                                        'Demande de réappro'
                                                }
                                            </StandardButton>
                                        :
                                        location.pathname === '/gerer-delegues' ?
                                            <StandardButton
                                                variant="outlined"
                                                onClick={(e) => {
                                                    navigate('/create-delegue')
                                                }}
                                            >
                                                Créer un utilisateur
                                            </StandardButton>
                                        :
                                            null
                                    }
                                </Grid>
                            </Grid>:
                            null
                    }
                    {
                        messageInformation !== null && messageInformation.content !== '' ?
                            <Alert
                                severity={
                                    messageInformation.hasOwnProperty('severity') ?
                                        messageInformation.severity:
                                        'info'
                                }
                                id="messageInformation">
                                {
                                    messageInformation.hasOwnProperty('title') && messageInformation.title !== '' ?
                                        <AlertTitle>{messageInformation.title}</AlertTitle>:
                                        null
                                }
                                {messageInformation.content}
                            </Alert>:
                            null
                    }
                    <Box sx={{
                        overflow: 'auto',
                        height: 'calc(100vh - ' + pageHeaderHeight + 'px - ' + pageTitleHeight + 'px - ' + alertHeight + 'px)',
                    }}>
                        {children}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default connect(
    state => ({
        pageColor: state.pageSkeletonReducer.pageColor,
        leadFilter: state.pageSkeletonReducer.leadFilter,
        messageInformation: state.pageSkeletonReducer.messageInformation,
        pdfReaderUrl: state.pdfReaderReducer.pdfReaderUrl
    }),
)(PageSkeleton)
