import React from "react"
import {Grid, Box, Alert, AlertTitle} from "@mui/material";
import {useViewport} from "./hooks/ViewportContext";

const FonctionnaliteNonDisponible = () => {
    const {width, height, size} = useViewport()
    const sceneWidth = ['xl','lg'].includes(size) ?
        width - 275
        :
            size === 'md' ?
                width - 60
            :
                width

    return(
        <>
            <Box
                style={{
                    backgroundImage: 'url(' + process.env.REACT_APP_API_URL + '/HomeAppli-Back-2.png)',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    animation: 'headerAppearence 0.75s forwards',
                    position: 'fixed',
                    // top:50,
                    zIndex:-1,
                    width : sceneWidth,
                    minHeight : height
                }}
            >
            </Box>
            <Box
                style={{
                    animation: 'loginAppearence 1.5s forwards',
                    margin: 'auto',
                    marginTop: '22%',
                    width: 350,
                    padding: 40
            }}>
                <Alert severity="info">
                    <AlertTitle>Non disponible</AlertTitle>
                    Fonctionnalité en cours de réalisation
                </Alert>
            </Box>
        </>
    )
}

export default FonctionnaliteNonDisponible
