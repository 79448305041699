import React from "react"
import PageSkeleton from "../PageSkeleton";
import {Grid} from "@mui/material";
import {connect} from "react-redux";
import PartnerForm from "./PartnerForm";
import {useLocation} from "react-router-dom";

const CreationLead = ({
    user= null,
    userImpersonator = null,
    dispatch
}) => {
    const location = useLocation()
    const uri = location.pathname.substring(1)

    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={"Créer une opportunité"}
            page_active={uri.substring(7)}
            dispatch={dispatch}
        >
            <Grid container>
                <PartnerForm />
            </Grid>
        </PageSkeleton>
    )
}
export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator
    })
)(CreationLead)
