import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {
    Container,
    TextField,
    InputAdornment,
    Skeleton,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Alert,
    IconButton,
    Tooltip
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {apiGet} from "../authentication/OAuth2Utils";
import {filterSearch} from "../Utils";
import {useNavigate} from "react-router-dom"
import {setUserImpersonator} from "../authentication/Login";
import {useViewport} from "../hooks/ViewportContext";
const Usurpation = ({
    user = null,
    userImpersonator = null,
    dispatch
}) => {

    const navigate = useNavigate()
    const [users, setListeUsers] = useState(null)
    const [freeSearch, setFreeSearch] = useState('')
    const {size} = useViewport()

    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#666"
            }
        }
    }

    useEffect(() => {
        dispatch(setMessageInformation(null))
        apiGet('/api/get-profile')
            .then(response => {
                if(response.status === 'success'){
                    setListeUsers(response.liste_user)
                } else {
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: response.error
                    }))
                }
            })
    },[true])

    const handleImpersonate = (email) => {
        localStorage.setItem('switch_user',email)
        apiGet('/api/switch')
            .then(response => {
                if(response.status === 'success'){
                    dispatch(setUserImpersonator(response.data))
                    navigate('/home')
                }
            })
            .catch(e => {
                console.log(e)
                localStorage.removeItem('switch_user')
            })
    }

    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={['xs','sm'].includes(size) ? "Prendre la main" : "Prendre la main sur le compte de ..."}
            page_active="usurpation-identite"
        >
            <Container style={{paddingTop: 40}}>
                {
                    users === null ?
                        <Skeleton variant="rectangular" width={'100%'} height={55}>
                            <FontAwesomeIcon icon={light('circle-user')} size="2x" style={{color: 'white'}} />
                        </Skeleton>
                    :
                        <>
                            <TextField
                                sx={style}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        fontSize: 24,
                                        padding: 10,
                                        color: '#666'
                                    }
                                }}
                                InputProps={{
                                    startAdornment:
                                    <InputAdornment position="start">
                                        <FontAwesomeIcon icon={light('circle-user')} size="2x" />
                                    </InputAdornment>
                                }}
                                fullWidth={true}
                                value={freeSearch !== undefined ? freeSearch:''}
                                onChange={(e) => {
                                    setFreeSearch(e.target.value)
                                }}
                            />
                            <List>
                                {
                                    users.map((user,index) => {
                                        if(filterSearch(freeSearch,user)){
                                            return(
                                                <ListItem
                                                    sx={{cursor:'pointer'}}
                                                    onClick={(e) => {
                                                        handleImpersonate(user.login)
                                                    }}
                                                >
                                                    <ListItemAvatar>
                                                        <DisplayIconByRole role={user.role} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={user.cardName}
                                                        secondary={
                                                            user.firstName + ' ' + user.lastName.toUpperCase()
                                                        }
                                                    />
                                                </ListItem>
                                            )
                                        }
                                    })
                                }
                            </List>
                        </>
                }
            </Container>
        </PageSkeleton>
    )
}

const DisplayIconByRole = ({
    role= null
}) => {
    return (
        <>
        {
            role === 'WEBMANAGER' ?
                <Avatar sx={{bgcolor: '#009ee3'}}>
                    <FontAwesomeIcon icon={light('user-secret')} />
                </Avatar>
            : role === 'ACCOUNTMGR' ?
                <Avatar sx={{bgcolor: '#3fa435'}}>
                    <FontAwesomeIcon icon={light('user-crown')} />
                </Avatar>
            : role === 'ACCOUNTUSER' ?
                <Avatar sx={{bgcolor: '#3fa435'}}>
                    <FontAwesomeIcon icon={light('user')} />
                </Avatar>
            : role === 'SALESAGENT' ?
                <Avatar sx={{bgcolor: '#f9b114'}}>
                    <FontAwesomeIcon icon={light('user-tie')} />
                </Avatar>:
                    null

        }
        </>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption,
    })
)(Usurpation)
