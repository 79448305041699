import React, {useState} from "react"
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, lighten} from "@mui/material";
import {isGranted} from "./Utils";
import {Link, useNavigate} from "react-router-dom"
import {useViewport} from "./hooks/ViewportContext";

const MenuVertical = ({
    user = null,
    position = '',
    pages = [],
    page_active = '',
    pageColor = null,
}) => {
    const navigate = useNavigate()

    const {width, height, size} = useViewport()

    const boxStyle = position !== 'hamburgermenu' ?
        {
            height:'calc(100vh - 50px)',
            borderRight:'5px solid '+pageColor
        }
        :
        {
            marginY:'10px'
        }

    return(
        <Box id={'menuVertical'} sx={boxStyle}>
            {
                pages.map((groupe, index) => {
                    return(
                        <Box key={index}>
                            <List sx={{ paddingY: 0, marginBottom:0}}>
                                {
                                    groupe.listePage.map((page, index2) => {
                                        if(isGranted(user, page.code)){
                                            return(
                                                <ListItem disablePadding key={index+'_'+index2}
                                                    sx={{
                                                        borderLeft: '5px solid'+ page.color,
                                                        marginBottom: '8px'
                                                    }}
                                                >
                                                    <ListItemButton
                                                        onClick={(e) => {
                                                            navigate("/" + page.link)
                                                        }}
                                                        sx={{
                                                            '&:hover' : {
                                                                backgroundColor : lighten(page.color, page_active === page.link ? 0.7 : 0.9)
                                                            },
                                                            backgroundColor : page_active === page.link ? lighten(page.color, 0.7) : 'transparent'
                                                        }}
                                                    >
                                                        {
                                                            page.hasOwnProperty('icon') ?
                                                                <ListItemIcon
                                                                    sx={{
                                                                        color: page.color,
                                                                        minWidth: '40px'
                                                                    }}
                                                                >
                                                                    {page.icon}
                                                                </ListItemIcon>:
                                                                null
                                                        }
                                                        <ListItemText primary={['xl','lg'].includes(size) || position === 'hamburgermenu' ? page.label:<>&nbsp;</>} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </List>
                        </Box>
                    )
                })
            }
        </Box>
    )
}

export default MenuVertical
