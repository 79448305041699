import {useEffect} from 'react';
import {getUser, refreshAccessToken} from "./OAuth2Utils";
import {setCheckingAppCredentials, setUser} from "./Login";
import {connect} from "react-redux";


export const fetchToken = () => dispatch => {

    dispatch(setCheckingAppCredentials(true));
    getUser()
        .then(data => {
            dispatch(setUser(data.data));
        })
        .finally(() => {
            dispatch(setCheckingAppCredentials(false));
        })
};

export const OauthTokenReader = ({
    dispatch
}) => {
    useEffect(() => dispatch(fetchToken()));
    return null;
};

export default connect(
    state => ({
    }),
)(OauthTokenReader)
