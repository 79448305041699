import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import FonctionnaliteNonDisponible from "../FonctionnaliteNonDisponible";
import {Alert, AlertTitle} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {useLocation} from "react-router-dom";
import {createSlice} from "@reduxjs/toolkit";
import DelegateDialog from "../DelegateDialog";
import {setLoading} from "../authentication/Login";

const MiseEnService = ({
    user = null,
    loading = false,
    caption = '',
    dispatch
}) => {
    const [miseEnServiceLoading,setMiseEnServiceLoading] = useState(false)
    const [listMiseEnService, setListMiseEnService] = useState(null)
    const [error,setError] = useState('')
    const location = useLocation()

    useEffect(() => {
        init()
    }, []);

    const init = () => {
        setMiseEnServiceLoading(true)
        let url = location.pathname === '/mise-en-service-distri' ?
            "/api/get-liste-mise-en-service-distri":
            "/api/get-liste-mise-en-service-agent"
        apiGet(url)
            .then(data => {
                if(data.status === 'error'){
                    setError(data.message)
                } else {
                    setListMiseEnService(data.data)
                }
            })
            .finally(() => {
                setMiseEnServiceLoading(false)
            })
    }

    const cancelDelegation = (equipmentCardNum) => {
        setMiseEnServiceLoading(true)
        apiPost('/api/cancel-delegate-mes',{equipmentCardNum : equipmentCardNum})
            .then(data => {
                if(data.status !== 'error'){
                    init()
                }
            })
    }

    return(
        <PageSkeleton
            user={user}
            title="Mises en service"
            page_active={location.pathname.substring(1)}
            dispatch={dispatch}
        >
            {
                error !== '' ?
                    <Alert severity="error">
                        <AlertTitle>Une erreur est survenue</AlertTitle>
                        {error}
                    </Alert>:
                    null
            }
            {
                miseEnServiceLoading ?
                    <TableSkeleton nbCol={6} nbLig={6} />
                :
                    listMiseEnService !== null && listMiseEnService.data.length > 0?
                        <>
                            <TableData
                                columns={listMiseEnService.entete}
                                data={listMiseEnService.data}
                                defaultOrderColumnId={'DeliveryDate'}
                                defaultOrder={'desc'}
                                cancelDelegation={cancelDelegation}
                                dispatch={dispatch}
                                page_active={location.pathname.substring(1)}
                            />
                            <DelegateDialog
                                init={init}
                            />
                        </>
                    :
                        <Alert severity="info">
                            Aucune mise en service trouvée
                        </Alert>
            }
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption,
    }),
)(MiseEnService)
