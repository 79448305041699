import React, {useState, useEffect} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {apiGet} from "../authentication/OAuth2Utils";
import {
    Alert,
    AlertTitle
} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";
import {useLocation} from "react-router-dom";

const Repertoire = ({
    user = null,
    userImpersonator = null,
    loading = false,
    caption = '',
    dispatch
}) => {
    const [repertoireLoading,setRepertoireLoading] = useState(false)
    const [list, setList] = useState(null)

    const location = useLocation()
    const uri = location.pathname.substring(1)

    useEffect(() => {
        dispatch(setMessageInformation(null))
        setRepertoireLoading(true)
        apiGet('/api/get-'+uri)
            .then(data => {
                if(data.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                } else {
                    setList(data.data)
                }
            })
            .finally(() => {
                setRepertoireLoading(false)
            })
    }, []);


    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={'Répertoire'}
            page_active={uri}
            loading={loading}
            caption={caption}
            dispatch={dispatch}
        >
            {
                repertoireLoading
                ?
                    <TableSkeleton nbCol={4} nbLig={6} />
                :
                    list !== null && list.data.length > 0
                    ?
                        <TableData
                            columns={list.entete}
                            data={list.data}
                            defaultOrderColumnId='Identite'
                            defaultOrder={'asc'}
                            dispatch={dispatch}
                        />
                    :
                        <Alert severity="info">
                            Il n'y a aucun élément dans votre répertoire
                        </Alert>
            }
        </PageSkeleton>
    )
}


export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption
    }),
)(Repertoire)
