import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import {reducer as loginReducer} from "./authentication/Login";
import {reducer as pageLoaderReducer} from "./PageLoader";
import {reducer as devisReducer} from "./pages/DevisForm";
import {reducer as partnerReducer} from "./pages/PartnerForm";
import {reducer as pageSkeletonReducer} from "./PageSkeleton";
import {reducer as pdfReaderReducer} from "./dialogs/PdfReaderDialog";
import {reducer as delegateDialogReducer} from "./DelegateDialog";
import {reducer as attachmentDialogReducer} from "./AttachmentDialog";

import {ViewportProvider} from "./hooks/ViewportContext";

export const store = configureStore({
    reducer: {
        loginReducer,
        pageLoaderReducer,
        devisReducer,
        partnerReducer,
        pageSkeletonReducer,
        pdfReaderReducer,
        delegateDialogReducer,
        attachmentDialogReducer,
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ViewportProvider>
            <App/>
        </ViewportProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
