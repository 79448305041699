import React, {useEffect, useState} from "react"
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {connect} from "react-redux";
import {Alert, AlertTitle, Avatar, Container, Grid, Paper, Popper} from "@mui/material";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import {DeleteForever, Euro} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {CancelButton, StandardButton, ValidateButton} from "../Buttons";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {flattenListItem, flattenListShipAddress, flattenListWarehouses} from "../Utils";
import {assoc, assocPath, clone} from "ramda";
import {initDocument, setDocument} from "./DevisForm";
import {useNavigate} from "react-router-dom";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

const ReapproForm = ({
    user = null,
    userImpersonator = null,
    dispatch
}) => {
    const navigate = useNavigate()
    const [document, setDocument] = useState({
        warehouseCode: null,
        journalMemo: null,
        lines: [
            {
                itemCode : '',
                itemName: '',
                quantity : null,
            }
        ]
    })
    const [listItem, setListItem] = useState(null)
    const [flattenedItem, setFlattenedItem] = useState([])
    const [listWarehouses, setListWarehouses] = useState(null)
    const [listFlattenWarehouses, setFlattenListWarehouses] = useState([])
    const [orderCreating, setOrderCreating] = useState(false)

    const handleChangeDocument = (property,value) => {
        setDocument(assoc(property, value, document))
    }

    const handleChangeLine = (index, property, value) => {
        let clonedDoc = clone(document)

        clonedDoc = assocPath(['lines',index, property], value, clonedDoc)
        if(property === 'itemCode') {
            clonedDoc = assocPath(['lines',index, 'quantity'], 1, clonedDoc)
        }
        setDocument(clonedDoc)
    }

    const addLine = () => {
        let clonedDoc = clone(document);
        clonedDoc.lines.push({
            'itemCode' : '',
            'itemName' : '',
            'quantity' : null,
        });
        setDocument(clonedDoc)
    }

    const removeLine = (index) => {
        let clonedDoc = clone(document);
        clonedDoc['lines'].splice(index, 1);
        setDocument(clonedDoc)
    }

    useEffect(() => {
        dispatch(setMessageInformation(null))
        apiGet('/api/order/get-all-data-reappro')
            .then(response => {
                if(response.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: response.message
                    }))
                } else {
                    setListItem(response.items)
                    setFlattenedItem(flattenListItem(response.items))
                    setListWarehouses(response.warehouses)
                    let flattened = flattenListWarehouses(response.warehouses)
                    setFlattenListWarehouses(flattened)
                    if (flattened.length === 1) {
                        handleChangeDocument(
                            'warehouseCode',
                            flattened[0].id
                        )
                    }
                }
             })
    },[])


    const handleValidate = (event) => {
        dispatch(setMessageInformation(null))
        const errorForm = validate()
        if(errorForm !== ''){
            dispatch(setMessageInformation({
                severity: 'error',
                title: 'Une erreur est survenue',
                content: errorForm
            }))
        } else {
            setOrderCreating(true)
            apiPost('/api/demande-reappro', document)
                .then(response => {
                    if (response.status === 'error') {
                        dispatch(setMessageInformation({
                            severity: 'error',
                            title: 'Une erreur est survenue',
                            content: response.message
                        }))
                    } else {
                        navigate('/demande-reappro-stock')
                    }
                })
                .finally(() => {
                    setOrderCreating(false)
                })
        }
    }

    const validate = () => {
        if (document.warehouseCode === null || document.warehouseCode === '') {
            return 'Le champ Entrepôt est obligatoire'
        }
        if (!document.lines.every(line => !line.itemCode || line.quantity)){
            return 'Veuillez sélectionner une quantité pour chaque article'
        }
        return ''
    }

    return (
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title="Demande de réappro"
            page_active="demande-reappro-stock"
            dispatch={dispatch}
        >
            <Container
                sx={{
                    marginTop : '10px'
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant={'standard'}
                            fullWidth={true}
                            label={'Libellé'}
                            value={document.journalMemo ? document.journalMemo : ''}
                            onChange={(e) => handleChangeDocument('journalMemo',e.target.value)}
                        />
                    </Grid>
                </Grid>
                {
                    listFlattenWarehouses.length > 1 ?
                        <Grid container direction="row" spacing={1} style={{marginTop: '.5em'}}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    freeSolo={false}
                                    options={listFlattenWarehouses}
                                    renderInput={
                                        (params) =>
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Entrepôt"
                                                required={true}
                                            />
                                    }
                                    value={
                                        document !== null && document.warehouseCode !== null && listWarehouses !== null ?
                                            {
                                                label: listWarehouses[document.warehouseCode].WarehouseName,
                                                id: document.warehouseCode
                                            }
                                            :
                                            null
                                    }
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(e, newValue) => {
                                        handleChangeDocument(
                                            'warehouseCode',
                                            newValue.id
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    : null
                }
                <Grid container sx={{marginTop: '1em'}}>
                    <Grid item xs={12} md={'auto'}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                            <DatePicker
                                label="Date de livraison souhaitée"
                                onChange={e => {
                                    handleChangeDocument('taxDate',e?.format('YYYY-MM-DD'))
                                }}
                                value={document?.taxDate ? document?.taxDate : null}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            fullWidth={true}
                                        />
                                }
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Typography variant={'h6'} style={{marginTop:20, marginBottom: 10}}>
                    Articles
                </Typography>
                <Paper style={{padding: 20, borderLeft: 'solid 5px #042C48'}}>
                    {
                        document.lines.map((line, index) => {
                            let itemValue = (line.itemCode !== '' && listItem !== null && listItem.hasOwnProperty(line.itemCode)) ?
                                {
                                    label: listItem[line.itemCode].itemName,
                                    id: line.itemCode
                                }:
                                null

                            return(
                                <Grid container direction="row" spacing={1} id={index} key={index} style={{marginBottom:'1em'}}>
                                    <Grid item xs={12} md={9}>
                                        <Autocomplete
                                            disablePortal
                                            options={flattenedItem}
                                            renderInput={(params) => <TextField {...params} variant="standard" label="Article" />}
                                            PopperComponent={CustomPopper}
                                            value={itemValue}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onChange={(e, newValue) => {
                                                handleChangeLine(
                                                    index,
                                                    'itemCode',
                                                    newValue.id
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={2}>
                                        <TextField
                                            label="Quantité"
                                            type="number"
                                            variant="standard"
                                            value={line.quantity ? line.quantity : ''}
                                            onChange={(e) => {
                                                handleChangeLine(
                                                    index,
                                                    'quantity',
                                                    e.target.value
                                                )
                                            }}
                                            inputProps={{style: {textAlign: 'center'}}}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item xs={2} md={1}>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => {
                                                removeLine(index);
                                            }}
                                            fullWidth={true}
                                            style={{maxHeight: 32, marginTop: 12}}
                                        >
                                            <DeleteForever />
                                        </Button>
                                    </Grid>

                                </Grid>
                            )
                        })
                    }


                    <Grid container direction="row" spacing={1} style={{marginTop: 20}}>
                        <Grid item xs={12}>
                            <StandardButton
                                variant="outlined"
                                onClick={() => {
                                    addLine();
                                }}
                            >
                                Ajouter une ligne d'article
                            </StandardButton>
                        </Grid>
                    </Grid>
                </Paper>

                <Grid container direction="row" spacing={3} sx={{marginTop:0}}>
                    <Grid item xs={6}>
                        <CancelButton
                            fullWidth={true}
                            variant="contained"
                            color="info"
                            onMouseUp={(e) => {
                                navigate('/demande-reappro-stock')
                            }}>
                            Annuler
                        </CancelButton>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            orderCreating
                                ?
                                <ValidateButton
                                    fullWidth={true}
                                    variant="contained"
                                    color="success"
                                    disabled
                                >
                                    Création en cours ...
                                </ValidateButton>
                                :
                                <ValidateButton
                                    fullWidth={true}
                                    variant="contained"
                                    color="success"
                                    onClick={handleValidate}
                                >
                                    Créer
                                </ValidateButton>
                        }
                    </Grid>
                </Grid>
            </Container>
        </PageSkeleton>
    )
}

const CustomPopper = function (props) {
    return <Popper {...props} style={{width: "fit-content"}} placement="bottom-start" />;
};

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        pageLoading: state.pageLoaderReducer.pageLoading
    }),
)(ReapproForm)
