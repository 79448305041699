import React, {useEffect, useState, Fragment} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import FonctionnaliteNonDisponible from "../FonctionnaliteNonDisponible";
import {useLocation} from "react-router-dom";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {
    Alert,
    AlertTitle,
    Badge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Tooltip,
    IconButton,
    Input,
    TextField
} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";
import {setLoading} from "../authentication/Login";
import {changePageLoadingAndCaptionState} from "../PageLoader";
import {useViewport} from "../hooks/ViewportContext";
import Typography from "@mui/material/Typography";
import {assocPath, clone} from "ramda";
import {StandardButton} from "../Buttons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {light, solid, regular} from "@fortawesome/fontawesome-svg-core/import.macro";

const Stock = ({
    user = null,
    userImpersonator = null,
    loading = false,
    caption = '',
    dispatch
}) => {
    const [stockLoading,setStockLoading] = useState(false)
    const [list, setList] = useState(null)
    const [cart, setCart] = useState({})
    const [comment, setComment] = useState(null)
    const [cartOpen, setCartOpen] = useState(false)
    const [cartCount, setCartCount] = useState(0)

    const location = useLocation()
    const uri = location.pathname.substring(1)
    const {width, height, size} = useViewport()

    const fetchData = () => {
        dispatch(setMessageInformation(null))
        setStockLoading(true)
        apiGet('/api/get-'+uri)
            .then(data => {
                if(data.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                } else {
                    setList(data.data)
                    if (data.data.data.length === 0) {
                        dispatch(setMessageInformation({
                            severity: 'info',
                            title: 'Information',
                            content: 'Il n\'y a aucun élément dans votre stock'
                        }))
                    }
                }
            })
            .finally(() => {
                setStockLoading(false)
            })
    }

    const handleSetCart = (datum, index, quantity) => {
        if (quantity) {
            quantity=Number(quantity)
        }
        let clonedCart = clone(cart)
        if (!clonedCart.hasOwnProperty(datum.WarehouseCode)){
            clonedCart[datum.WarehouseCode] = {
                'WarehouseName' : datum.WarehouseName,
                'Items' : {}
            }
        }
        if (!clonedCart[datum.WarehouseCode].Items.hasOwnProperty(datum.ItemCode)){
            clonedCart[datum.WarehouseCode].Items[datum.ItemCode] = {
                'ItemName' : datum.ItemName,
                'Quantity' : quantity ? quantity : 1,
                'SerialNumbers' : []
            }
        } else {
            if (quantity === 0) {
                delete clonedCart[datum.WarehouseCode].Items[datum.ItemCode]
                if (Object.keys(clonedCart[datum.WarehouseCode].Items).length === 0) {
                    delete clonedCart[datum.WarehouseCode]
                }
            }
            if (quantity) {
                clonedCart[datum.WarehouseCode].Items[datum.ItemCode].Quantity = quantity
            }
        }

        if (index !== null) {
            if (!clonedCart[datum.WarehouseCode].Items[datum.ItemCode].SerialNumbers
                .includes(datum.CollapseStock[index].IntrSerial)){
                clonedCart[datum.WarehouseCode].Items[datum.ItemCode].SerialNumbers.push(datum.CollapseStock[index].IntrSerial)
                clonedCart[datum.WarehouseCode].Items[datum.ItemCode].Quantity = clonedCart[datum.WarehouseCode].Items[datum.ItemCode].SerialNumbers.length
            } else {
                let indexToRemove = clonedCart[datum.WarehouseCode].Items[datum.ItemCode].SerialNumbers.findIndex(
                    (e) => e === datum.CollapseStock[index].IntrSerial
                )
                clonedCart[datum.WarehouseCode].Items[datum.ItemCode].SerialNumbers.splice(indexToRemove, 1)
                clonedCart[datum.WarehouseCode].Items[datum.ItemCode].Quantity--
            }
        }
        setCart(clonedCart)
        calculateCartCount(clonedCart)
    }

    const removeFromCart = (warehouseCode, itemCode, serialNumber) => {
        let clonedCart = clone(cart)
        if (warehouseCode) {
            if (itemCode) {
                if (serialNumber) {
                    let indexToRemove = clonedCart[warehouseCode].Items[itemCode].SerialNumbers.findIndex(
                        (e) => e === serialNumber
                    )
                    clonedCart[warehouseCode].Items[itemCode].SerialNumbers.splice(indexToRemove, 1)
                    clonedCart[warehouseCode].Items[itemCode].Quantity--
                    if (clonedCart[warehouseCode].Items[itemCode].SerialNumbers.length === 0){
                        delete clonedCart[warehouseCode].Items[itemCode]
                        if (Object.keys(clonedCart[warehouseCode].Items).length === 0) {
                            delete clonedCart[warehouseCode]
                        }
                    }
                } else {
                    delete clonedCart[warehouseCode].Items[itemCode]
                    if (Object.keys(clonedCart[warehouseCode].Items).length === 0) {
                        delete clonedCart[warehouseCode]
                    }
                }
            } else {
                delete clonedCart[warehouseCode]
            }
        }
        setCart(clonedCart)
        calculateCartCount(clonedCart);
    }

    const calculateCartCount = (cartToCalculate) => {
        let count = 0
        if (Object.keys(cartToCalculate).length) {
            Object.keys(cartToCalculate).map((warehouse, warehouseIndex) => {
                if (Object.keys(cartToCalculate[warehouse].Items).length) {
                    return Object.keys(cartToCalculate[warehouse].Items).map((item, itemIndex) => {
                        return count += cartToCalculate[warehouse].Items[item].Quantity
                    })
                }
            })
        }
        setCartCount(count)
        setCartOpen(count>0)
    }

    useEffect(() => {
        fetchData()
    }, []);

    const destock = () => {
        dispatch(setMessageInformation(null))
        dispatch(changePageLoadingAndCaptionState({
            pageLoading: true,
            caption: 'Sortie de stock...'
        }))
        apiPost('/api/destock',{cart, comment})
            .then(response => {
                if(response.status === 'error') {
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: response.message
                    }))
                } else {
                    dispatch(setMessageInformation({
                        severity: 'success',
                        content : 'Sortie de stock effectuée'
                    }))
                    fetchData()
                    setComment(null)
                    setCart({})
                    setCartOpen(false)
                    setCartCount(0)
                }
            })
            .finally(() => {
                dispatch(changePageLoadingAndCaptionState({
                    caption: '',
                    pageLoading: false
                }))
            })
    }

    return(
        <>
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={'Stock'}
            page_active={uri}
            loading={loading}
            caption={caption}
            setCartOpen={setCartOpen}
            cartOpen={cartOpen}
            cartCount={cartCount}
            dispatch={dispatch}
        >
            <Stack
                direction={['xs','sm'].includes(size) ? 'column' : 'row'}
                sx={
                    ['xs','sm'].includes(size) ?
                        {
                            display:'flex',
                            flexDirection:'column',
                            height : height - (105),
                            maxHeight : height - (105)
                        }
                    :
                        {display:'flex'}
                }
            >
                <Box
                    sx={
                        ['xs','sm'].includes(size) ?
                            {
                                flexBasis:'auto',
                                flexGrow:'0',
                                flexShrink:'3',
                                overflow:'auto'
                            }
                        :
                            {
                                overflow: 'auto',
                                height: height - 105,
                                flexBasis: cartOpen ? '65%' : '100%'
                            }
                    }
                >
                    {
                        stockLoading ?
                            <TableSkeleton nbCol={4} nbLig={6} />
                            :
                            list?.data?.length > 0 ?
                                <Grid container>
                                    <Grid item xs={12}>
                                        {
                                            list.data.map((stock, key) =>
                                                <Box
                                                    key={key}
                                                    sx={{
                                                        marginBottom:'3em'
                                                    }}
                                                >
                                                    <Grid container spacing={2} sx={{padding:'1em 1em 0 1em'}}
                                                          justifyContent={'space-between'}
                                                          alignItems={'baseline'}
                                                    >
                                                        <Grid item xs={'auto'}>
                                                            <Typography
                                                                variant={'h6'}
                                                            >
                                                                {stock[0].WarehouseName}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={'auto'}>
                                                            <Typography>
                                                                {stock[0].WarehouseAddress}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <TableData
                                                        columns={list.entete}
                                                        data={stock}
                                                        defaultOrder='asc'
                                                        defaultOrderColumnId='ItemCode'
                                                        loading={loading}
                                                        caption={caption}
                                                        dispatch={dispatch}
                                                        user={user}
                                                        notFixed={true}
                                                        handleSetCart={handleSetCart}
                                                        cart={cart}
                                                    />
                                                </Box>
                                            )
                                        }
                                    </Grid>

                                </Grid>
                                : null
                    }
                </Box>
                {
                    cartOpen ?
                        <>
                            <Paper
                                sx={
                                    ['xs','sm'].includes(size) ?
                                        {
                                            backgroundColor:'#fcfcfc',
                                            padding: '.5em',
                                            flexBasis:'auto',
                                            flexGrow:'0',
                                            flexShrink:'0',
                                            overflow:'auto',
                                            borderTop:'solid #D0D0D0',
                                            maxHeight:(height - (105))/2
                                        }
                                    :
                                        {
                                            overflow: 'auto',
                                            height: height - 121,
                                            backgroundColor:'#fcfcfc',
                                            padding: '.5em',
                                            flexBasis:'35%'
                                        }
                                }
                            >
                                <Typography sx={{fontSize : '1.5em'}}>
                                    Sortie de stock
                                </Typography>
                                <Box>
                                    {
                                        Object.keys(cart).length ?
                                            <List>
                                                {
                                                    Object.keys(cart).map((warehouse, warehouseIndex) =>
                                                        <Fragment key={warehouseIndex}>
                                                            <Divider/>
                                                            <ListItem
                                                                sx={{paddingBottom:0}}
                                                            >
                                                                <ListItemIcon sx={{minWidth: '40px'}}>
                                                                    <FontAwesomeIcon
                                                                        style={{fontSize:'1.5em', color:'#e6007e'}}
                                                                        icon={light('cubes')}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primaryTypographyProps={{
                                                                        'sx' : {fontSize : '1em'}
                                                                    }}
                                                                    primary={cart[warehouse].WarehouseName}
                                                                />
                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        removeFromCart(warehouse)
                                                                    }}
                                                                >
                                                                    <Tooltip
                                                                        title={'Annuler sortie de stock'}
                                                                        placement={'right'}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            style={{fontSize:'.9em', color: 'red'}}
                                                                            icon={regular('xmark')}
                                                                        />
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </ListItem>

                                                            {
                                                                Object.keys(cart[warehouse].Items).length ?
                                                                    <ListItem sx={{paddingY:0}}>
                                                                        <List sx={{paddingY:0}}>
                                                                            {
                                                                                Object.keys(cart[warehouse].Items).map((item, itemIndex) =>
                                                                                    <Fragment key={itemIndex}>
                                                                                        <ListItem
                                                                                            sx={{paddingY:0}}
                                                                                        >
                                                                                            <ListItemIcon sx={{minWidth: '45px'}}>
                                                                                                <FontAwesomeIcon
                                                                                                    style={{fontSize:'.9em', color: '#3fa435'}}
                                                                                                    icon={light('cube')}
                                                                                                />
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        color: '#3fa435',
                                                                                                        fontSize:'.8em',
                                                                                                        lineHeight:'1.2em',
                                                                                                        marginLeft:'.2em'
                                                                                                    }}
                                                                                                >
                                                                                                    {'x'+cart[warehouse].Items[item].Quantity}
                                                                                                </Typography>
                                                                                            </ListItemIcon>
                                                                                            <ListItemText
                                                                                                primaryTypographyProps={{
                                                                                                    'sx' : {fontSize : '.9em'}
                                                                                                }}
                                                                                                primary={
                                                                                                    cart[warehouse].Items[item].ItemName
                                                                                                }
                                                                                            />
                                                                                            {
                                                                                                Object.keys(cart[warehouse].Items[item].SerialNumbers).length ?
                                                                                                    <IconButton
                                                                                                        edge="end"
                                                                                                        onClick={(e) => {
                                                                                                            removeFromCart(warehouse, item)
                                                                                                        }}
                                                                                                    >
                                                                                                        <Tooltip
                                                                                                            title={'Annuler sortie de stock'}
                                                                                                            placement={'right'}
                                                                                                        >
                                                                                                            <FontAwesomeIcon
                                                                                                                style={{fontSize:'.8em', color: 'red'}}
                                                                                                                icon={regular('xmark')}
                                                                                                            />
                                                                                                        </Tooltip>
                                                                                                    </IconButton>
                                                                                                :
                                                                                                    <Box
                                                                                                        sx={{
                                                                                                            display: 'flex',
                                                                                                            alignItems: 'center',
                                                                                                            minWidth: '5em',
                                                                                                            maxWidth: '5em',
                                                                                                        }}
                                                                                                    >
                                                                                                        <IconButton
                                                                                                            onClick={(e) => {
                                                                                                                handleSetCart(
                                                                                                                    {
                                                                                                                        WarehouseCode : warehouse,
                                                                                                                        ItemCode : item
                                                                                                                    },
                                                                                                                    null,
                                                                                                                    cart[warehouse].Items[item].Quantity - 1
                                                                                                                )
                                                                                                            }}
                                                                                                            sx={{padding : '.2em'}}
                                                                                                        >
                                                                                                            <FontAwesomeIcon
                                                                                                                style={{fontSize:'.8em', color: '#0288d1'}}
                                                                                                                icon={regular('square-minus')}
                                                                                                            />
                                                                                                        </IconButton>
                                                                                                        <TextField
                                                                                                            variant={'outlined'}
                                                                                                            size={'small'}
                                                                                                            style={{padding: 0}}
                                                                                                            inputProps={{
                                                                                                                style: {
                                                                                                                    textAlign: 'center',
                                                                                                                    padding: 0,
                                                                                                                    fontSize: '.8em'
                                                                                                                },
                                                                                                                inputMode: 'numeric',
                                                                                                                pattern: '[0-9]*'
                                                                                                            }}
                                                                                                            value={cart[warehouse].Items[item].Quantity}
                                                                                                            onChange={(e) => {
                                                                                                                handleSetCart(
                                                                                                                    {
                                                                                                                        WarehouseCode : warehouse,
                                                                                                                        ItemCode : item
                                                                                                                    },
                                                                                                                    null,
                                                                                                                    e.target.value
                                                                                                                )
                                                                                                            }}
                                                                                                        />
                                                                                                        <IconButton
                                                                                                            onClick={(e) => {
                                                                                                                handleSetCart(
                                                                                                                    {
                                                                                                                        WarehouseCode : warehouse,
                                                                                                                        ItemCode : item
                                                                                                                    },
                                                                                                                    null,
                                                                                                                    cart[warehouse].Items[item].Quantity + 1
                                                                                                                )
                                                                                                            }}
                                                                                                            sx={{padding : '.2em'}}
                                                                                                        >
                                                                                                            <FontAwesomeIcon
                                                                                                                style={{fontSize:'.8em', color: '#0288d1'}}
                                                                                                                icon={regular('square-plus')}
                                                                                                            />
                                                                                                        </IconButton>
                                                                                                    </Box>
                                                                                            }
                                                                                        </ListItem>
                                                                                        {
                                                                                            Object.keys(cart[warehouse].Items[item].SerialNumbers).length ?
                                                                                                <ListItem  sx={{paddingY:0}}>
                                                                                                    <List sx={{paddingTop:0}}>
                                                                                                        {
                                                                                                            Object.keys(cart[warehouse].Items[item].SerialNumbers).map((serialNumber, serialNumberIndex) =>
                                                                                                                <ListItem
                                                                                                                    key={serialNumber}
                                                                                                                    sx={{paddingY:0}}
                                                                                                                >
                                                                                                                    <ListItemIcon sx={{minWidth: '20px'}}>
                                                                                                                        <FontAwesomeIcon
                                                                                                                            style={{fontSize:'.6em', color: '#707070'}}
                                                                                                                            icon={light('barcode')}
                                                                                                                        />
                                                                                                                    </ListItemIcon>
                                                                                                                    <ListItemText
                                                                                                                        primaryTypographyProps={{
                                                                                                                            'sx' : {fontSize : '.8em', marginRight:'1em'}
                                                                                                                        }}
                                                                                                                        primary={cart[warehouse].Items[item].SerialNumbers[serialNumber]}
                                                                                                                    />
                                                                                                                    <IconButton
                                                                                                                        onClick={(e) => {
                                                                                                                            removeFromCart(warehouse, item, cart[warehouse].Items[item].SerialNumbers[serialNumber])
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Tooltip
                                                                                                                            title={'Annuler sortie de stock'}
                                                                                                                            placement={'right'}
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon
                                                                                                                                style={{fontSize:'.7em', color: 'red'}}
                                                                                                                                icon={regular('xmark')}
                                                                                                                            />
                                                                                                                        </Tooltip>
                                                                                                                    </IconButton>
                                                                                                                </ListItem>
                                                                                                            )
                                                                                                        }
                                                                                                    </List>
                                                                                                </ListItem>
                                                                                            : null
                                                                                        }
                                                                                    </Fragment>
                                                                                )
                                                                            }
                                                                        </List>
                                                                    </ListItem>
                                                                    : null
                                                            }
                                                        </Fragment>
                                                    )
                                                }
                                            </List>
                                            :
                                            <Typography>
                                                Aucun article
                                            </Typography>
                                    }
                                </Box>
                                <TextField
                                    sx={{
                                        marginBottom:'1em'
                                    }}
                                    label="Commentaire"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    multiline
                                    value = {comment ? comment : ''}
                                    onChange={(e) => {
                                        setComment(e.target.value)
                                    }}
                                />
                                <StandardButton
                                    variant="outlined"
                                    fullWidth={true}
                                    onClick={(e) => {
                                        destock()
                                    }}
                                >
                                    Effectuer la sortie de stock
                                </StandardButton>
                            </Paper>
                        </>
                    : null
                }
            </Stack>
        </PageSkeleton>
    </>
    )
}
export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption
    }),
)(Stock)
