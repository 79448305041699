import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import PageSkeleton from "../PageSkeleton";
import FonctionnaliteNonDisponible from "../FonctionnaliteNonDisponible";
import {useLocation} from "react-router-dom";
import {apiGet} from "../authentication/OAuth2Utils";
import {Alert, AlertTitle} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";

const GestionDelegues = ({
    user = null,
    userImpersonator = null,
    dispatch
}) => {
    const [loading,setLoading] = useState(false)
    const [list, setList] = useState(null)
    const [error,setError] = useState('')

    useEffect(() => {
        setLoading(true)
        apiGet("/api/get-liste-delegues")
            .then(data => {
                if(data.status === 'error'){
                    setError(data.message)
                } else {
                    setList(data.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, []);

    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title="Utilisateurs"
            page_active="gerer-delegues"
            dispatch={dispatch}
        >
            {
                error !== '' ?
                    <Alert severity="error">
                        <AlertTitle>Une erreur est survenue</AlertTitle>
                        {error}
                    </Alert>:
                    null
            }
            {
                loading ?
                    <TableSkeleton nbCol={6} nbLig={6} />
                :
                    list !== null && list.data.length > 0 ?
                        <TableData
                            columns={list.entete}
                            data={list.data}
                            defaultOrderColumnId={'LastName'}
                            defaultOrder={'asc'}
                            dispatch={dispatch} />
                    :
                        <Alert severity="info">
                            Aucun délégué trouvé
                        </Alert>
            }
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator
    }),
)(GestionDelegues)
