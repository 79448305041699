import React, {useEffect, useState} from "react"
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {connect} from "react-redux";
import {Alert, AlertTitle, Avatar, Container, Grid, Paper, Popper} from "@mui/material";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import {DeleteForever, Euro} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {CancelButton, StandardButton, ValidateButton} from "../Buttons";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {flattenListItem, flattenListShipAddress, flattenListWarehouses} from "../Utils";
import {assoc, assocPath, clone} from "ramda";
import {initDocument, setDocument, TextFieldLimited} from "./DevisForm";
import {useNavigate} from "react-router-dom";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

const DelegueForm = ({
    user = null,
    userImpersonator = null,
    dispatch
}) => {
    const [document, setDocument] = useState({
        nom : '',
        prenom : '',
        email : '',
        mobile : '',
        phone1 : '',
        phone2 : '',
        notes : '',
    })
    const [creating, setCreating] = useState(false)
    const navigate = useNavigate()

    const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

    const handleChangeDocument = (property,value) => {
        setDocument(assoc(property, value, document))
    }

    const handleValidate = (event) => {
        dispatch(setMessageInformation(null))
        const errorForm = validate()
        if(errorForm !== ''){
            dispatch(setMessageInformation({
                severity: 'error',
                title: 'Une erreur est survenue',
                content: errorForm
            }))
        } else {
            setCreating(true)
            apiPost('/api/create-delegue', document)
                .then(response => {
                    if (response.status === 'error') {
                        dispatch(setMessageInformation({
                            severity: 'error',
                            title: 'Une erreur est survenue',
                            content: response.message
                        }))
                    } else {
                        navigate('/gerer-delegues')
                    }
                })
                .finally(() => {
                    setCreating(false)
                })
        }
    }

    const validate = () => {
        if (document.nom === null || document.nom === '') {
            return 'Le champ Nom est obligatoire'
        }
        if (document.prenom === null || document.prenom === '') {
            return 'Le champ Prénom est obligatoire'
        }
        if (document.email === null || document.email === '') {
            return 'Le champ Email est obligatoire'
        }
        return ''
    }

    return (
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title="Créer utilisateur"
            page_active="gerer-delegues"
            dispatch={dispatch}
        >
            <Container
                sx={{
                    marginTop : '10px'
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant={'standard'}
                            fullWidth={true}
                            label={'Nom'}
                            value={document.nom ? document.nom : ''}
                            onChange={(e) =>
                                handleChangeDocument('nom',e.target.value.toUpperCase())
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant={'standard'}
                            fullWidth={true}
                            label={'Prénom'}
                            value={document.prenom ? document.prenom : ''}
                            onChange={(e) =>
                                handleChangeDocument('prenom',capitalize(e.target.value))
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant={'standard'}
                            fullWidth={true}
                            label={'Email'}
                            value={document.email ? document.email : ''}
                            onChange={(e) =>
                                handleChangeDocument('email',e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant={'standard'}
                            fullWidth={true}
                            label={'Tél. portable'}
                            value={document.mobile ? document.mobile : ''}
                            onChange={(e) =>
                                handleChangeDocument('mobile',e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant={'standard'}
                            fullWidth={true}
                            label={'Téléphone 1'}
                            value={document.phone1 ? document.phone1 : ''}
                            onChange={(e) =>
                                handleChangeDocument('phone1',e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant={'standard'}
                            fullWidth={true}
                            label={'Téléphone 2'}
                            value={document.phone2 ? document.phone2 : ''}
                            onChange={(e) =>
                                handleChangeDocument('phone2',e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldLimited
                            label="Commentaire"
                            variant="standard"
                            value={document.notes ? document.notes : ''}
                            fullWidth={true}
                            maxLength={100}
                            onChange={(e) =>
                                handleChangeDocument('notes',e.target.value)
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container direction="row" spacing={3} sx={{marginTop:0}}>
                    <Grid item xs={6}>
                        <CancelButton
                            fullWidth={true}
                            variant="contained"
                            color="info"
                            onMouseUp={(e) => {
                                navigate('/gerer-delegues')
                            }}>
                            Annuler
                        </CancelButton>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            creating
                                ?
                                <ValidateButton
                                    fullWidth={true}
                                    variant="contained"
                                    color="success"
                                    disabled
                                >
                                    Création en cours ...
                                </ValidateButton>
                                :
                                <ValidateButton
                                    fullWidth={true}
                                    variant="contained"
                                    color="success"
                                    onClick={handleValidate}
                                >
                                    Créer
                                </ValidateButton>
                        }
                    </Grid>
                </Grid>
            </Container>
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        pageLoading: state.pageLoaderReducer.pageLoading
    }),
)(DelegueForm)
