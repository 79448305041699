import React from "react"
import {Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, Modal} from "@mui/material";
import {Email, LocalPhone} from "@mui/icons-material";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #042C48',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};


export const DisplayLead = ({
    partner = null,
    setPartner = null
}) => {
    const handleClose = (e) => {
        setPartner(null)
    }

    let initiale = partner !== null ?
        partner?.FirstName ?
            partner.FirstName.substring(0,1)
        : ''
        + partner?.LastName ?
            partner.LastName.substring(0,1)
        : ''
    : ''

    return(
        <Modal open={partner !== null} onClose={handleClose}>
            <Box sx={{
                ...style,
                width: 300,
                padding : '1em'
            }}>
                <Grid container alignItems={'center'} spacing={2}>
                    <Grid item xs={2}>
                        <Avatar sx={{bgcolor: 'orange'}}>{initiale}</Avatar>
                    </Grid>
                    <Grid item xs={10}>
                        <h3 style={{margin:'0'}}>
                            {partner !== null ? partner.ClientName: ''}
                        </h3>
                    </Grid>
                </Grid>
                <Grid container spacing={2} paddingTop={'0.5em'}>
                    <Grid item xs={2} />
                    <Grid item xs={10}>
                        <small>{partner !== null ? partner.CardCode: ''}</small>
                    </Grid>
                    <Grid item xs={12} >
                        <Divider/>
                    </Grid>
                    <Grid item xs={2}>
                        {
                            partner !== null && partner.E_Mail !== null ?
                                <a href={"mailto:" + partner.E_Mail}><Email /></a>:
                                <Email style={{color: 'grey'}} />

                        }
                    </Grid>
                    <Grid item xs={10}>
                        {
                            partner !== null && partner.E_Mail !== null ?
                                <a href={"mailto:" + partner.E_Mail} style={{textDecoration: 'none'}}>{partner.E_Mail}</a>:
                                ''
                        }
                    </Grid>
                    <Grid item xs={2}>
                        {
                            partner !== null && partner.MobilePhone !== null ?
                                <a href={"tel:" + partner.MobilePhone} style={{textDecoration: 'none'}}><LocalPhone /></a>:
                                <LocalPhone style={{color: 'grey'}} />
                        }
                    </Grid>
                    <Grid item xs={10}>
                        {
                            partner !== null && partner.MobilePhone !== null ?
                                <a href={"tel:" + partner.MobilePhone} style={{textDecoration: 'none'}}>{partner.MobilePhone}</a>:
                                null
                        }
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
