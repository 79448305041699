import React, {useState,useEffect} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {apiGet} from "../authentication/OAuth2Utils";
import {Alert, AlertTitle} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";

const CommandeSav = ({
    user = null,
    userImpersonator = null,
    dispatch
}) => {
    const [commandeSAVLoading,setCommandeSAVLoading] = useState(false)
    const [listCommandeSAV, setListCommandeSAV] = useState(null)

    useEffect(() => {
        dispatch(setMessageInformation(null))
        setCommandeSAVLoading(true)
        apiGet('/api/get-commandes-sav')
            .then(data => {
                if(data.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                } else {
                    setListCommandeSAV(data.data)
                }
            })
            .finally(() => {
                setCommandeSAVLoading(false)
            })
    }, []);

    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title="Commandes sous garantie"
            page_active="commande-sav"
            dispatch={dispatch}
        >
            {
                commandeSAVLoading ?
                    <TableSkeleton nbCol={6} nbLig={6} />:
                    listCommandeSAV !== null && listCommandeSAV.commandeSAV.length > 0?
                        <TableData
                            columns={listCommandeSAV.entete}
                            data={listCommandeSAV.commandeSAV}
                            dispatch={dispatch}
                        />
                    :
                        null
            }
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator
    }),
)(CommandeSav)
