import React, {useEffect, useState} from "react"
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {connect} from "react-redux";
import {Alert, AlertTitle, Avatar, Container, Grid, Paper, Popper} from "@mui/material";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import {DeleteForever, Euro} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {CancelButton, StandardButton, ValidateButton} from "../Buttons";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {flattenListItem, flattenListShipAddress} from "../Utils";
import {assoc, assocPath, clone} from "ramda";
import {initDocument, setDocument} from "./DevisForm";
import {useNavigate, useParams} from "react-router-dom";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {changePageLoadingAndCaptionState, changePageLoadingState, PageLoader} from "../PageLoader";

const DestockForm = ({
    user = null,
    userImpersonator = null,
    pageLoading = null,
    dispatch
}) => {
    const navigate = useNavigate()
    const params = useParams()

    const [document, setDocument] = useState(null)
    const [destockCreating, setDestockCreating] = useState(false)

    useEffect(() => {
        dispatch(setMessageInformation(null))
        dispatch(changePageLoadingState(true))
        apiPost('/api/get-destock-data',params)
            .then(response => {
                if(response.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: response.message
                    }))
                } else {
                    setDocument(response.data)
                }
             })
            .finally(() => {
                dispatch(changePageLoadingState(false))
            })
    },[])


    const setDestock = () => {
        dispatch(setMessageInformation(null))
        const validationError = validate();
        if (validationError) {
            dispatch(setMessageInformation({
                severity: 'error',
                title: 'Une erreur est survenue',
                content: validationError
            }))
        } else {
            setDestockCreating(true)
            apiPost('/api/destock-agent',document)
                .then(response => {
                    if(response.status === 'error'){
                        dispatch(setMessageInformation({
                            severity: 'error',
                            title: 'Une erreur est survenue',
                            content: response.message
                        }))
                    } else {
                        navigate('/commande-agent')
                    }
                })
                .finally(() => {
                    setDestockCreating(false)
                })
        }
    }

    const validate = () => {
        return ''
    }

    return (
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title="Sortie de stock"
            page_active="commande-agent"
            loading = {pageLoading}
            dispatch={dispatch}
        >
            {
                document ?
                    <Container
                        sx={{
                            marginTop : '10px'
                        }}
                    >
                        <Typography variant={'h6'} sx={{marginBottom:'.8em'}}>
                            Commande n°{document.DocNum} - réf. {document.NumAtCard}
                        </Typography>
                        {
                            document.Items.map((item, itemIndex) =>
                                <Grid
                                    container
                                    alignItems={'center'}
                                    key={itemIndex}
                                    sx={{
                                        marginBottom:'1em',
                                        borderTop:'1px solid lightgrey',
                                        paddingTop:'.5em'
                                    }}
                                >
                                    <Grid item xs={12} md={8}>
                                        <Typography sx={{fontSize:'1.1em', marginBottom:'.2em'}}>
                                            {item.ItemCode} - {item.ItemDescription}
                                        </Typography>
                                        <Typography component={'span'} sx={{fontSize:'1em', marginRight:'.5em'}}>
                                            {item.WarehouseName}
                                        </Typography>
                                        <Typography
                                            component={'span'}
                                            sx={{
                                                fontSize:'.75em',
                                                whiteSpace:'pre-wrap'
                                        }}>
                                            {item.Address}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        {
                                            !item.SerialNumbers ?
                                                    <Typography sx={{color : 'red'}}>
                                                        Aucun numéro de série disponible ({item.Quantity} sortie{item.Quantity > 1 ? 's' :''} à effectuer)
                                                    </Typography>
                                                :
                                                    item.SerialNumbers.map((serialNumber, serialNumberIndex) =>
                                                        <Grid container key={serialNumberIndex}>
                                                            <Autocomplete
                                                                value=
                                                                    {document.Items[itemIndex].SerialNumbers[serialNumberIndex]['Selected'] ?
                                                                        document.Items[itemIndex].SerialNumbers[serialNumberIndex]['Selected']
                                                                        : null
                                                                    }
                                                                onChange={(event, newValue) => {
                                                                    let clonedDoc = clone(document)
                                                                    clonedDoc.Items[itemIndex].SerialNumbers[serialNumberIndex]['Selected']=newValue
                                                                    setDocument(clonedDoc);
                                                                }}
                                                                onInputChange={(event, newInputValue) => {
                                                                    if (document.Items[itemIndex].SerialNumbers[serialNumberIndex].Choices.includes(newInputValue)) {
                                                                        let clonedDoc = clone(document)
                                                                        clonedDoc.Items[itemIndex].SerialNumbers[serialNumberIndex]['Selected']=newInputValue
                                                                        setDocument(clonedDoc);
                                                                    }
                                                                }}
                                                                fullWidth={true}
                                                                renderInput={
                                                                    (params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            variant={'standard'}
                                                                            label={"Numéro de série" +(item.Quantity > 1 ? " article "+(serialNumberIndex+1) : '') }
                                                                        />
                                                                }
                                                                options={serialNumber.Choices}
                                                            />
                                                        </Grid>
                                                    )
                                        }
                                    </Grid>
                                </Grid>
                            )
                        }

                        <Grid container spacing={3} sx={{marginTop:'.5em'}}>
                            <Grid item xs={6}>
                                <CancelButton
                                    fullWidth={true}
                                    variant="contained"
                                    color="info"
                                    onMouseUp={(e) => {
                                        navigate('/commande-agent')
                                    }}>
                                    Annuler
                                </CancelButton>
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    destockCreating
                                        ?
                                        <ValidateButton
                                            fullWidth={true}
                                            variant="contained"
                                            color="success"
                                            disabled
                                        >
                                            Validation en cours ...
                                        </ValidateButton>
                                        :
                                        <ValidateButton
                                            fullWidth={true}
                                            variant="contained"
                                            color="success"
                                            onClick={(e)=>{setDestock()}}
                                        >
                                            Valider
                                        </ValidateButton>
                                }
                            </Grid>
                        </Grid>
                    </Container>
                : null
            }
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        pageLoading: state.pageLoaderReducer.pageLoading
    }),
)(DestockForm)
