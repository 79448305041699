import React from "react";
import {createSlice} from "@reduxjs/toolkit";
import {Backdrop, CircularProgress} from "@mui/material";
import { makeStyles } from '@mui/styles';


export const PageLoaderSlice = createSlice({
   name: 'pageLoaderManager',
   initialState: {
       pageLoading: false,
       caption: 'Traitement en cours ...',
       oldCaption : '',
       messageInformation : null
   },
    reducers: {
       changePageLoadingState: (state, action) => {
           return {
               ...state,
               pageLoading: action.payload,
               oldCaption: ''
           }
       },
        changePageLoadingAndCaptionState: (state, action) => {
           if(action.payload.pageLoading){
               return {
                   ...state,
                   pageLoading: true,
                   caption: action.payload.caption,
                   oldCaption: state.caption
               }
           } else {
               return {
                   ...state,
                   pageLoading: false,
                   caption: state.oldCaption,
                   oldCaption: ''
               }
           }
        }
    }
});

export const {actions, reducer} = PageLoaderSlice;

export const {changePageLoadingState,changePageLoadingAndCaptionState} = actions;

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 1000,
    },
}));

export const PageLoader = ({
    pageLoading = false,
    caption = 'Traitement en cours ...',
    dispatch
}) => {
    const classes = useStyles();
    return(
        <Backdrop open={pageLoading} className={classes.backdrop}>
            <CircularProgress style={{marginRight: '10px',color: '#004A9C'}} />
            <span>{caption}</span>
        </Backdrop>
    )

}
