import React from "react"
import {connect} from "react-redux";
import PageSkeleton from "../PageSkeleton";
import FonctionnaliteNonDisponible from "../FonctionnaliteNonDisponible";

const MonCompte = ({
    user = null,
    userImpersonator = null,
    dispatch
}) => {
    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title="Mon compte"
            page_active="mon-compte"
            dispatch={dispatch}
        >
            <FonctionnaliteNonDisponible />
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator
    }),
)(MonCompte)
