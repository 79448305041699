import React from "react"
import {connect} from "react-redux";
import PageSkeleton from "../PageSkeleton";
import FonctionnaliteNonDisponible from "../FonctionnaliteNonDisponible";

const VenteStock = ({
    user = null,
    userImpersonator = null,
    dispatch
}) => {
    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title="Ventes sur stock"
            page_active="vente-stock"
            dispatch={dispatch}
        >
            <FonctionnaliteNonDisponible />
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator
    }),
)(VenteStock)
