import React, {useEffect, useState} from "react"
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions, Alert, AlertTitle, Popper, FormControl, Select, MenuItem, Card
} from "@mui/material";
import {createSlice} from "@reduxjs/toolkit";
import {connect} from "react-redux";
import Grid from "@mui/material/Grid";
import {CancelButton, StandardButton} from "./Buttons";
import {assoc, clone, set} from "ramda";
import {FileInput} from "./pages/MiseEnServiceForm";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import {apiPost} from "./authentication/OAuth2Utils";
import {changePageLoadingState} from "./PageLoader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular} from "@fortawesome/fontawesome-svg-core/import.macro";

export const AttachmentDialogSlice = createSlice({
    name: 'attachmentDialogManager',
    initialState: {
        attachmentDialogData: false,
    },
    reducers: {
        setAttachmentDialogData: (state, action) => {
            return {
                ...state,
                attachmentDialogData: action.payload
            }
        }
    }
});

export const {actions, reducer} = AttachmentDialogSlice;

export const {
    setAttachmentDialogData
} = actions;

const AttachmentDialog = ({
    attachmentDialogData = null,
    init = null,
    dispatch,
}) => {
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState('')
    const [document,setDocument] = useState('')
    const [creating,setCreating] = useState(false)
    const [files,setFiles] = useState({})
    const [imageLoaded, setImageLoaded] = useState([])

    const imageTypes = ['png','jpg','jpeg','bmp','gif','svg']

    useEffect(() => {
        if (!!attachmentDialogData){
            let newDoc = clone(attachmentDialogData)
            setError('')
            setCreating(false)
            apiPost('/api/get-quotation-attachment', attachmentDialogData)
                .then(response => {
                    if(response.status === 'error'){
                        setError(response.message)
                    } else {
                        newDoc['dateLivraison'] = response.data.dateLivraison
                        newDoc['groupeTaxe'] = response.data.groupeTaxe
                        initFile([
                            {id:'devis', url:response.data.devis},
                            {id:'certifTva', url:response.data.certifTva},
                        ])
                    }
                })
                .finally(() => {
                    setDocument(newDoc)
                })
        }
    }, [attachmentDialogData]);

    const handleImageLoaded = (id, value) => {
        let imageLoadedClone = clone(imageLoaded);
        if (imageLoadedClone.includes(id) && !value){
            imageLoadedClone = imageLoadedClone.filter(item => item !== id)
        } else {
            imageLoadedClone.push(id)
        }
        setImageLoaded(imageLoadedClone)
    }


    const handleSetFile = (id,file) => {
        let clonedFiles = clone(files)
        if (file) {
            clonedFiles[id] = {
                'id' : id,
                'file' : file,
            }
            clonedFiles[id].url = URL.createObjectURL(file)
        } else {
            delete clonedFiles[id]
        }
        setFiles(clonedFiles)
    }

    const createForm = () => {
        let form = new FormData
        Object.keys(document).map((key) => {
            form.append(key, document[key])
        })
        Object.keys(files).map((key) => {
            form.append(key+'_name', files[key].file.name)
            form.append(key, files[key].file)
        })
        return form
    }

    const handleValidate = () => {
        let error = validate()
        if (error === ''){
            setLoading(true)
            let form = createForm();
            apiPost('/api/create-quotation-attachment', form)
                .then(response => {
                    if(response.status === 'error'){
                        setError(response.message)
                    } else {
                        dispatch(setAttachmentDialogData(null))
                        setDocument(null)
                        setFiles({})
                        init()
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            setError(error)
        }
    }

    const validate = () => {
        if (!Object.keys(files).length){
            return 'Au moins une pièce jointe doit être renseignée.'
        }
        return ''
    }

    const initFile = (attachmentInfos) => {
        let clonedFiles = clone(files)
        attachmentInfos.map((infos) => {
            if (infos?.id && infos?.url) {
                let fileName = decodeURI(infos.url.split('\\').pop().split('/').pop().split('?').shift())
                let extension = fileName.split('.').pop()
                clonedFiles[infos.id] = {
                    'id': infos.id,
                    'file': {
                        'name': fileName
                    },
                    'extension' : extension,
                    'url': infos.url,
                }
            }
        })
        setFiles(clonedFiles)
    }

    return(
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={!!attachmentDialogData}
            onClose={(e) => {
                dispatch(setAttachmentDialogData(null))
                setDocument(null)
                setFiles({})
            }
        }>
            <DialogTitle sx={{fontSize:'1.7em'}}>
                Pièces jointes
            </DialogTitle>
            <DialogContent>
                {
                    error !== '' ?
                        <Alert severity="error" sx={{lineBreak:'anywhere'}}>
                            <AlertTitle>Une erreur est survenue</AlertTitle>
                            {error}
                        </Alert>
                    :
                        null
                }
                <Grid item xs={12} container spacing={2} direction={'column'}>
                    {
                        document ?
                            <>
                                <Grid item>
                                    <FileInput
                                        id={'devis'}
                                        label={'Devis signé'}
                                        accepted={
                                            'application/msword,'+
                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'+
                                            'application/vnd.ms-powerpoint,'+
                                            'application/vnd.openxmlformats-officedocument.presentationml.slideshow,'+
                                            'application/vnd.openxmlformats-officedocument.presentationml.presentation'+
                                            'application/msword,'+
                                            'application/vnd.ms-excel,'+
                                            'application/vnd.ms-powerpoint,' +
                                            'application/pdf,' +
                                            'image/*'
                                        }
                                        handleSetFile={handleSetFile}
                                        imageLoaded={imageLoaded}
                                        handleImageLoaded={handleImageLoaded}
                                        files={files}
                                        dispatch={dispatch}
                                    />
                                </Grid>
                                {
                                    document?.groupeTaxe === 'C06R' ?
                                        <Grid item>
                                            <FileInput
                                                id={'certifTva'}
                                                label={'Certif. TVA'}
                                                accepted={
                                                    'application/msword,'+
                                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'+
                                                    'application/vnd.ms-powerpoint,'+
                                                    'application/vnd.openxmlformats-officedocument.presentationml.slideshow,'+
                                                    'application/vnd.openxmlformats-officedocument.presentationml.presentation'+
                                                    'application/msword,'+
                                                    'application/vnd.ms-excel,'+
                                                    'application/vnd.ms-powerpoint,' +
                                                    'application/pdf,' +
                                                    'image/*'
                                                }
                                                handleSetFile={handleSetFile}
                                                imageLoaded={imageLoaded}
                                                handleImageLoaded={handleImageLoaded}
                                                files={files}
                                                dispatch={dispatch}
                                            />
                                        </Grid>
                                    :
                                        null
                                }
                                <Grid item >
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                        <DatePicker
                                            label="Date de livraison souhaitée"
                                            onChange={e => {
                                                setDocument(assoc('dateLivraison', e?.format('YYYY-MM-DD'), document))
                                            }}
                                            value={document?.dateLivraison ? document?.dateLivraison : null}
                                            renderInput={
                                                (params) =>
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                    />
                                            }
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </>
                        :
                            <Grid item xs={12} sx={{textAlign:'center'}}>
                                <FontAwesomeIcon
                                    className={"fa-spin"}
                                    style={{
                                        color: 'LightGrey',
                                        height: '3em',
                                    }}
                                    icon={regular('cog')}
                                />
                            </Grid>
                    }

                </Grid>
            </DialogContent>
            <DialogActions>
                {
                    document ?
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CancelButton
                                    disabled={loading}
                                    fullWidth={true}
                                    style={{color: 'white'}}
                                    onClick={(e) => {
                                        setDocument(null)
                                        setFiles({})
                                        dispatch(setAttachmentDialogData(null))
                                    }}
                                >
                                    Annuler
                                </CancelButton>
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    loading ?
                                        <Button
                                            disabled
                                            color={'info'}
                                            variant="contained"
                                            fullWidth={true}
                                        >
                                            Enregistrement en cours...
                                        </Button>
                                        :
                                        <Button
                                            color={'info'}
                                            variant="contained"
                                            fullWidth={true}
                                            onClick={(event) => {
                                                handleValidate()
                                            }}
                                        >
                                            Valider
                                        </Button>
                                }
                            </Grid>
                        </Grid>
                    : null
                }

            </DialogActions>
        </Dialog>
    )
}

const CustomPopper = function (props) {
    return <Popper {...props} style={{width: "fit-content"}} placement="bottom-start" />;
};

export default connect(
    state => ({
        attachmentDialogData: state.attachmentDialogReducer.attachmentDialogData
    }),
)(AttachmentDialog)
