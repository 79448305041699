import React, {useState} from "react";
import {
    Dialog,
    DialogContent,
    LinearProgress,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Stack,
    Typography
}
    from "@mui/material";

import {createSlice} from "@reduxjs/toolkit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {duotone} from "@fortawesome/fontawesome-svg-core/import.macro";

export const PdfReaderSlice = createSlice({
    name: 'pdfReaderManager',
    initialState: {
        pdfReaderUrl: null
    },
    reducers: {
        setPdfReaderUrl: (state, action) => {
            return {
                ...state,
                pdfReaderUrl: action.payload
            }
        }
    }
})

export const {actions,reducer} = PdfReaderSlice

export const {setPdfReaderUrl} = actions

export const PdfReaderDialog = ({
    pdfReaderUrl= null,
    dispatch
}) => {
    const handleClose = () => {
        if(iframeVisible){
            setIframeVisible(false)
            dispatch(setPdfReaderUrl(null))
        }
    }

    const [iframeVisible,setIframeVisible] = useState(false)

    return(
        <Dialog
            open={pdfReaderUrl !== null}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth={iframeVisible}
            {...iframeVisible ? {
                PaperProps: {
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                    },
                    sx: {
                        minHeight: '80vh',
                        maxHeight: '80vh'
                    }
                }
            }:
            {
                PaperProps: {
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }
            }
        }
        >
            <DialogContent>
                <iframe
                    src={pdfReaderUrl}
                    style={{
                        border: 'none',
                        width: '100%',
                        height: '75vh',
                        display : iframeVisible ? '':'none'
                    }}
                    onLoad={() => {
                        if(pdfReaderUrl !== null){
                            setIframeVisible(true)
                        }
                    }}
                />
                {
                    !iframeVisible ?
                        <TableContainer component={Paper}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <LinearProgress />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{padding: '20px 30px'}}>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                            >
                                                <FontAwesomeIcon icon={duotone('gears')} size="3x" style={{marginRight: 10}} />
                                                <Typography>
                                                    Génération du document en cours ...
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>:
                        null
                }
            </DialogContent>
        </Dialog>
    )
}
