export const authenticate = ({login, password}) => {
    let data = new FormData();
    data.append('grant_type', 'password');
    data.append('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID);
    data.append('client_secret', process.env.REACT_APP_OAUTH_CLIENT_SECRET);
    data.append('username', login);
    data.append('password', password);
    return fetch(process.env.REACT_APP_API_URL + '/token', {
        method: 'post',
        body: data
    })
    .then(response => {
        return response.json()
    })
    .then(data => {
        return data
    })
};

export const refreshAccessToken = () => {
    let data = new FormData();
    data.append('grant_type', 'refresh_token');
    data.append('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID);
    data.append('client_secret', process.env.REACT_APP_OAUTH_CLIENT_SECRET);
    data.append('refresh_token', localStorage.getItem('refresh_token'));
    return fetch(process.env.REACT_APP_API_URL + '/token', {
        method: 'post',
        body: data
    }).then(response => {
        return response.json()
    }).then(data => {
        if (data.hasOwnProperty('error')){
            throw data.error;
        }
        localStorage.setItem('access_token', data['access_token']);
        localStorage.setItem('refresh_token', data['refresh_token']);
        return data
    })
};

export const apiGet = (url,numberCall= 0) => {
    let paramHeader = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('access_token'),
        "Origin": window.location.hostname
    }
    if(localStorage.getItem('switch_user') !== null && localStorage.getItem('switch_user') !== ''){
        paramHeader['X-Switch-User'] = localStorage.getItem('switch_user')
    }

    return fetch(process.env.REACT_APP_API_URL + url + '?' + Date.now(), {
        method: 'get',
        headers: paramHeader
    })
    .catch(e => {
        if(url !== '/api/user'){
            return refreshAccessToken()
        }
    })
    .then(response=> {
        if(response.type !== undefined && response.type === 'cors'){
            return response.json()
        } else {
            if(response?.refresh_token){
                if(numberCall < 1){
                    return apiGet(url,numberCall+1)
                } else {
                    return Promise.reject()
                }
            } else {
                return Promise.reject('General failure')
            }
        }
    })

    .catch(e => {
        if(url !== '/api/user' && numberCall < 1) {
            localStorage.removeItem('switch_user')
            window.location = '/login'
            return Promise.reject('')
        } else {
            return Promise.resolve({status: 'error',error: 'erreur de compilation de l\'api.'})
        }
    })
    .then(data => {
        return data
    })
};

export const apiGetFile = (url, params) => {
    let searchParams='';
    if (params) {
        Object.keys(params).forEach(key => searchParams += '&'+key+'='+params[key]);
    }
    let paramHeader = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('access_token'),
        "Origin": window.location.hostname
    }
    if(localStorage.getItem('switch_user') !== null && localStorage.getItem('switch_user') !== ''){
        paramHeader['X-Switch-User'] = localStorage.getItem('switch_user')
    }
    return fetch(process.env.REACT_APP_API_URL + url + '?' + Date.now() + searchParams, {
        method: 'get',
        headers: paramHeader
    })
        .catch(e => {
        })
        .then(response =>{
            console.log(response)
            return response.blob()
        })
        .then(data => {
            console.log(data);
            return data
        })
};

export const apiGetFileSimple = (url) => {
    return fetch(process.env.REACT_APP_API_URL + url + '?' + Date.now(), {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token'),
            "Origin": window.location.hostname
        }
    })
    .catch(e => {
    })
    .then(data => {
        return data
    })
};

export const apiPost = (url, body, numberCall) => {
    let paramHeader = {
        "Authorization": "Bearer " + localStorage.getItem('access_token'),
        "Origin": window.location.hostname
    }
    if (body.constructor.name !== 'FormData') {
        paramHeader["Content-Type"] = "application/json"
    }

    if(localStorage.getItem('switch_user') !== null && localStorage.getItem('switch_user') !== ''){
        paramHeader['X-Switch-User'] = localStorage.getItem('switch_user')
    }

    return fetch(process.env.REACT_APP_API_URL + url, {
        method: 'POST',
        body: body.constructor.name === 'FormData' ? body : JSON.stringify(body),
        headers: paramHeader
    })
    .catch(e => {
        return refreshAccessToken()
    })
    .then(response => {
        if(response.type !== undefined && response.type === 'cors'){
            return response.json()
        } else {
            if(response?.refresh_token){
                if(numberCall < 1) {
                    return apiPost(url, body, numberCall + 1)
                } else {
                    return Promise.reject()
                }
            } else {
                return Promise.reject('General failure')
            }
        }
    })
    .catch(e => {
        if(numberCall < 1) {
            localStorage.removeItem('switch_user')
            window.location = '/login'
            return Promise.reject()
        } else {
            return Promise.resolve({status: 'error',error: 'erreur de compilation de l\'api.'})
        }
    })
    .then(data => {
        return data
    })
};

export const anonymousApiPost = (url,body) => {
    return fetch(process.env.REACT_APP_API_URL + url, {
        method: 'post',
        body: JSON.stringify(body),

    })
        .then(response => {
            console.log(response)
            return response.json()
        })
        .catch(e => {
            console.warn(e)
        })
        .then(data => {
            return data
        })
}

export const getUser = () => {
    return apiGet('/api/user');
};
