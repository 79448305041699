import React, {useState, useEffect} from "react";

import { createSlice } from '@reduxjs/toolkit'
import {changePageLoadingState, PageLoader, PageLoaderSlice, reducer as pageLoaderReducer} from "../PageLoader";
import {
    Alert,
    AlertTitle,
    Avatar,
    Backdrop,
    Box,
    CircularProgress, Container, Divider,
    Paper,
    Switch,
    Stack,
    TableCell,
    TableSortLabel,
    Popper,
} from "@mui/material";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {connect} from "react-redux";
import PartnerForm, {setPartner} from "./PartnerForm"
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {TableData, TableSkeleton} from "../TableUtils";
import {assoc, assocPath, clone, filter} from "ramda";
import {
    flattenListConditionPaiement, flattenListCustomer,
    flattenListItem,
    flattenListShipAddress, flattenListWarehouse,
    objectValuesAlternative
} from "../Utils";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {CancelButton, StandardButton, ValidateButton} from "../Buttons";
import {Calculate, DeleteForever, Euro, PersonAdd, Info} from "@mui/icons-material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import RemiseDialog from "../dialogs/RemiseDialog";
import AddressForm from "./AddressForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useViewport} from "../hooks/ViewportContext";

export const initialState = {
    document : {
        docEntry: null,
        docNum: null,
        cardCode: null,
        internalCode : null,
        numAtCard : null,
        commentaireLigne: null,
        accompte: null,
        // modePaiement: null,
        conditionPaiement: null,
        dateChantierPrevue: null,
        groupeTaxe: 'C06P',
        billToAddress2: null,
        shipToCode: '',
        lines : [
            {
                lineNum: null,
                itemCode : '',
                quantity : null,
                price : null,
                remise: 0,
                userText: '',
            }
        ]
    },
};

export const devisSlice = createSlice({
    name: 'devis',
    initialState: initialState,
    reducers: {
        setDocument: (state, action) => {
            let clonedDoc = clone(state.document);
            clonedDoc[action.payload.property] = action.payload.value;
            if(action.payload.property === 'internalCode'){
                // Analyse des mode et condition de paiement
                let partnerObj = action.payload.listCustomer[action.payload.value]
                clonedDoc.cardCode = partnerObj.CardCode
                // clonedDoc.modePaiement = partnerObj.PeymentMethodCode
                clonedDoc.dateChantierPrevue = partnerObj.U_W3C_DAPR
                //clonedDoc.billToAddress2 = partnerObj.AddressName2
                if(partnerObj.PayTermsGrpCode === 7 || partnerObj.PayTermsGrpCode === 9){
                    clonedDoc.conditionPaiement = partnerObj.PayTermsGrpCode
                } else {
                    clonedDoc.conditionPaiement = 'default'
                }
                //suppression des lines d'articles car price list changée
                clonedDoc.lines = [
                    {
                        lineNum: null,
                        itemCode : '',
                        warehouseCode : null,
                        quantity : null,
                        price : null,
                        remise: 0,
                        userText: '',
                    }
                ]
            }
            if(action.payload.hasOwnProperty('billToAddress2')){
                clonedDoc.billToAddress2 = action.payload.billToAddress2
            }
            return {
                ...state,
                document: clonedDoc
            }
        },
        setLine: (state, action) => {
            let clonedDoc = clone(state.document);
            clonedDoc.lines[action.payload.index][action.payload.property] = action.payload.value;
            if(action.payload.property === 'itemCode'){
                clonedDoc.lines[action.payload.index].price = action.payload.price;
                clonedDoc.lines[action.payload.index].userText = action.payload.userText;
                clonedDoc.lines[action.payload.index].quantity = 1;
            }
            return {
                ...state,
                document: clonedDoc
            }
        },
        addLine: (state, action) => {
            let clonedDoc = clone(state.document);
            clonedDoc.lines.push({
                'itemCode' : '',
                'itemName' : '',
                'warehouseCode' : null,
                'quantity' : null,
                'price' : null,
                'lineNum': null,
                'remise': 0,
                'userText' : '',
            });
            return {
                ...state,
                document:clonedDoc
            }
        },
        removeLine: (state, action) => {
            let clonedDoc = clone(state.document);
            clonedDoc['lines'].splice(action.payload, 1);
            if (clonedDoc['lines'].length === 0){
                clonedDoc['lines']=[
                    {
                        lineNum: null,
                        itemCode : '',
                        quantity : null,
                        price : 0,
                        remise: 0,
                        userText: '',
                    }
                ]
            }
            return {
                ...state,
                document:clonedDoc
            }
        },
        initDocument: (state, action) => {
            if(action.payload === undefined){
                return {
                        ...state,
                        document : {
                            docEntry: null,
                            docNum: null,
                            cardCode: null,
                            internalCode : null,
                            numAtCard : null,
                            commentaireLigne: null,
                            accompte: null,
                            // modePaiement: null,
                            conditionPaiement: null,
                            dateChantierPrevue: null,
                            groupeTaxe: 'C06P',
                            billToAddress2: '',
                            shipToCode: null,
                            lines : [
                                {
                                    lineNum: null,
                                    itemCode : '',
                                    warehouseCode: null,
                                    quantity : null,
                                    price : null,
                                    remise: 0,
                                    userText : '',
                                }
                            ]
                        }
                    }
                } else {
                    return {
                        ...state,
                        document: action.payload
                    }
                }
            }
    }
});
export const {actions, reducer} = devisSlice;

export const {
    setDocument,
    setLine,
    addLine,
    removeLine,
    initDocument,
    setQuotationAllData
} = actions;

export const useStyles = makeStyles(theme => ({
    thinTextField:{
        '& input':{
            padding: 4
        },
        '& .MuiOutlinedInput-root .MuiAutocomplete-input':{
            padding: '0 !important'
        }
    }
}))


const DevisForm = ({
    user = null,
    userImpersonator = null,
    document = null,
    pageLoading = true,
    dispatch
}) => {
    const [listCustomer, setListCustomer] = useState(null)
    const [listItem, setListItem] = useState(null)
    // const [listModePaiement, setListModePaiement] = useState(null)
    const [listConditionPaiement, setListConditionPaiement] = useState(null)
    const [listGroupeTva, setListGroupTva] = useState(null)
    const [openPartner,setOpenPartner] = useState(false)
    const [flattenedCustomer, setFlattenedCustomer] = useState([])
    const [flattenedItem, setFlattenedItem] = useState([])
    // const [flattenedModePaiement, setFlattenedModePaiement] = useState([])
    const [flattenedConditionPaiement, setFlattenedConditionPaiement] = useState([])
    const [quotationCreating,setQuotationCreating] = useState(false)
    const [selectedLine,setSelectedLine] = useState(null)
    const [listWarehouse, setListWarehouse] =useState(null)
    const [flattenedWarehouse, setFlattenedWarehouse] =useState(null)

    const [selectedCardCode,setSelectedCardCode] = useState(null)
    const [listShipAddress, setListShipAddress] = useState(null)
    const [listFlattenShipAddress, setFlattenListShipAddress] = useState([])

    const [contextMenu, setContextMenu] = useState(false)

    const [openAddress,setOpenAddress] = useState(false)
    const [address, setAddress] = useState(null)

    const {size} = useViewport()

    const navigate = useNavigate()
    const classes = useStyles()

    const params = useParams()

    useEffect(() => {
        if(params.hasOwnProperty('docentry') || params.hasOwnProperty('internalcode')){
            dispatch(changePageLoadingState(true))
        }
        apiPost('/api/quotation/get-all-data', params)
            .then(data => {
                if(data.status === 'error'){
                    //setMessage('error', data.message);

                    dispatch(setMessageInformation({
                        severity: 'error',
                        content: data.message
                    }))

                } else {
                    setListCustomer(data.customers);
                    setFlattenedCustomer(flattenListCustomer(data.customers));
                    setListItem(data.items)
                    // setListModePaiement(data.modes_paiement)
                    setListConditionPaiement(data.conditions_paiement)
                    setListGroupTva(data.groupes_tva)
                    setListWarehouse(data.warehouses)
                    setFlattenedItem(flattenListItem(data.items));
                    // setFlattenedModePaiement(flattenListModePaiement(data.modes_paiement))
                    setFlattenedConditionPaiement(flattenListConditionPaiement(data.conditions_paiement))
                    setFlattenedWarehouse(flattenListWarehouse(data.warehouses))
                    if(data.quotation !== null){
                        dispatch(initDocument(data.quotation))
                    }
                    if(params.hasOwnProperty('internalcode')){
                        let obj = {
                            property: 'internalCode',
                            value: params.internalcode,
                            listCustomer: data.customers
                        }
                        dispatch(setDocument(obj))
                    }
                }
            })
            .finally(() => {
                dispatch(changePageLoadingState(false))
            })
        return () => {
            dispatch(initDocument())
        }
    }, []);

    const fetchAddresses = () => {
        let params = {
            cardCode: document.cardCode
        }
        apiPost('/api/get-ship-addresses', params)
            .then(data => {
                if (data.status === 'error') {
                    //setMessage('error', data.message);
                    dispatch(setMessageInformation({
                        severity: 'error',
                        content: data.message
                    }))
                } else {
                    setListShipAddress(data.addresses)
                    setFlattenListShipAddress(flattenListShipAddress(data.addresses))
                }
            })
    }

    useEffect(() => {
        if(document.cardCode !== null && selectedCardCode !== document.cardCode){
            setSelectedCardCode(document.cardCode)
            fetchAddresses()
        }
    },[document])

    const getCustomerLabelByInternalCode = (internalCode) => {
        if(listCustomer !== null && listCustomer.hasOwnProperty(internalCode)){
            return listCustomer[internalCode].CardName
        } else {
            return internalCode
        }
    }

    const createQuotation = () => {
        dispatch(setMessageInformation(null))
        const errorForm = validateQuotation()
        if(errorForm !== ''){
            dispatch(setMessageInformation({
                severity: 'error',
                content: errorForm
            }))
        } else {
            setQuotationCreating(true);
            apiPost('/api/create-quotation', document)
                .then(data => {
                    if (data.status === 'error') {
                        dispatch(setMessageInformation({
                            severity: 'error',
                            content:  data.message
                        }))
                        //setMessage('error', data.message);
                    } else {
                        dispatch(setMessageInformation({
                            severity: 'success',
                            content:  'Devis créé'
                        }))
                        //setMessage('success', 'Devis créé')

                        navigate('/devis')
                    }
                })
                .finally(() => {
                    setQuotationCreating(false);
                })
        }
    }

    const validateQuotation = () => {
        if (document.internalCode === null || document.internalCode === '') {
            return 'Le champ Client est obligatoire'
        }
        if (document.numAtCard === null || document.numAtCard === ''){
            return 'Le champ N° réf. client est obligatoire'
        }
        if (!document.lines.every(line =>
            !line.itemCode || (line.price >= listItem[line.itemCode].prices[18])
        )){
            return 'Veuillez vérifier les prix des articles'
        }
        if (flattenedWarehouse.length > 1) {
            if (!document.lines.every(line => !line.itemCode || line.warehouseCode)){
                return 'Veuillez sélectionner un stock pour chaque article'
            }
        }
        if(document.dateChantierPrevue === null){
            return 'La date prévisionnelle du chantier est obligatoire'
        }
        if(document.shipToCode === null || document.shipToCode === ''){
            return 'Vous devez sélectionner une adresse de livraison. Si elle n\'est pas présente dans la liste proposée, veuillez contacter Innoclair'
        }
        if(document.conditionPaiement === null || document.conditionPaiement === ''){
            return 'Vous devez sélectionner une condition de paiement'
        }
        return ''
    }

    const handleClickAccompte = (event) => {
        if(document.accompte === null){
            //Calcul
            dispatch(setDocument({property: 'accompte', value: 0}))
        } else {
            dispatch(setDocument({property: 'accompte', value: null}))
        }
    }

    const calculTotal = () => {
        let result = 0
        document.lines.map((line, index) => {
            if(line.quantity !== null && line.price !== null){
                result += line.quantity * (line.price - line.remise)
            }
        })
        result=Math.round(result*100)/100
        return result
    }

    const calculTva = () => {
        let result = 0
        let totalHT = calculTotal()
        if(listGroupeTva !== null && listGroupeTva.hasOwnProperty(document.groupeTaxe)){
            let taxeObj = listGroupeTva[document.groupeTaxe]
            let tauxTva = taxeObj.VatRate
            result = totalHT * (tauxTva / 100)
            result=Math.round(result*100)/100
        }
        return result
    }

    const handleCalculAccompte = (event) => {
        let totalTTC = calculTotal() + calculTva()

        let accompte = Math.round(totalTTC*0.3)
        dispatch(setDocument({
            property : 'accompte',
            value: accompte
        }))
    }

    const handleCardCode = (e) => {
        e.preventDefault()
        setContextMenu(!contextMenu)
    }

    const addressAdded = () => {
        fetchAddresses()
        dispatch(setDocument({
            property : 'shipToCode',
            value: address.label
        }))
    }

    let total = calculTotal()
    let tva = calculTva()
    let totalTTC = total + tva

    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={document !== null && document.docEntry !== null ? 'Modifier un devis':"Créer un devis"}
            page_active="devis"
            dispatch={dispatch}
        >
            <Container
                sx={{
                    marginTop : '10px'
                }}
            >
                <Grid container direction="row" spacing={1} alignItems={'center'}>
                    <Grid item xs>
                        <Autocomplete
                            disablePortal
                            options={flattenedCustomer}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Client"
                                    required={true}
                                />
                            }
                            fullWidth={true}
                            disabled={document === null || document.docEntry !== null}
                            noOptionsText={
                                flattenedCustomer === null || flattenedCustomer.length === 0 ?
                                    <Stack direction="row">
                                        <CircularProgress />
                                        <div style={{marginLeft: 20, marginTop: 8}}>Récupération des clients / opportunités en cours ...</div>
                                    </Stack>:
                                    'Aucun client / opportunité ne correspond à vos critères'
                        }
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            groupBy={(option) => option.category}
                            value={document !== null && document.internalCode !== null ?
                                {
                                    id: document.internalCode.toString(),
                                    label: getCustomerLabelByInternalCode(document.internalCode.toString())
                                } : null}
                            className={classes.thinTextField}
                            onChange={(e, newValue) => {
                                if (newValue !== null) {
                                    dispatch(setDocument({
                                        property : 'internalCode',
                                        value : newValue.id,
                                        listCustomer,
                                        listConditionPaiement
                                    }))
                                } else {
                                    dispatch(initDocument())
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={'auto'}  onContextMenu={handleCardCode}>
                        <StandardButton
                            fullWidth = {true}
                            startIcon={<PersonAdd />}
                            variant="outlined"
                            disabled={document === null || document.docEntry !== null}
                            onClick={() => {
                                setOpenPartner(true);
                            }}
                        >
                            {
                                ['xs','sm'].includes(size) ?
                                    null
                                :
                                    contextMenu ?
                                        document.cardCode:
                                        'Nouveau client'
                            }
                        </StandardButton>
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldLimited
                            label={
                                ['xs','sm'].includes(size) ?
                                    "Référence client à utiliser"
                                :
                                    "Veuillez indiquer ici la référence client que vous souhaitez utiliser"
                            }
                            required={true}
                            variant="standard"
                            value={document.numAtCard ? document.numAtCard : ''}
                            fullWidth={true}
                            className={classes.thinTextField}
                            maxLength={100}
                            onChange={(e) => {
                                dispatch(setDocument({
                                    property : 'numAtCard',
                                    value : e.target.value
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs sx={{marginTop : '20px'}}>
                        <Autocomplete
                            disablePortal
                            freeSolo={false}
                            options={listFlattenShipAddress}
                            renderInput={
                                (params) =>
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Adresse de livraison"
                                        required={true}
                                    />
                            }
                            value={
                                document?.shipToCode && listShipAddress !== null && !!listShipAddress[document?.shipToCode] ?
                                    {
                                        label: listShipAddress[document.shipToCode].Street + ' ' + listShipAddress[document.shipToCode].ZipCode + ' ' + listShipAddress[document.shipToCode].City + ' ('+document.shipToCode+')',
                                        id: document.shipToCode
                                    }
                                    :
                                    null
                            }
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(e, newValue) => {

                                dispatch(setDocument({
                                    property: 'shipToCode',
                                    value: newValue.id,
                                    billToAddress2: listShipAddress[newValue.id].AddressName2
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={'auto'} sx={{marginTop : '20px'}}>
                        <StandardButton
                            fullWidth
                            startIcon={<FontAwesomeIcon icon={solid('plus')}/>}
                            variant="outlined"
                            onClick={() => {
                                setAddress({
                                    customerCardCode : document.cardCode,
                                    type : 'shipping',
                                    apiUrl : '/anonymous-api/create-address'
                                })
                                setOpenAddress(true);
                            }}
                        >
                            {
                                ['xs','sm'].includes(size) ?
                                    null
                                :
                                   'Ajouter'
                            }
                        </StandardButton>
                    </Grid>
                    <Grid item xs={12} md={'auto'} sx={{marginTop : '20px'}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                            <DatePicker
                                fullWidth={true}
                                label="Date de chantier prévue"
                                onChange={e => {
                                    dispatch(setDocument({
                                        property: 'dateChantierPrevue',
                                        value: e?.format('YYYY-MM-DD')
                                    }))
                                }}
                                value={document.dateChantierPrevue ? document.dateChantierPrevue : null}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            required={true}
                                        />
                                }
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Première ligne d'adresse"
                            variant="standard"
                            value={document.billToAddress2 ? document.billToAddress2 : ''}
                            fullWidth={true}
                            className={classes.thinTextField}
                            onChange={(e) => {
                                dispatch(setDocument({
                                    property : 'billToAddress2',
                                    value : e.target.value
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            options={flattenedConditionPaiement}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Condition de paiement"
                                    required={true}
                                />
                            }
                            value={
                                document.conditionPaiement && listConditionPaiement && listConditionPaiement[document.conditionPaiement] ?
                                    {
                                        label: listConditionPaiement[document.conditionPaiement].paymentTermsGroupName,
                                        id: document.conditionPaiement
                                    }
                                :
                                    ''
                            }
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(e, newValue) => {
                                dispatch(setDocument({
                                    property : 'conditionPaiement',
                                    value : newValue.id
                                }))
                            }}
                        />
                    </Grid>
                </Grid>
                {
                    document.internalCode ?
                        <>
                            <Typography variant={'h5'} style={{marginTop:20, marginBottom: 10}}>
                                Articles
                            </Typography>
                            <Paper style={{padding: 20, borderLeft: 'solid 5px #042C48'}}>
                                {
                                    document.lines.map((line, index) => {
                                        let itemValue = (line.itemCode !== '' && listItem !== null && listItem.hasOwnProperty(line.itemCode)) ?
                                            {
                                                label: listItem[line.itemCode].itemName,
                                                id: line.itemCode
                                            }:
                                            ''
                                        let warehouseValue = (line.warehouseCode !== '' && listWarehouse !== null && listWarehouse.hasOwnProperty(line.warehouseCode)) ?
                                            {
                                                label: listWarehouse[line.warehouseCode].Warehouse,
                                                id: line.warehouseCode
                                            }:
                                            ''
                                        return(
                                            <Grid container direction="row" spacing={1} alignItems={'center'} id={index} key={index} style={{marginBottom:5}}>
                                                <Grid item xs={12} md={flattenedWarehouse.length > 1 ? 5 : 7}>
                                                    <Autocomplete
                                                        disablePortal
                                                        options={flattenedItem}
                                                        renderInput={(params) =>
                                                            <TextField {...params} variant="standard" label="Article" />
                                                        }
                                                        PopperComponent={CustomPopper}
                                                        value={itemValue}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        onChange={(e, newValue) => {
                                                            dispatch(setLine({
                                                                index,
                                                                property : 'itemCode',
                                                                value : newValue.id,
                                                                price : listItem[newValue.id].prices[listCustomer[document.internalCode].PriceListNum],
                                                                userText : listItem[newValue.id].userText
                                                            }))
                                                        }}
                                                    />
                                                </Grid>
                                                {
                                                    flattenedWarehouse.length > 1 ?
                                                        <Grid item xs={9} md={3}>
                                                            <Autocomplete
                                                                disablePortal
                                                                options={flattenedWarehouse}
                                                                renderInput={(params) =>
                                                                    <TextField {...params} variant="standard" label="Stock" />
                                                                }
                                                                PopperComponent={CustomPopper}
                                                                value={warehouseValue}
                                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                onChange={(e, newValue) => {
                                                                    dispatch(setLine({
                                                                        index,
                                                                        property : 'warehouseCode',
                                                                        value : newValue.id,
                                                                    }))
                                                                }}
                                                            />
                                                        </Grid>
                                                    : null
                                                }

                                                <Grid item xs={3} md={1}>
                                                    <TextField
                                                        label="Quantité"
                                                        type="number"
                                                        variant="standard"
                                                        value={line.quantity ? line.quantity : ''}
                                                        onChange={(e) => {
                                                            dispatch(setLine({
                                                                index,
                                                                property : 'quantity',
                                                                value : e.target.value
                                                            }))
                                                        }}
                                                        inputProps={{style: {textAlign: 'center'}}}
                                                        fullWidth={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={flattenedWarehouse.length > 1 ? 2 : 3}>
                                                    <DisplayTotalLigne
                                                        line={line}
                                                        index={index}
                                                        setSelectedLine={setSelectedLine}
                                                        listItem={listItem}
                                                        setLine={setLine}
                                                        dispatch={dispatch}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => {
                                                            dispatch(removeLine(index));
                                                        }}
                                                    >
                                                        <DeleteForever />
                                                    </Button>
                                                </Grid>

                                            </Grid>
                                        )
                                    })
                                }
                                <Grid container direction="row" spacing={1} style={{marginTop: 20}}>
                                    <Grid item xs={12}>
                                        <StandardButton
                                            variant="outlined"
                                            onClick={() => {
                                                dispatch(addLine());
                                            }}
                                        >
                                            Ajouter une ligne d'article
                                        </StandardButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </>
                    : null
                }
                <Grid container spacing={1} style={{marginTop: 20}}>
                    <Grid item xs={12}>
                        <TextField
                            multiline={true}
                            fullWidth={true}
                            label="Commentaire de fin de page"
                            value={document.commentaireLigne ? document.commentaireLigne : ''}
                            onChange={(e) => {
                                dispatch(setDocument({
                                    property: 'commentaireLigne',
                                    value: e.target.value
                                }))}
                            }
                        />
                    </Grid>
                </Grid>

                {
                    document.lines[0].itemCode !== '' ?
                        <Paper style={{padding: 20, marginRight: 20, marginTop: 20, borderLeft: 'solid 5px #042C48'}}>
                            <Grid container alignItems={'center'} justifyContent={"space-between"} sx={{marginBottom : '1.5em'}}>
                                <Grid item xs={12} sm={'auto'}>
                                    Demande d'acompte
                                    <Switch checked={document.accompte !== null} onClick={handleClickAccompte} />
                                </Grid>
                                <Grid item xs={12} sm={'auto'}>
                                    {
                                        document.accompte !== null ?
                                            <Button
                                                onClick={handleCalculAccompte}
                                                sx={{padding : 0, minWidth : 0}}
                                            >
                                                <Calculate/>
                                            </Button> :
                                            null
                                    }
                                    {
                                        document.accompte !== null ?
                                            <TextField
                                                variant="standard"
                                                type='number'
                                                value={document.accompte}
                                                onChange={(e) => {
                                                    dispatch(setDocument({
                                                        property: 'accompte',
                                                        value: e.currentTarget.value
                                                    }))
                                                }}
                                                inputProps={{style: {textAlign: 'right'}}}
                                                InputProps={{
                                                    endAdornment: <Avatar sx={{ width: 24, height: 24, backgroundColor: '#042C48', marginLeft: 1 }}><Euro sx={{fontSize: 14}} /></Avatar>
                                                }} />:
                                            null
                                    }
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} justifyContent={"space-between"} sx={{marginBottom : '1.5em'}}>
                                <Grid item xs={12} sm={'auto'}>
                                    Total HT
                                </Grid>
                                <Grid item xs={12} sm={'auto'}>
                                    <TextField
                                        variant="standard"
                                        type='number'
                                        value={total}
                                        inputProps={{style: {textAlign: 'right'}}}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <Avatar sx={{ width: 24, height: 24, backgroundColor: '#042C48', marginLeft: 1 }}><Euro sx={{fontSize: 14}} /></Avatar>
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} justifyContent={"space-between"}>
                                <Grid item xs={12} sm={'auto'}>
                                    <Typography>
                                        TVA
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={'auto'}>
                                    <Select
                                        variant="standard"
                                        value={document.groupeTaxe !== null ? document.groupeTaxe : 'C06P'}
                                        onChange={(e) => {
                                            dispatch(setDocument({
                                                property: 'groupeTaxe',
                                                value: e.target.value
                                            }))
                                        }}
                                    >
                                        {
                                            listGroupeTva !== null ?
                                                objectValuesAlternative(listGroupeTva).map((groupeTva,index) => {
                                                    return (
                                                        <MenuItem value={groupeTva.VatCode} key={index}>{groupeTva.VatName}</MenuItem>
                                                    )
                                                }):
                                                null
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={'auto'}>
                                    <TextField
                                        variant="standard"
                                        type='number'
                                        value={tva}
                                        inputProps={{style: {textAlign: 'right'}}}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <Avatar sx={{ width: 24, height: 24, backgroundColor: '#042C48', marginLeft: 1 }}><Euro sx={{fontSize: 14}} /></Avatar>
                                        }} />
                                </Grid>
                            </Grid>
                            <Grid item xs={'auto'} style={{paddingTop: 20, paddingBottom: 20}}>
                                <Divider />
                            </Grid>
                            <Grid container alignItems={'center'} justifyContent={"space-between"}>
                                <Grid item xs={12} sm={'auto'}>
                                    Total TTC
                                </Grid>
                                <Grid item xs={12} sm={'auto'}>
                                    <TextField
                                        variant="standard"
                                        type='number'
                                        value={totalTTC}
                                        inputProps={{style: {textAlign: 'right'}}}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <Avatar sx={{ width: 24, height: 24, backgroundColor: '#042C48', marginLeft: 1 }}><Euro sx={{fontSize: 14}} /></Avatar>
                                        }} />
                                </Grid>
                            </Grid>
                        </Paper>
                        :
                        null
                }

                <Grid container direction="row" spacing={1} style={{marginTop:15, marginBottom: 20}}>
                    <Grid item xs={6}>
                        <CancelButton
                            fullWidth={true}
                            variant="contained"
                            style={{marginRight: 20}}
                            color="info"
                            onMouseUp={(e) => {
                                dispatch(initDocument())
                                navigate('/devis')
                            }}>
                            Annuler
                        </CancelButton>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            quotationCreating
                                ?
                                <ValidateButton
                                    fullWidth={true}
                                    variant="contained"
                                    color="success"
                                    disabled
                                >
                                    {
                                        document !== null && document.docEntry !== null ?
                                            'Valider':
                                            'Créer'
                                    }
                                </ValidateButton>
                                :
                                <ValidateButton
                                    fullWidth={true}
                                    variant="contained"
                                    color="success"
                                    onClick={createQuotation}
                                >
                                    {
                                        document !== null && document.docEntry !== null ?
                                            'Valider':
                                            'Créer'
                                    }
                                </ValidateButton>
                        }
                    </Grid>
                </Grid>
            </Container>


            <Drawer
                sx={{
                    maxWidth:500,
                    '& .MuiDrawer-paper': {
                        maxWidth:500
                    }
                }}
                anchor={'right'}
                open={openPartner}
                onClose={() => setOpenPartner(false)}
            >
                <PartnerForm
                    setListCustomer={setListCustomer}
                    setFlattenedCustomer={setFlattenedCustomer}
                    flattenListItem={flattenListItem}
                    setOpenPartner={setOpenPartner}
                    // listModePaiement={listModePaiement}
                    listConditionPaiement={listConditionPaiement}
                />
            </Drawer>
            <PageLoader pageLoading={pageLoading} dispatch={dispatch} caption="Chargement en cours..." />
            <RemiseDialog
                open={selectedLine !== null}
                selectedLine={selectedLine}
                setSelectedLine={setSelectedLine}
                dispatch={dispatch}
            />
            <Drawer
                sx={{
                    maxWidth:500,
                    '& .MuiDrawer-paper': {
                        maxWidth:500
                    }
                }}
                anchor={'right'}
                open={openAddress}
                onClose={() => setOpenAddress(false)}
            >
                <AddressForm
                    setOpenAddress={setOpenAddress}
                    address={address}
                    setAddress={setAddress}
                    addressAdded={addressAdded}
                />
            </Drawer>
        </PageSkeleton>
    )
}

const CustomPopper = function (props) {
    return <Popper {...props} style={{width: "fit-content"}} placement="bottom-start" />;
};

const DisplayTotalLigne = ({
    line = null,
    index = null,
    setSelectedLine = null,
    setLine = null,
    listItem = null,
    dispatch
}) => {
    // borderBottom: 'solid 1px #999',
    //     display: 'flex',
    //     alignItems: 'end',
    //     justifyContent: 'end',
    //     paddingBottom: 4,
    //     marginTop : '11px'
    return(
        <Box
            sx={{
                'borderBottom' : '1px solid #999',
                'paddingTop' : '15px'
            }}
        >
            <Grid container
                  alignItems={'center'}
                  spacing={1}
            >
                {
                    line !== null && line.remise > 0 ?
                        <Grid item xs>
                            <TextField
                                value={line.price}
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true
                                }}
                                inputProps={{
                                    style: {
                                        color: '#469cdd',
                                        textDecoration: 'line-through',
                                        fontSize: 16
                                    }
                                }}
                                onChange={(e) => {
                                    dispatch(setLine({
                                        index,
                                        property : 'price',
                                        value : e.target.value
                                    }))
                                }}
                            />
                        </Grid>:
                        null
                }
                {
                    line !== null ?
                        line.remise > 0 ?
                            <Grid item xs sx={{
                                'paddingTop' : '5px',
                            }}>
                                {line.price - line.remise}
                            </Grid>
                            :
                            <Grid item xs>
                                <TextField
                                    value={line.price}
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    inputProps={{
                                        style: {
                                            fontSize: 16,
                                            textAlign: 'right'
                                        }
                                    }}
                                    onChange={(e) => {
                                        dispatch(setLine({
                                            index,
                                            property : 'price',
                                            value : e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                        : null
                }
                {
                    line !== null ?
                        <Grid item xs={'auto'}>
                            <Button
                                sx={{
                                    'padding': 0,
                                    'minWidth' : 0,
                                }}
                                onClick={(e) => {
                                    setSelectedLine({
                                        index,
                                        data: line,
                                        listItem
                                    })
                                }}
                            >
                                <Avatar
                                    sx={{ width: 24, height: 24, backgroundColor: '#042C48'
                                    }}>
                                    <Euro sx={{fontSize: 14}} />
                                </Avatar>
                            </Button>
                        </Grid>:
                        null
                }
            </Grid>
        </Box>


    )
}

export const TextFieldLimited = ({
    label= '',
    required = false,
    variant = 'standard',
    value = null,
    fullWidth = null,
    className = null,
    onChange = null,
    maxLength = 5
}) => {
    let currentLength = value === null ? 0 : value.length
    return (
        <TextField
            label={label}
            required={required}
            variant={variant}
            value={value}
            helperText={currentLength + '/' + maxLength}
            {...fullWidth !== null ? {fullWidth: fullWidth}:null}
            {...className !== null ? {className: className}:null}
            onChange={(e) => {
                if(e.target.value.length <= maxLength){
                    onChange(e)
                }
            }}
        />
    )
}


export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        document: state.devisReducer.document,
        pageLoading: state.pageLoaderReducer.pageLoading
    }),
)(DevisForm)
