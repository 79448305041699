import React, {useState,useEffect} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {apiGet} from "../authentication/OAuth2Utils";
import {Alert, AlertTitle, Box, Grid} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";
import Typography from "@mui/material/Typography";
import {useViewport} from "../hooks/ViewportContext";


const DemandeReapproStock = ({
    user = null,
    userImpersonator = null,
    loading = false,
    caption = '',
    dispatch
}) => {
    const [listLoading,setListLoading] = useState(false)
    const [list, setList] = useState(null)
    const {width, height, size} = useViewport()

    useEffect(() => {
        dispatch(setMessageInformation(null))
        setListLoading(true)
        apiGet('/api/get-demande-reappro-stock')
            .then(data => {
                if(data.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                } else {
                    setList(data.data)
                    if (data.data.data.length === 0) {
                        dispatch(setMessageInformation({
                            severity: 'info',
                            title: 'Information',
                            content: 'Vous n\'avez aucune demande de réappro. en attente'
                        }))
                    }
                }
            })
            .finally(() => {
                setListLoading(false)
            })
    }, []);

    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={['xs','sm'].includes(size) ? "Demandes réappro." : "Demandes réappro stock déporté"}
            page_active="demande-reappro-stock"
            dispatch={dispatch}
        >
            <Box
                sx={{
                    overflow: 'auto',
                    height: height - 105,
                }}
            >
                {
                    listLoading ?
                        <TableSkeleton nbCol={4} nbLig={6} />
                        :
                        list?.data?.length > 0 ?
                            <Box>
                                {
                                    list.data.map((stock, key) =>
                                        <Box
                                            key={key}
                                            sx={{
                                                marginBottom:'3em'
                                            }}
                                        >
                                            <Grid container spacing={2} sx={{padding:'1em 1em 0 1em'}}
                                                  justifyContent={'space-between'}
                                                  alignItems={'baseline'}
                                            >
                                                <Grid item xs={'auto'}>
                                                    <Typography
                                                        variant={'h6'}
                                                    >
                                                        {stock[0].WarehouseName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        {stock[0].WarehouseAddress}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <TableData
                                                columns={list.entete}
                                                data={stock}
                                                defaultOrder='desc'
                                                defaultOrderColumnId='DocDate'
                                                loading={loading}
                                                caption={caption}
                                                dispatch={dispatch}
                                                user={user}
                                                notFixed={true}
                                            />
                                        </Box>
                                    )
                                }
                            </Box>
                            : null
                }
            </Box>

        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption
    }),
)(DemandeReapproStock)
