import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

export const PrivateRoute = ({
    user = null,
    publicRoutes = [],
    children
}) => {
    const location = useLocation()

    const explodedPathName = location.pathname.split('/')

    if (!user && !publicRoutes.includes(explodedPathName[1])){
        localStorage.removeItem('switch_user')
        return <Navigate to={'/login?referer=' + location.pathname} replace />;
    }

    if (user) {
        let params = new URLSearchParams(window.location.search);
        let referer = params.get('referer');
        if (location.pathname === '/login'){
            if (referer === null){
                referer = '/';
            }
            return <Navigate to={referer} replace />;
        }
    }

    return children ? children : <Outlet />;
};