import React, {useState,useEffect} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {apiGet} from "../authentication/OAuth2Utils";
import {Alert, AlertTitle} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";
import {initDocument} from "./DevisForm";
import {useNavigate, useParams} from "react-router-dom";
import {filterQuotation} from "../Utils";
import AttachmentDialog from "../AttachmentDialog";

const Devis = ({
    user = null,
    userImpersonator = null,
    loading = false,
    caption = '',
    dispatch
}) => {
    const [devisLoading,setDevisLoading] = useState(false)
    const [listDevis, setListDevis] = useState(null)

    const params = useParams()

    let listeDevis = listDevis === null ?
            null
        :
            {
                entete: listDevis.entete,
                devis: params.hasOwnProperty('cardcode') ? filterQuotation(listDevis.devis,params.cardcode) : listDevis.devis
            }

    let titre = params.hasOwnProperty('cardcode') ?
            listeDevis !== null ?
                'Devis de ' + (listeDevis.devis[0].FirstName !== null ? listeDevis.devis[0].FirstName : '') + ' ' + listeDevis.devis[0].LastName
            :
                'Devis de ...'
        :
            'Devis'

    useEffect(() => {
        init()
    }, []);

    const init = () => {
        dispatch(setMessageInformation(null))
        setDevisLoading(true)
        apiGet('/api/get-devis')
            .then(data => {
                if(data.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                } else {
                    setListDevis(data.data)
                }
            })
            .finally(() => {
                setDevisLoading(false)
            })
        dispatch(initDocument())
    }

    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={titre}
            page_active="devis"
            loading={loading}
            caption={caption}
            dispatch={dispatch}
        >
            {
                devisLoading ?
                    <TableSkeleton nbCol={6} nbLig={6} />:
                    listDevis !== null && listDevis.devis.length > 0?
                        <>
                            <TableData
                                columns={listeDevis.entete}
                                data={listeDevis.devis}
                                defaultOrderColumnId={'DocNum'}
                                dispatch={dispatch}
                            />
                            <AttachmentDialog
                                init={init}
                            />
                        </>
                    :
                        <Alert severity="info">
                            Vous n'avez aucun devis dans notre base
                        </Alert>
            }
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption
    }),
)(Devis)
